import { walletAddressSlicer } from '../utils/util'
const ToastMsg = ({ receipt, explorer, type }) => {
  return (
    <>
      <p className="big">
        <span className="key toasttextcolor" >Your Receipt</span>
      </p>
      <p className="small">
        <span className="key toasttextcolor">TransactionHash: </span>{" "}
        <a
          href={`${explorer}${receipt.blockHash}`}
          target="_blank"
          rel="noreferrer"
        >
          <b className="value toast-msg-tx">
            {" "}
            {walletAddressSlicer(receipt.blockHash)}
          </b>
        </a>
      </p>
      <p className="small">
        <span className="key toasttextcolor">Status:</span>{" "}
        <b className="value toasttextcolor">{receipt.status && "Success"}</b>
      </p>
      <p className="small">
        <span className="key toasttextcolor">BlockNumber:</span>{" "}
        <b className="value toasttextcolor">{receipt.blockNumber}</b>
      </p>

      <a
        className="receipt-btn"
        href={`${explorer}${receipt.transactionHash}`}
        target="_blank"
        rel="noreferrer"
      >
        Show More
      </a>
    </>
  );
};

export default ToastMsg
