import { useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import BlockchainContext from "../store/BlockchainContext";
import { walletAddressSlicer, numberWithCommas } from "../utils/util";

const Header = () => {
  const { totalUsers,
    activeNetwork,
    handleConnectToWallet,
    activeAccount,
    handleDisconnectWallet,
    activeAccountBalance } =
    useContext(BlockchainContext);

    const googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: "en",
          autoDisplay: false
        },
        "google_translate_element"
      );
    };
    useEffect(() => {
      var addScript = document.createElement("script");
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
    }, []);
    
  return (
    <section className="homepage">
      <header className="">
        <div className="header">
          <div className="container containerTwo">
            <div className="row align-items-center">
              <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <div className="logo">
                  <Link to="/">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/1607334777.png`} alt="logo" style={{width:"200px"}}/>
                  </Link>
                </div>
              </div>
              <div className="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 text-right d-flex justify-content-end align-items-center">
                <div className="soc_icons">
                  <ul>
                    <li className="nav-item">
                      {activeAccount ? (
                        <div className="d-flex align-items-center">
                          <p className="m-0 address-text">
                            {walletAddressSlicer(activeAccount)} <br />
                            {numberWithCommas(activeAccountBalance, activeNetwork.displaydecimals)}{" "}
                            {activeNetwork.unit}
                          </p> <br />
                          <button
                            onClick={() => handleDisconnectWallet()}
                            className={`nav-link link connect Click-here btn `}
                            style={{ padding: "8px 30px !important", marginLeft: "12px" }}
                          >
                            Disconnect
                          </button>
                        </div>
                      ) : (
                        <button
                          onClick={() => handleConnectToWallet("wallet")}
                          className={`nav-link link display-7 connect Click-here btn`}
                          style={{ padding: "8px 30px !important" }}
                        >
                          Connect
                        </button>
                      )}
                    </li>
                    {activeAccount ? (
                      <li><Link to="/profile">Profile</Link></li>
                    ) : (
                      <></>
                    )}
                    { /*<li><Link to="/seven">Seven</Link></li> 
                    <li><Link to="/eight">Eight</Link></li> */}
                    <li><a href="https://t.me/trongoogolofficial" target="_blank"><i className="fab fa-telegram" /></a></li>
                    <li><a href="https://t.me/cryptomediaservices" target="_blank"><i className="fab fa-telegram" /></a></li>
                    <li><div id="google_translate_element"></div></li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="headerBottom">
          <div className="container containerTwo">
            <div className="d-flex align-items-center justify-content-between bannerTopContent">
              <div className="actPants">
                Active participants : {totalUsers}                  </div>
              <div id="show_profile">
              </div>
            </div>
          </div>
        </div>
      </header>
    </section>
  )
}

export default Header

