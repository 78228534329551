
export const formatTimeStamp = (timestamp) => {
  const EventTime = new Date(timestamp * 1000).toLocaleTimeString([], {
    hourCycle: "h23",
  });
  const eventDate = new Date(timestamp * 1000).toLocaleDateString().split("/");
  const finalDate = [
    eventDate[2],
    ("0" + eventDate[0]).slice(-2),
    ("0" + eventDate[1]).slice(-2),
  ].join("/");
  return [finalDate, " ", EventTime];
};

export const truncateToDecimals = (num, fixed = 0) => {
  num = removeexponentials(num);
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
};
export const removeexponentials =  (n) => {
  var sign = +n < 0 ? "-" : "",
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  var [lead, decimal, pow] = n.toString().replace(/^-/, "").replace(/^([0-9]+)(e.*)/, "$1.$2").split(/e|\./);
  return +pow < 0 ? sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal : sign + lead + (+pow >= decimal.length ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0)) : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
};
export const timeDifference = (timestamp) => {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;
  const current = new Date();
  const previous = timestamp * 1000;

  var elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return Math.round(elapsed / 1000) + " seconds ago";
  } else if (elapsed < msPerHour) {
    return Math.round(elapsed / msPerMinute) + " minutes ago";
  } else if (elapsed < msPerDay) {
    return Math.round(elapsed / msPerHour) + " hours ago";
  } else if (elapsed < msPerMonth) {
    return "approximately " + Math.round(elapsed / msPerDay) + " days ago";
  } else if (elapsed < msPerYear) {
    return "approximately " + Math.round(elapsed / msPerMonth) + " months ago";
  } else {
    return "approximately " + Math.round(elapsed / msPerYear) + " years ago";
  }
};

export const walletAddressSlicer = (wallet) => {
  if (typeof wallet === "string") {
    if(wallet.length > 20)
    {
      return (
        wallet.substring(0, 2) +
        ".." +
        wallet.substring(wallet.length - 2, wallet.length)
      );
    }
    return wallet;
  }
};

export const walletAddressSlicerold = (wallet) => {
  if (typeof wallet === "string") {
    return (
      wallet.substring(0, 2) +
      ".." +
      wallet.substring(wallet.length - 2, wallet.length)
    );
     return wallet;
  }
};

export const walletAddressSlicerCustom = (wallet) => {
  if (typeof wallet === "string") {
    return (
      wallet.substring(0, 15) +
      "..." +
      wallet.substring(wallet.length - 15, wallet.length)
    );
  }
};


export const getvaluebetween = (min, max, decimal) => {
  var precision = 1;
  for (var i = 0; i < decimal; i++)
    precision = precision * 10;
  max = max * precision;
  min = min * precision;
  return Math.floor(Math.random() * (max - min + 1) + min) / precision;
};


export const numberWithCommas = (number, tofixed) => {
  if (tofixed != null)
    number = Number(number).toFixed(tofixed);

  const str = number.toString().split('.');
  str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1');

  return str.join('.');
}

export const isNull = (data) => {
  if ((data == null) || (data == undefined) || (data == ""))
    return true;
  return false;
}