import { useState, useEffect, useContext } from "react";
import Footer from "./Footer"
import Header from "./Header"
import { useParams } from 'react-router-dom';
import servicerequest from "../utils/config";
import { Link } from "react-router-dom";
import BlockchainContext from "../store/BlockchainContext";
import { ApplicationConstants } from "../utils/ApplicationConstants";
import { walletAddressSlicerold } from "../utils/util";
//import { _fetchData } from "ethers/lib/utils";

const Treeview = () => {
  const { userTransactions, handleInvest } = useContext(BlockchainContext);
  //const res = await servicerequest.post("/getTransactions/", "account=" + account[0]);
  const [users, setusers] = useState([]);
  const [parent, setparent] = useState([]);
  const [parentaddress,setparentaddress]= useState("");
  const { treeid } = useParams();
  const { boardid } = useParams();
  const { referrid } = useParams();
  const gettreedata = async (event) => {
    //if(users.length==0)
    {
      const res = await servicerequest.post("/getTransactions/", "account=" + referrid+"&treeid="+treeid+"&boardid="+boardid);
      console.log(res.data);
      setusers(res.data.users);
      setparent(res.data.parent);
      if(res.data.parent.length>0)
        setparentaddress(res.data.parent[0].users_address.toLowerCase());
    }
  }; 
  
  const buyFirstTree= () =>  {
    for( let loopvar=0;loopvar <userTransactions.length;loopvar++)
    {
      if( (userTransactions[loopvar].treeid == treeid) && (userTransactions[loopvar].board == 0) )
      return false;
    }
    return true;
  }
  const buySecondTree= () =>  {
    for( let loopvar=0;loopvar <userTransactions.length;loopvar++)
    {
      if( (userTransactions[loopvar].treeid == treeid) && (userTransactions[loopvar].board == 1) )
      return false;
    }
    return true;
  }
  const buyThirdTree= () =>  {
    for( let loopvar=0;loopvar <userTransactions.length;loopvar++)
    {
      if( (userTransactions[loopvar].treeid == treeid) && (userTransactions[loopvar].board == 2) )
      return false;
    }
    return true;
  }
  const getboardreferer= (_treeid) =>  {
    for( let loopvar=0;loopvar <userTransactions.length;loopvar++)
    {
      if( (userTransactions[loopvar].treeid == _treeid))
      return userTransactions[loopvar].refferrer;
    }
    return ApplicationConstants.defaultreferral;
  }
  const investnow= async(_boardid) =>  {
      handleInvest(treeid, _boardid, getboardreferer(treeid));
  };
  const getuseraddress = (boardindex) =>{
    let userlist=[];
    for(let loopvar=0;loopvar<100;loopvar++)
    {
      userlist.push(boardindex+loopvar+"");
    }
    return userlist;
  }
  const getparentaddress = (boardindex) =>{
    let userlist=[];
    for(let loopvar=0;loopvar<100;loopvar++)
    {
      userlist.push(boardindex+loopvar+"");
    }
    return userlist;
  }
  //gettreedata();
  const getchild =  (address, path, called="0") =>  {
    //console.log(address, path);

    if(users.length ==0) return "";
    if(address ==null) return "";
    if(address =="") return "";
   // console.log(address, path.length);
    let _sorteduser=users.sort(function(a,b){
      return a.timeinsec - b.timeinsec;
    });
    //console.log(_sorteduser.length,path.length, called,address);
    
    if(path.length==0)
    {
      return address;
    }
    else if(path.length ==1)
    {
      let _fchild= _sorteduser.filter((e)=> {
        // console.log("93")
         //  console.log(e .users_parents.toLowerCase() , address.toLowerCase() ,e .users_parents.toLowerCase() == address.toLowerCase(), path[0]);
           
           return (e .users_parents.toLowerCase() == address.toLowerCase())  && (e .users_address.toLowerCase() != e.users_parents.toLowerCase());
         })
         .filter((e)=> {
         
          // console.log(path, e);
          // console.log("79");
          if(path[0]==true)
          {
            return e.users_side == 1;
          }
          else
          {
            return e.users_side == 0;
          }
         });
         //console.log(_fchild);
         if(_fchild.length==1 ) return _fchild[0].users_address;
         else 
         {
          //console.log(_fchild.length, path.slice(1));
          //console.log("empty")
          return null;
         }
    }
    else if(path.length>1)
    {
      //console.log(path);
      let _child=_sorteduser.filter((e)=> {
       // console.log(e .users_parents.toLowerCase() , address.toLowerCase() ,e .users_parents.toLowerCase() == address.toLowerCase(), path[0]);
        
        return (e .users_parents.toLowerCase() == address.toLowerCase())  && (e .users_address.toLowerCase() != e.users_parents.toLowerCase());
      }).filter((e)=> {
         
        // console.log(path, e);
        // console.log("79");
        if(path[0]==true)
        {
          return e.users_side == 1;
        }
        else
        {
          return e.users_side == 0;
        }
       });;
      //  console.log(_child.length ,path.length);
      if((_child.length >0) && (path.length>0))
      {
       // console.log(_child[0]);
        // path=path.shift();
        // console.log(path);
       // console.log(_child[0].users_address, path);
        return getchild(_child[0].users_address, path.slice(1),"a");
      }
      else
      {
        //console.log("no child",address);
        return null;
      }
    }
    else
    {
      return null;
      /*
      console.log(_sorteduser.length, path, address);
       let _fchild= _sorteduser.filter((e)=> {
        console.log("104")
          // console.log(e .users_parents.toLowerCase() , address.toLowerCase() ,e .users_parents.toLowerCase() == address.toLowerCase(), path[0]);
           
           return (e .users_parents.toLowerCase() == address.toLowerCase())  && (e .users_address.toLowerCase() != e.users_parents.toLowerCase());
         }).filter((e)=> {
          console.log(path, e);
          console.log("110");
          if(path[0]==true)
          {
            return e.users_side == 1;
          }
          else
          {
            return e.users_side == 0;
          }
         });
         if(_fchild.length>0 ) return _fchild[0].users_address+"b";
         else 
          return "empty b";
          */
    }
  } 
  const getchildparent =  (address, path, called="0") =>  {
    console.log(address, path);

    if(parent.length ==0) return "";
    if(address ==null) return "";
    if(address =="") return "";
   // console.log(address, path.length);
    let _sorteduser=parent.sort(function(a,b){
      return a.timeinsec - b.timeinsec;
    });
    console.log(_sorteduser.length,path.length, called,address);
    
    if(path.length==0)
    {
      return address;
    }
    else if(path.length ==1)
    {
      let _fchild= _sorteduser.filter((e)=> {
           return (e .users_parents.toLowerCase() == address.toLowerCase())  && (e .users_address.toLowerCase() != e.users_parents.toLowerCase());
         })
         .filter((e)=> {
         
          if(path[0]==true)
          {
            return e.users_side == 1;
          }
          else
          {
            return e.users_side == 0;
          }
         });
         console.log(_fchild);
         if(_fchild.length==1 ) return _fchild[0].users_address;
         else 
         {
          console.log(_fchild.length, path.slice(1));
          console.log("empty")
          return null;
         }
    }
    else if(path.length>1)
    {
      console.log(path);
      let _child=_sorteduser.filter((e)=> {
       // console.log(e .users_parents.toLowerCase() , address.toLowerCase() ,e .users_parents.toLowerCase() == address.toLowerCase(), path[0]);
        
        return (e .users_parents.toLowerCase() == address.toLowerCase())  && (e .users_address.toLowerCase() != e.users_parents.toLowerCase());
      }).filter((e)=> {
         
        // console.log(path, e);
        // console.log("79");
        if(path[0]==true)
        {
          return e.users_side == 1;
        }
        else
        {
          return e.users_side == 0;
        }
       });;
      //  console.log(_child.length ,path.length);
      if((_child.length >0) && (path.length>0))
      {
       // console.log(_child[0]);
        // path=path.shift();
        // console.log(path);
        console.log(_child[0].users_address, path);
        return getchildparent(_child[0].users_address, path.slice(1),"a");
      }
      else
      {
        console.log("no child",address);
        return null;
      }
    }
    else
    {
      return null;
      
    }
  }

  useEffect(() => {
    gettreedata();
    console.log(buyFirstTree(), buySecondTree(),buyThirdTree());
  }, [referrid]);
  return (
    <section className="homepage">
      <div className="plansPage" >
        <Header />
        <div style={{float: "right"}}>
          {(buyFirstTree() == true)? (
              <button className="nav-link link display-7 connect Click-here btn" onClick={() => investnow("0")}>Join 25$ Board</button>
          ):(<></>)}
           {((buySecondTree() == true) && (buyFirstTree() == false))? (
              <button className="nav-link link display-7 connect Click-here btn" onClick={() => investnow("1")}>Join 50$ Board</button>
          ):(<></>)}
           {((buyThirdTree() == true) && (buySecondTree() == false) && (buyFirstTree() == false))? (
              <button className="nav-link link display-7 connect Click-here btn" onClick={() => investnow("2")}>Join 100$ Board</button>
          ):(<></>)}
        </div>
        <div className="container-inner mobilediv">
          <div className="row">
          <div className="col-sm-6">
            {((parent.length>0)? (
              <div className="planDiagrams">
                <div className="planDiagram child">
                <Link onClick={() => gettreedata()} to={"/tree/"+treeid+"/"+boardid+"/"+(getchildparent(parentaddress,[])!=""? getchildparent(parentaddress,[]):ApplicationConstants.defaultreferral)}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,true,false,true]))} </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,true,false,true,true]))} </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,true,true,false]))}  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,true,true,false,true]))}</text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,true,true,false,false]))} </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,true,true,true]))} </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,true,true,true,true]))} </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,false,false,false]))}  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,false,false,false,true]))}</text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,false,false,false,false]))} </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,false,false,true]))}  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,false,false,true,true]))}  </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,false,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,false,true,false]))} </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,false,true,false,true]))} </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,false,true,false,false]))} </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,false,true,true]))} </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,false,true,true,true]))} </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,false,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,true,false,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,true,false,false,true]))}</text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,true,false,false,false]))} </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,true,false,true]))}  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,true,false,true,true]))} </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,true,true,false]))} </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,true,true,false,true]))} </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,true,true,false,false]))} </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,true,true,true]))} </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,true,true,true,true]))} </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[true,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,false,false,false]))} </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,false,false,false,true]))} </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,false,false,false,false]))}</text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,false,false,true]))}</text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,false,false,true,true]))}</text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,false,false,true,false]))}</text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,false,true,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchildparent(parentaddress,[false,false,true,false,true]))}</text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,false,true,false,false]))}  </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,false,true,true]))}   </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,false,true,true,true]))}  </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,false,true,true,false]))}  </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,true,false,false]))}   </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,true,false,false,true])) }</text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchildparent(parentaddress,[false,true,false,false,false]))}</text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">{walletAddressSlicerold(getchildparent(parentaddress,[]))} </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchildparent(parentaddress,[false]))}      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchildparent(parentaddress,[true]))}     </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchildparent(parentaddress,[false,false]))}     </text>
                      <text x={440} y={510} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchildparent(parentaddress,[false,false,false]))}     </text>
                      <text x={570} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchildparent(parentaddress,[false,false,true]))}      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchildparent(parentaddress,[false,true]))}     </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchildparent(parentaddress,[false,true,true]))}      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchildparent(parentaddress,[false,true,false]))}      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchildparent(parentaddress,[true,true]))}     </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchildparent(parentaddress,[true,true,true]))}      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchildparent(parentaddress,[true,true,false]))}      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchildparent(parentaddress,[true,false]))}     </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchildparent(parentaddress,[true,false,false]))}     </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchildparent(parentaddress,[true,false,true]))}      </text>
                    </g>
                  </svg></Link> 
                  </div>
              </div>
            ):(<></>))}

            </div>
            <div className="col-sm-6">
            {((parent.length>0)? (
              <div className="planDiagrams">
                <div className="planDiagram child">
                <Link onClick={() => gettreedata()} to={"/tree/"+treeid+"/"+boardid+"/"+(getchild(referrid,[])!=""? getchild(referrid,[]):ApplicationConstants.defaultreferral)}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true]))} </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,true]))} </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false]))}  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,true]))}</text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,false]))} </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true]))} </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,true]))} </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false]))}  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,true]))}</text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,false]))} </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true]))}  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,true]))}  </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false]))} </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,true]))} </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,false]))} </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true]))} </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,true]))} </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,true]))}</text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,false]))} </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true]))}  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,true]))} </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false]))} </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,true]))} </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,false]))} </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true]))} </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,true]))} </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false]))} </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,true]))} </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,false]))}</text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true]))}</text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,true]))}</text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,false]))}</text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,true,false,true]))}</text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,false]))}  </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true]))}   </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,true]))}  </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,false]))}  </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false]))}   </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,true])) }</text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,false]))}</text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">{walletAddressSlicerold(getchild(referrid,[]))} </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false]))}      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true]))}     </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false]))}     </text>
                      <text x={440} y={510} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,false]))}     </text>
                      <text x={570} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,true]))}      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true]))}     </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,true]))}      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,false]))}      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true]))}     </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,true]))}      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,false]))}      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false]))}     </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,false]))}     </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,true]))}      </text>
                    </g>
                  </svg></Link>                      </div>
              </div>
               ):(<></>))}
            </div>

            <div className="col-sm-7 mx-auto">
            {((parent.length==0)? (
              <div className="planDiagrams col-md-11">
                <div className="planDiagram parent">
                <Link onClick={() => gettreedata()} to={"/tree/"+treeid+"/"+boardid+"/"+(getchild(referrid,[])!=""? getchild(referrid,[]):ApplicationConstants.defaultreferral)}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true]))} </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,true]))} </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false]))}  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,true]))}</text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,false]))} </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true]))} </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,true]))} </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false]))}  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,true]))}</text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,false]))} </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true]))}  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,true]))}  </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false]))} </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,true]))} </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,false]))} </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true]))} </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,true]))} </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,true]))}</text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,false]))} </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true]))}  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,true]))} </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false]))} </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,true]))} </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,false]))} </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true]))} </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,true]))} </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false]))} </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,true]))} </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,false]))}</text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true]))}</text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,true]))}</text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,false]))}</text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,true,false,true]))}</text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,false]))}  </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true]))}   </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,true]))}  </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,false]))}  </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false]))}   </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,true])) }</text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,false]))}</text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">{walletAddressSlicerold(getchild(referrid,[]))} </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false]))}      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true]))}     </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false]))}     </text>
                      <text x={440} y={510} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,false]))}     </text>
                      <text x={570} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,true]))}      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true]))}     </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,true]))}      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,false]))}      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true]))}     </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,true]))}      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,false]))}      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false]))}     </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,false]))}     </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,true]))}      </text>
                    </g>
                  </svg></Link>    
                </div>
              </div>
              ):(<></>))}
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                <Link onClick={() => gettreedata()} to={"/tree/"+treeid+"/"+boardid+"/"+(getchild(referrid,[false,false,false])!=""? getchild(referrid,[false,false,false]):ApplicationConstants.defaultreferral)}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,true,false,true]))} </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,true,false,true,true]))} </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,true,true,false]))}  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,true,true,false,true]))}</text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,true,true,false,false]))} </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,true,true,true]))} </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,true,true,true,true]))} </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,false,false,false]))}  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,false,false,false,true]))}</text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,false,false,false,false]))} </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,false,false,true]))}  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,false,false,true,true]))}  </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,false,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,false,true,false]))} </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,false,true,false,true]))} </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,false,true,false,false]))} </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,false,true,true]))} </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,false,true,true,true]))} </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,false,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,true,false,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,true,false,false,true]))}</text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,true,false,false,false]))} </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,true,false,true]))}  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,true,false,true,true]))} </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,true,true,false]))} </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,true,true,false,true]))} </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,true,true,false,false]))} </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,true,true,true]))} </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,true,true,true,true]))} </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,true,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,false,false,false]))} </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,false,false,false,true]))} </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,false,false,false,false]))}</text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,false,false,true]))}</text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,false,false,true,true]))}</text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,false,false,true,false]))}</text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,false,true,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,false,false,false,true,false,true]))}</text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,false,true,false,false]))}  </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,false,true,true]))}   </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,false,true,true,true]))}  </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,false,true,true,false]))}  </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,true,false,false]))}   </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,true,false,false,true])) }</text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,false,false,true,false,false,false]))}</text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">{walletAddressSlicerold(getchild(referrid,[false,false,false,]))} </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,false,false]))}      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,false,true]))}     </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,false,false,false]))}     </text>
                      <text x={440} y={510} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,false,false,false,false]))}     </text>
                      <text x={570} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,false,false,false,true]))}      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,false,false,true]))}     </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,false,false,true,true]))}      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,false,false,true,false]))}      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,false,true,true]))}     </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,false,true,true,true]))}      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,false,true,true,false]))}      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,false,true,false]))}     </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,false,true,false,false]))}     </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,false,true,false,true]))}      </text>
                    </g>
                  </svg></Link>                       </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                <Link onClick={() => gettreedata()} to={"/tree/"+treeid+"/"+boardid+"/"+(getchild(referrid,[false,false,true])!=""? getchild(referrid,[false,false,true]):ApplicationConstants.defaultreferral)}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,true,false,true]))} </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,true,false,true,true]))} </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,true,true,false]))}  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,true,true,false,true]))}</text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,true,true,false,false]))} </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,true,true,true]))} </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,true,true,true,true]))} </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,false,false,false]))}  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,false,false,false,true]))}</text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,false,false,false,false]))} </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,false,false,true]))}  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,false,false,true,true]))}  </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,false,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,false,true,false]))} </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,false,true,false,true]))} </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,false,true,false,false]))} </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,false,true,true]))} </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,false,true,true,true]))} </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,false,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,true,false,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,true,false,false,true]))}</text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,true,false,false,false]))} </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,true,false,true]))}  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,true,false,true,true]))} </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,true,true,false]))} </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,true,true,false,true]))} </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,true,true,false,false]))} </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,true,true,true]))} </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,true,true,true,true]))} </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,true,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,false,false,false]))} </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,false,false,false,true]))} </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,false,false,false,false]))}</text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,false,false,true]))}</text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,false,false,true,true]))}</text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,false,false,true,false]))}</text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,false,true,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,true,false,false,true,false,true]))}</text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,false,true,false,false]))}  </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,false,true,true]))}   </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,false,true,true,true]))}  </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,false,true,true,false]))}  </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,true,false,false]))}   </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,true,false,false,true])) }</text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,false,true,false,true,false,false,false]))}</text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">{walletAddressSlicerold(getchild(referrid,[false,false,true,]))} </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,true,false]))}      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,true,true]))}     </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,true,false,false]))}     </text>
                      <text x={440} y={510} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,true,false,false,false]))}     </text>
                      <text x={570} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,true,false,false,true]))}      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,true,false,true]))}     </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,true,false,true,true]))}      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,true,false,true,false]))}      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,true,true,true]))}     </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,true,true,true,true]))}      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,true,true,true,false]))}      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,false,true,true,false]))}     </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,true,true,false,false]))}     </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,false,true,true,false,true]))}      </text>
                    </g>
                  </svg></Link>                      </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                <Link onClick={() => gettreedata()} to={"/tree/"+treeid+"/"+boardid+"/"+(getchild(referrid,[false,true,false])!=""? getchild(referrid,[false,true,false]):ApplicationConstants.defaultreferral)}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,true,false,true]))} </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,true,false,true,true]))} </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,true,true,false]))}  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,true,true,false,true]))}</text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,true,true,false,false]))} </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,true,true,true]))} </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,true,true,true,true]))} </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,false,false,false]))}  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,false,false,false,true]))}</text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,false,false,false,false]))} </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,false,false,true]))}  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,false,false,true,true]))}  </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,false,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,false,true,false]))} </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,false,true,false,true]))} </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,false,true,false,false]))} </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,false,true,true]))} </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,false,true,true,true]))} </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,false,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,true,false,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,true,false,false,true]))}</text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,true,false,false,false]))} </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,true,false,true]))}  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,true,false,true,true]))} </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,true,true,false]))} </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,true,true,false,true]))} </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,true,true,false,false]))} </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,true,true,true]))} </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,true,true,true,true]))} </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,true,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,false,false,false]))} </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,false,false,false,true]))} </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,false,false,false,false]))}</text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,false,false,true]))}</text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,false,false,true,true]))}</text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,false,false,true,false]))}</text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,false,true,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true,false,false,false,true,false,true]))}</text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,false,true,false,false]))}  </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,false,true,true]))}   </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,false,true,true,true]))}  </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,false,true,true,false]))}  </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,true,false,false]))}   </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,true,false,false,true])) }</text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,false,false,true,false,false,false]))}</text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">{walletAddressSlicerold(getchild(referrid,[false,true,false,]))} </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true,false,false]))}      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true,false,true]))}     </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true,false,false,false]))}     </text>
                      <text x={440} y={510} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,false,false,false,false]))}     </text>
                      <text x={570} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,false,false,false,true]))}      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true,false,false,true]))}     </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,false,false,true,true]))}      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,false,false,true,false]))}      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true,false,true,true]))}     </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,false,true,true,true]))}      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,false,true,true,false]))}      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true,false,true,false]))}     </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,false,true,false,false]))}     </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,false,true,false,true]))}      </text>
                    </g>
                  </svg></Link>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                <Link onClick={() => gettreedata()} to={"/tree/"+treeid+"/"+boardid+"/"+(getchild(referrid,[false,true,true])!=""? getchild(referrid,[false,true,true]):ApplicationConstants.defaultreferral)}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,true,false,true]))} </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,true,false,true,true]))} </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,true,true,false]))}  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,true,true,false,true]))}</text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,true,true,false,false]))} </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,true,true,true]))} </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,true,true,true,true]))} </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,false,false,false]))}  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,false,false,false,true]))}</text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,false,false,false,false]))} </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,false,false,true]))}  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,false,false,true,true]))}  </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,false,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,false,true,false]))} </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,false,true,false,true]))} </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,false,true,false,false]))} </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,false,true,true]))} </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,false,true,true,true]))} </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,false,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,true,false,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,true,false,false,true]))}</text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,true,false,false,false]))} </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,true,false,true]))}  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,true,false,true,true]))} </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,true,true,false]))} </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,true,true,false,true]))} </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,true,true,false,false]))} </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,true,true,true]))} </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,true,true,true,true]))} </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,true,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,false,false,false]))} </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,false,false,false,true]))} </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,false,false,false,false]))}</text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,false,false,true]))}</text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,false,false,true,true]))}</text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,false,false,true,false]))}</text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,false,true,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true,true,false,false,true,false,true]))}</text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,false,true,false,false]))}  </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,false,true,true]))}   </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,false,true,true,true]))}  </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,false,true,true,false]))}  </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,true,false,false]))}   </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,true,false,false,true])) }</text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[false,true,true,false,true,false,false,false]))}</text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">{walletAddressSlicerold(getchild(referrid,[false,true,true,]))} </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true,true,false]))}      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true,true,true]))}     </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true,true,false,false]))}     </text>
                      <text x={440} y={510} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,true,false,false,false]))}     </text>
                      <text x={570} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,true,false,false,true]))}      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true,true,false,true]))}     </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,true,false,true,true]))}      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,true,false,true,false]))}      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true,true,true,true]))}     </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,true,true,true,true]))}      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,true,true,true,false]))}      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[false,true,true,true,false]))}     </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,true,true,false,false]))}     </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[false,true,true,true,false,true]))}      </text>
                    </g>
                  </svg></Link>                    </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                <Link onClick={() => gettreedata()} to={"/tree/"+treeid+"/"+boardid+"/"+(getchild(referrid,[true,false,false])!=""? getchild(referrid,[true,false,false]):ApplicationConstants.defaultreferral)}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,true,false,true]))} </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,true,false,true,true]))} </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,true,true,false]))}  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,true,true,false,true]))}</text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,true,true,false,false]))} </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,true,true,true]))} </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,true,true,true,true]))} </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,false,false,false]))}  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,false,false,false,true]))}</text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,false,false,false,false]))} </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,false,false,true]))}  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,false,false,true,true]))}  </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,false,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,false,true,false]))} </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,false,true,false,true]))} </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,false,true,false,false]))} </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,false,true,true]))} </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,false,true,true,true]))} </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,false,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,true,false,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,true,false,false,true]))}</text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,true,false,false,false]))} </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,true,false,true]))}  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,true,false,true,true]))} </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,true,true,false]))} </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,true,true,false,true]))} </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,true,true,false,false]))} </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,true,true,true]))} </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,true,true,true,true]))} </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,true,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,false,false,false]))} </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,false,false,false,true]))} </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,false,false,false,false]))}</text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,false,false,true]))}</text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,false,false,true,true]))}</text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,false,false,true,false]))}</text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,false,true,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false,false,false,false,true,false,true]))}</text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,false,true,false,false]))}  </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,false,true,true]))}   </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,false,true,true,true]))}  </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,false,true,true,false]))}  </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,true,false,false]))}   </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,true,false,false,true])) }</text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,false,false,true,false,false,false]))}</text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">{walletAddressSlicerold(getchild(referrid,[true,false,false,]))} </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false,false,false]))}      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false,false,true]))}     </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false,false,false,false]))}     </text>
                      <text x={440} y={510} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,false,false,false,false]))}     </text>
                      <text x={570} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,false,false,false,true]))}      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false,false,false,true]))}     </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,false,false,true,true]))}      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,false,false,true,false]))}      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false,false,true,true]))}     </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,false,true,true,true]))}      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,false,true,true,false]))}      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false,false,true,false]))}     </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,false,true,false,false]))}     </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,false,true,false,true]))}      </text>
                    </g>
                  </svg></Link>                     </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                <Link onClick={() => gettreedata()} to={"/tree/"+treeid+"/"+boardid+"/"+(getchild(referrid,[true,false,true])!=""? getchild(referrid,[true,false,true]):ApplicationConstants.defaultreferral)}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,true,false,true]))} </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,true,false,true,true]))} </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,true,true,false]))}  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,true,true,false,true]))}</text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,true,true,false,false]))} </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,true,true,true]))} </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,true,true,true,true]))} </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,false,false,false]))}  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,false,false,false,true]))}</text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,false,false,false,false]))} </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,false,false,true]))}  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,false,false,true,true]))}  </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,false,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,false,true,false]))} </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,false,true,false,true]))} </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,false,true,false,false]))} </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,false,true,true]))} </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,false,true,true,true]))} </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,false,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,true,false,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,true,false,false,true]))}</text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,true,false,false,false]))} </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,true,false,true]))}  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,true,false,true,true]))} </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,true,true,false]))} </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,true,true,false,true]))} </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,true,true,false,false]))} </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,true,true,true]))} </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,true,true,true,true]))} </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,true,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,false,false,false]))} </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,false,false,false,true]))} </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,false,false,false,false]))}</text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,false,false,true]))}</text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,false,false,true,true]))}</text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,false,false,true,false]))}</text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,false,true,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false,true,false,false,true,false,true]))}</text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,false,true,false,false]))}  </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,false,true,true]))}   </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,false,true,true,true]))}  </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,false,true,true,false]))}  </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,true,false,false]))}   </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,true,false,false,true])) }</text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,false,true,false,true,false,false,false]))}</text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">{walletAddressSlicerold(getchild(referrid,[true,false,true,]))} </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false,true,false]))}      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false,true,true]))}     </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false,true,false,false]))}     </text>
                      <text x={440} y={510} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,true,false,false,false]))}     </text>
                      <text x={570} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,true,false,false,true]))}      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false,true,false,true]))}     </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,true,false,true,true]))}      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,true,false,true,false]))}      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false,true,true,true]))}     </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,true,true,true,true]))}      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,true,true,true,false]))}      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,false,true,true,false]))}     </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,true,true,false,false]))}     </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,false,true,true,false,true]))}      </text>
                    </g>
                  </svg></Link>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                <Link onClick={() => gettreedata()} to={"/tree/"+treeid+"/"+boardid+"/"+(getchild(referrid,[true,true,false])!=""? getchild(referrid,[true,true,false]):ApplicationConstants.defaultreferral)}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,true,false,true]))} </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,true,false,true,true]))} </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,true,true,false]))}  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,true,true,false,true]))}</text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,true,true,false,false]))} </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,true,true,true]))} </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,true,true,true,true]))} </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,false,false,false]))}  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,false,false,false,true]))}</text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,false,false,false,false]))} </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,false,false,true]))}  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,false,false,true,true]))}  </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,false,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,false,true,false]))} </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,false,true,false,true]))} </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,false,true,false,false]))} </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,false,true,true]))} </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,false,true,true,true]))} </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,false,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,true,false,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,true,false,false,true]))}</text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,true,false,false,false]))} </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,true,false,true]))}  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,true,false,true,true]))} </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,true,true,false]))} </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,true,true,false,true]))} </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,true,true,false,false]))} </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,true,true,true]))} </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,true,true,true,true]))} </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,true,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,false,false,false]))} </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,false,false,false,true]))} </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,false,false,false,false]))}</text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,false,false,true]))}</text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,false,false,true,true]))}</text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,false,false,true,false]))}</text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,false,true,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true,false,false,false,true,false,true]))}</text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,false,true,false,false]))}  </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,false,true,true]))}   </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,false,true,true,true]))}  </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,false,true,true,false]))}  </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,true,false,false]))}   </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,true,false,false,true])) }</text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,false,false,true,false,false,false]))}</text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">{walletAddressSlicerold(getchild(referrid,[true,true,false,]))} </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true,false,false]))}      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true,false,true]))}     </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true,false,false,false]))}     </text>
                      <text x={440} y={510} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,false,false,false,false]))}     </text>
                      <text x={570} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,false,false,false,true]))}      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true,false,false,true]))}     </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,false,false,true,true]))}      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,false,false,true,false]))}      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true,false,true,true]))}     </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,false,true,true,true]))}      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,false,true,true,false]))}      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true,false,true,false]))}     </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,false,true,false,false]))}     </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,false,true,false,true]))}      </text>
                    </g>
                  </svg></Link>                    </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                <Link onClick={() => gettreedata()} to={"/tree/"+treeid+"/"+boardid+"/"+(getchild(referrid,[true,true,true])!=""? getchild(referrid,[true,true,true]):ApplicationConstants.defaultreferral)}>
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,true,false,true]))} </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,true,false,true,true]))} </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,true,true,false]))}  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,true,true,false,true]))}</text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,true,true,false,false]))} </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,true,true,true]))} </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,true,true,true,true]))} </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,false,false,false]))}  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,false,false,false,true]))}</text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,false,false,false,false]))} </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,false,false,true]))}  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,false,false,true,true]))}  </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,false,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,false,true,false]))} </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,false,true,false,true]))} </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,false,true,false,false]))} </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,false,true,true]))} </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,false,true,true,true]))} </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,false,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,true,false,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,true,false,false,true]))}</text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,true,false,false,false]))} </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,true,false,true]))}  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,true,false,true,true]))} </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,true,false,true,false]))} </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,true,true,false]))} </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,true,true,false,true]))} </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,true,true,false,false]))} </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,true,true,true]))} </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,true,true,true,true]))} </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,true,true,true,true,false]))} </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,false,false,false]))} </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,false,false,false,true]))} </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,false,false,false,false]))}</text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,false,false,true]))}</text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,false,false,true,true]))}</text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,false,false,true,false]))}</text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,false,true,false]))}  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true,true,false,false,true,false,true]))}</text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,false,true,false,false]))}  </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,false,true,true]))}   </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,false,true,true,true]))}  </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,false,true,true,false]))}  </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,true,false,false]))}   </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,true,false,false,true])) }</text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                    {walletAddressSlicerold(getchild(referrid,[true,true,true,false,true,false,false,false]))}</text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">{walletAddressSlicerold(getchild(referrid,[true,true,true,]))} </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true,true,false]))}      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true,true,true]))}     </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true,true,false,false]))}     </text>
                      <text x={440} y={510} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,true,false,false,false]))}     </text>
                      <text x={570} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,true,false,false,true]))}      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true,true,false,true]))}     </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,true,false,true,true]))}      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,true,false,true,false]))}      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true,true,true,true]))}     </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,true,true,true,true]))}      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,true,true,true,false]))}      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">{walletAddressSlicerold(getchild(referrid,[true,true,true,true,false]))}     </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,true,true,false,false]))}     </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">{ walletAddressSlicerold(getchild(referrid,[true,true,true,true,false,true]))}      </text>
                    </g>
                  </svg></Link>                     </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </section>

  )
}

export default Treeview;