import { createContext, useState, useEffect } from "react";
import Web3 from "web3";
import Web3Modal from "web3modal";
//import { providers } from "../utils/Web3Provider";
import axios from "axios";
import { networks } from "../utils/NetworkConstants";
import { ethers } from 'ethers';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { numberWithCommas, truncateToDecimals } from "../utils/util";
import { toast } from "react-toastify";
import ToastMsg from "../components/ToastMsg";
import { ApplicationConstants } from "../utils/ApplicationConstants";
import { ABI } from "../utils/abi";
import servicerequest from "../utils/config";

const BlockchainContext = createContext({
  notification: null,
  showModal: function () { },
  hideModal: function () { },
});

export const BlockchainContextProvider = (props) => {
  const [provider, setProvider] = useState();
  const [web3Instance, setWeb3Instance] = useState();
  const [useremail, setuseremail] = useState("");
  const [showemailprompt, setshowemailprompt] = useState(false);
  const [showreferralprompt, setshowreferralprompt] = useState(false);
  const [web3Modal, setWeb3Modal] = useState();
  const [minDeposit, setminDeposit] = useState(0);
  const [Contract, setContract] = useState();
  const [treecount, settreecount] = useState(0);
  const [usdPrice, setUsdPrice] = useState(0);
  const [popupopen, setpopupOpen] = useState(false);// useState(sessionStorage.getItem("TRONG_popupshown")?false:true);

  const [totalDeposit, setTotalDeposit] = useState("0.000");
  const [totalUsers, settotalUsers] = useState(0);

  //const [totalRefEarn, setRefEarn] = useState("0000.000");
  const [walletBalance, setWalletBalance] = useState("0.00000");
  const [harvestValue, setHarvestValue] = useState("0.00000");
  const [userinfo, setuserinfo] = useState(["0", "0", "0", "0"]);

  const [historyTotalInfo, setHistoryTotalInfo] = useState(["0.000", "0.000"]);
  const [userTransactions, setUserTransactions] = useState([]);
  //const [userInvitedInfo, setUserInvitedInfo] = useState(["0", "0"]);
  const [ischange, setIschange] = useState(true);
  ////////////////////
  //console.log(( localStorage.getItem("TRONG_activeNetwork")? ( networks[localStorage.getItem("TRONG_activeNetwork")]==null?networks["bsc"]:networks[localStorage.getItem("TRONG_activeNetwork")] ):networks["bsc"]));
  const [activeNetwork, setActiveNetwork] = useState(localStorage.getItem("TRONG_activeNetwork") ? networks[localStorage.getItem("TRONG_activeNetwork")] : networks["bsc"]);
  const [activeAccount, setActiveAccount] = useState();
  const [activeContract, setActiveContract] = useState();
  const [activeAccountBalance, setActiveAccountBalance] = useState("0.00000");
  //const [userHoldBonus, setUserHoldBonus] = useState("0");
  const [autoSlide, setAutoSlide] = useState(true);
  //const [depositBonus, setDepositBonus] = useState("000.000");
  const [popupstatus, setpopupstatus] = useState("0");


  useEffect(() => {
    if (localStorage.getItem("TRONG_siteInfo")) {
      setTotalDeposit(JSON.parse(localStorage.getItem("TRONG_siteInfo"))[0]);
      if (localStorage.getItem("TRONG_usdPrice")) {
        setUsdPrice(JSON.parse(localStorage.getItem("TRONG_usdPrice")));
      }
    }
    if (localStorage.getItem("TRONG_mindeposit")) {
      setminDeposit(JSON.parse(localStorage.getItem("TRONG_mindeposit")));
    }
    if (window.location.href.includes("ref=")) {
      let refaccount = window.location.href.split("ref=")[1].split("&");
      localStorage.setItem("TRONG_baseRef", refaccount[0]);
      console.log(refaccount[0]);
    }
    if (window.location.href.includes("&network=")) {
      let urlNet = window.location.href.split("&network=")[1];
      setActiveNetwork(networks[urlNet]);

    } else if (localStorage.getItem("TRONG_activeNetwork")) {
      console.log(networks[localStorage.getItem("TRONG_activeNetwork")])
      setActiveNetwork(networks[localStorage.getItem("TRONG_activeNetwork")]);

    } else {
      console.log("activeNetwork", activeNetwork.title);
    }
    if (
      localStorage.getItem("TRONG_account") && localStorage.getItem("TRONG_account") !== "undefined"
    ) {
      connectToWallet("wallet");
    } else {
      connectToWallet("noWallet");
    }
  }, []);

  useEffect(() => {
    if (
      provider &&
      activeAccount &&
      web3Instance.currentProvider.isMetaMask === true &&
      ischange
    ) {
      setIschange(false);
      provider.on("accountsChanged", (accounts) => {
        setUserTransactions([]);
        fetchImportantData(activeContract, web3Instance, accounts[0]);
      });
    }
    if (provider && activeAccount && web3Instance.currentProvider.isMetaMask === true && ischange) {
      setIschange(false);
      provider.on("networkChanged", (networkId) => {
        setActiveAccount("");
        setHarvestValue("");
        setWalletBalance("");
        console.log("ffff ")
        connectToWallet();
      });
    }
  });

  /////////////////
  const handleChangeNetwork = async (netTitle) => {
    console.log(netTitle);
    //console.log(networks[netTitle]);
    //console.log(networks);
    if (networks[netTitle] == null)
      netTitle = "bsc";
    disconnectWallet();
    setActiveNetwork(networks[netTitle]);
    setHistoryTotalInfo(["0.000", "0.000"]);
    localStorage.setItem("TRONG_activeNetwork", netTitle);
    console.log(ApplicationConstants.pricefrom);
    if (ApplicationConstants.pricefrom == "coingecko") {
      const usdPrice = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${networks[netTitle].tokenId}&vs_currencies=usd&pos=1`);
      setUsdPrice(usdPrice.data[networks[netTitle].tokenId].usd);
      //setminDeposit(networks[netTitle].mindeposit);
      setminDeposit(networks[netTitle].mindeposit / usdPrice.data[networks[netTitle].tokenId].usd);
      localStorage.setItem("TRONG_mindeposit", networks[netTitle].mindeposit / usdPrice.data[networks[netTitle].tokenId].usd);
    }
    else if (ApplicationConstants.pricefrom == "dex") {
      console.log(networks[netTitle].type)

      if ((networks[netTitle].type === "0") || (networks[netTitle].type === "2")) {
        const web3 = new Web3(networks[netTitle].rpcURl);
        const contractInstanceprice = new web3.eth.Contract(networks[netTitle].ABI, networks[netTitle].contract);
        await contractInstanceprice.methods.getLatestPrice().call(async (error, usdprice) => {
          if (!error) {
            console.log(usdprice);
            let priceinusd = usdprice / 100000000;
            console.log(priceinusd);
            setUsdPrice(priceinusd);
            setminDeposit(networks[activeNetwork.title].mindeposit / priceinusd);
            localStorage.setItem("TRONG_mindeposit", activeNetwork.mindeposit / priceinusd);
          }
        });
      }
      else {

      }
    }
    else {
      const usdPrice = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${networks[netTitle].tokenId}&vs_currencies=usd&pos=2`);
      setUsdPrice(usdPrice.data[networks[netTitle].tokenId].usd);
      //setminDeposit(networks[netTitle].mindeposit);
      setminDeposit(networks[netTitle].mindeposit / usdPrice.data[networks[netTitle].tokenId].usd);
      localStorage.setItem("TRONG_mindeposit", networks[netTitle].mindeposit / usdPrice.data[networks[netTitle].tokenId].usd);
    }
    if ((networks[netTitle].type === "0") || (networks[netTitle].type === "2")) {
      const web3 = new Web3(networks[netTitle].rpcURl);
      const contractInstance = new web3.eth.Contract(
        networks[netTitle].ABI,
        networks[netTitle].contract
      );

      setContract(contractInstance);
      setActiveContract(contractInstance);
      setWeb3Instance(web3);
      setProvider(provider);
      //console.log(activeNetwork.contract);
      //console.log(activeNetwork);
      fetchDataFromContract(contractInstance, web3, null);

      //console.log( contractInstance.methods);
      /*
      await  contractInstance.methods.getMinDeposit().call((error, result) => {
          if(!error)
          {
            console.log(" ssasass 1",error,result);
            // console.log(error);
            // console.log(networks[netTitle]);
            // console.log(web3.utils.fromWei(result));
            setminDeposit(web3.utils.fromWei(result));
          }
          else
          {
              console.log(error);
              console.log( contractInstance.methods);
          }
        });
        */
      await contractInstance.methods.getSiteInfo().call((error, result) => {
        if (!error) {
          console.log(result);
          setTotalDeposit(Number(web3.utils.fromWei(result._totalInvested)));
          settotalUsers(Number((result._usercount)));
          settreecount(Number(result._treecount));

          // //console.log(" 111111");
          // localStorage.setItem(
          //   "TRONG_siteInfo",
          //   JSON.stringify([
          //     web3.utils.fromWei(result[0]),
          //   ])
          // );
          // localStorage.setItem(
          //   "TRONG_usdPrice",
          //   usdPrice.data[networks[netTitle].tokenId].usd
          // );

        }
      });
    } else {
      console.log(networks[netTitle].type);
    }

  };

  const getGlobalInformation = async (account) => {

    Object.values(networks).map(async (network) => {
      if ((network.type === "0") || (network.type === "2")) {
        setUserTransactions([]);
        const web3 = new Web3(network.rpcURl);
        const contractInstance = new web3.eth.Contract(network.ABI, network.contract);
        try {
          await contractInstance.methods.getUserNumberOfDeposits(account)
            .call(async (error, amountOfDeposit) => {
              if (!error) {
                for (let i = 0; i < amountOfDeposit; i++) {
                  await contractInstance.methods
                    .getUserDepositInfo(account, i)
                    .call((error, depositInfo) => {
                      if (!error) {
                        //console.log(depositInfo);
                        setUserTransactions((old) => [
                          ...old,
                          {
                            treeid: depositInfo[0][0],
                            board: depositInfo[0][1],
                            position: depositInfo[0][2],
                            amount: numberWithCommas(web3.utils.fromWei(depositInfo[0][3]), 5),
                            amountreceived: numberWithCommas(web3.utils.fromWei(depositInfo[1]), 5),
                            amountreceivedfrom: depositInfo[3],
                            refferrer: depositInfo[0][5],
                            referralusers: depositInfo[2],
                            iseligible: depositInfo[4],
                            reentryused: depositInfo[5],
                          },
                        ]);
                      }
                    });
                }
              }
            });

          await contractInstance.methods.getSiteInfo().call((error, result) => {
            if (!error) {
              console.log(result);
              setTotalDeposit(Number(web3.utils.fromWei(result._totalInvested)));
              settotalUsers(Number((result._usercount)));
              settreecount(Number(result._treecount));
              // setTotalDeposit(Number(web3.utils.fromWei(result[0]))+ networks[netTitle].bonusamount );
              // //console.log(" 111111");
              // localStorage.setItem(
              //   "TRONG_siteInfo",
              //   JSON.stringify([
              //     web3.utils.fromWei(result[0]),
              //   ])
              // );
              // localStorage.setItem(
              //   "TRONG_usdPrice",
              //   usdPrice.data[networks[netTitle].tokenId].usd
              // );

            }
          });
        }
        catch (e) {
          console.error(e);
        }
      } else {

      }
    });
  };

  const checkPolygonNetwork = async () => {
    const web3 = new Web3(networks["polygon"].rpcURl);
    const contractInstance = new web3.eth.Contract(
      networks["polygon"].ABI,
      networks["polygon"].contract
    );
    await contractInstance.methods.name().call((error, result) => {
      if (!error) {
      }
    });
  };

  const checkEthNetwork = async () => {
    const web3 = new Web3(networks["eth"].rpcURl);
    const contractInstance = new web3.eth.Contract(
      networks["eth"].ABI,
      networks["eth"].contract
    );
    await contractInstance.methods.name().call((error, result) => {
      if (!error) {
      }
    });
  };

  const connectToWallet = async (type) => {
    if (type === "noWallet") {
      try {
        if ((window.ethereum == undefined) || (window.ethereum == null)) {
          toast.error("Please install MetaMask");
          //shiva need to show data even if no metamask installed
          const web3 = new Web3(activeNetwork.rpcURl);
          const contractInstance = new web3.eth.Contract(activeNetwork.ABI, activeNetwork.contract);
          setContract(contractInstance);
          setActiveContract(contractInstance);
          setWeb3Instance(web3);
          setProvider(provider);
          fetchDataFromContract(contractInstance, web3, null);
        }
        else if ((activeNetwork.type === "0") || (activeNetwork.type === "2")) {
          const web3 = new Web3(activeNetwork.rpcURl);
          //console.log(activeNetwork.ABI)
          //console.log(activeNetwork.contract)
          const contractInstance = new web3.eth.Contract(
            activeNetwork.ABI,
            activeNetwork.contract
          );
          setContract(contractInstance);
          setActiveContract(contractInstance);
          setWeb3Instance(web3);
          setProvider(provider);
          //console.log(activeNetwork.contract);
          //console.log(activeNetwork);
          fetchDataFromContract(contractInstance, web3, null);
        }
        else {
          console.log(activeNetwork.title);
          console.log(networks[activeNetwork.title]);
          console.log(networks[activeNetwork.title].contract);

        }
      }
      catch (e) {
        console.error(e);
      }
    } else {
      setAutoSlide(false);
      console.log(" connect", activeNetwork)
      console.log(typeof window.ethereum);
      if ((window.ethereum == undefined) || (window.ethereum == null)) {
        toast.error("Please install MetaMask");
      }
      else if ((activeNetwork.type === "0") || (activeNetwork.type === "2")) {
        //console.log(activeNetwork.rpcURl);
        //console.log(activeNetwork.chainId);
        const web3Modal = new Web3Modal({
          cacheProvider: true,
          providerOptions: {
            walletconnect: {
              //package: WalletConnectProvider,
              options: {
                rpc: activeNetwork.rpcconfig,
                supportedChainIds: [activeNetwork.chainId],
                bridge: 'https://bridge.walletconnect.org',
                //network: "binance"
                network: activeNetwork.trustwalletnetworkname
              },
            },
          },
          theme: "dark",
        });
        let provider;
        await web3Modal
          .connect(web3Modal)
          .then((res) => {
            provider = res;
            console.log(res);
          })
          .catch((err) => {
            console.error(err)
            provider = activeNetwork.rpcURl;
            //provider = process.env.REACT_APP_RPF_NODE;
          });
        const web3 = new Web3(provider);
        const account = await web3.eth.getAccounts();
        if (
          window.ethereum &&
          web3.currentProvider.isMetaMask === true &&
          activeNetwork.title !== "eth"
        ) {
          if (
            !localStorage.getItem("TRONG_activeNetwork") ||
            localStorage.getItem("TRONG_activeNetwork") === activeNetwork.title
          ) {
            try {
              await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: web3.utils.toHex(activeNetwork.chainId) }]
              });
            } catch (err) {
              if (err.code === 4902) {
                await window.ethereum.request({
                  method: "wallet_addEthereumChain",
                  params: activeNetwork.networkData,
                });
              }
            }
          } else {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params:
                networks[localStorage.getItem("TRONG_activeNetwork")].networkData,
            });
          }
        }
        const contractInstance = new web3.eth.Contract(
          activeNetwork.ABI,
          activeNetwork.contract
        );
        setContract(contractInstance);
        setActiveContract(contractInstance);
        account.length > 0 && setActiveAccount(account[0]);
        setWeb3Instance(web3);
        setProvider(provider);
        setWeb3Modal(web3Modal);
        setpopupOpen(true);
        //console.log(contractInstance)
        //console.log(web3)
        //console.log(account)

        fetchDataFromContract(contractInstance, web3, account[0]);
        localStorage.setItem("TRONG_account", account[0]);
      }
      else if (activeNetwork.type === "1") {
        console.log(" 333")

      }
      else {
        console.log(" dsd ")
        console.log(activeNetwork)
      }
    }
  };

  /////////////////

  const fetchImportantData = async (contract, web3, account) => {
    if ((activeNetwork.type === "0") || (activeNetwork.type === "2")) {
      if (account) {
        await contract.methods.getUserInfo(account).call(async (error, deposits) => {
          if (!error) {
            console.log(deposits);
            setuserinfo([web3.utils.fromWei(deposits[0]), web3.utils.fromWei(deposits[1]), web3.utils.fromWei(deposits[2]), (deposits[3])]);
          }
        });

        const WalletBl = await web3.eth.getBalance(account);
        setActiveAccountBalance(web3.utils.fromWei(WalletBl));

        setActiveAccount(account);
        setWalletBalance(web3.utils.fromWei(WalletBl));


      } else {
        localStorage.removeItem("TRONG_account");
        setActiveAccount("");
      }
      if (contract) {
        await contract.methods
          .getUserInfo(account)
          .call(async (error, deposits) => {
            if (!error) {
              setuserinfo([web3.utils.fromWei(deposits[0]), web3.utils.fromWei(deposits[1]), web3.utils.fromWei(deposits[2]), (deposits[3])]);
            }
          });

        // await contract.methods.getSiteInfo().call((error, result) => {
        //   if (!error) {
        //     //setTotalDeposit(web3.utils.fromWei(result[0]));
        //     setTotalDeposit(Number(web3.utils.fromWei(result[0]))+ activeNetwork.bonusamount );
        //     //console.log(" 333333");
        //     localStorage.setItem(
        //       "TRONG_siteInfo",
        //       JSON.stringify([
        //         web3.utils.fromWei(result[0]),
        //       ])
        //     );
        //   }
        // });
      }
    } else {

    }
  };

  const fetchDataFromContract = async (contract, web3, account) => {
    //console.log(activeNetwork);

    if (usdPrice == 0) {
      console.log(ApplicationConstants.pricefrom);
      const usdPrice = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${activeNetwork.tokenId}&vs_currencies=usd&pos=4`);
      setUsdPrice(usdPrice.data[activeNetwork.tokenId].usd);
      //setminDeposit(networks[netTitle].mindeposit);
      setminDeposit(activeNetwork.mindeposit / usdPrice.data[activeNetwork.tokenId].usd);
      localStorage.setItem("TRONG_mindeposit", activeNetwork.mindeposit / usdPrice.data[activeNetwork.tokenId].usd);
    }
    if ((activeNetwork.type === "0") || (activeNetwork.type === "2")) {
      if (contract && !account) {
        //getLastDepositRealTime(activeNetwork.title);
        //console.log(" no account ")
        //console.log(contract);
        await contract.methods.getSiteInfo().call((error, result) => {
          if (!error) {
            //console.log(result);
            setTotalDeposit(Number(web3.utils.fromWei(result._totalInvested)));
            settotalUsers(Number((result._usercount)));
            settreecount(Number(result._treecount));
          }
        });
      }
      if (contract && account) {
        await contract.methods.getSiteInfo().call((error, result) => {
          if (!error) {
            //console.log(result);
            setTotalDeposit(Number(web3.utils.fromWei(result._totalInvested)));
            settotalUsers(Number((result._usercount)));
            settreecount(Number(result._treecount));
          }
        });
        await contract.methods.getUserInfo(account)
          .call(async (error, deposits) => {
            if (!error) {
              console.log(deposits);
              setuserinfo([web3.utils.fromWei(deposits[0]), web3.utils.fromWei(deposits[1]), web3.utils.fromWei(deposits[2]), (deposits[3])]);
            }
          });
        console.log(" sassasdasd " + activeNetwork.type)
        if (activeNetwork.type == "0") {
          console.log(account);
          web3.eth.getBalance(account, function (error, result) {
            if (!error) {
              setWalletBalance(web3.utils.fromWei(result));
              setActiveAccountBalance(web3.utils.fromWei(result));
            }
          });
        }
        else if (activeNetwork.type == "2") {
          var contractInstance = new web3.eth.Contract(ABI, activeNetwork.TokenContract);
          contractInstance.methods.balanceOf(account).call(async (err, ethresult) => {
            if (!err) {
              setWalletBalance(ethers.utils.formatUnits(ethresult, activeNetwork.TokenDecimals));
              setActiveAccountBalance(ethers.utils.formatUnits(ethresult, activeNetwork.TokenDecimals));
            }
          });
        }
        else {
          //console.log(account);
          //console.log("xxxxx "+ activeNetwork.TokenContract+"  ")

        }
        if (!Contract) {
          //getLastDepositRealTime(activeNetwork.title);
        }
        //getRealTimeData(contract,null, web3);
      }
    } else {
      console.log("sample")
    }
    if (account) {
      setUserTransactions([]);
      //await getGlobalInformation("0x8cCF2480a17D09d434Cd74c394e428A1b7891Cbd");
      await getGlobalInformation(account);
    }
  };

  const invest = async (treeid, boardid, refAccount) => {
    if (isNaN(boardid))
      boardid = 1;
    if (isNaN(treeid))
      treeid = 1;
    let amount = (Number(boardid) == 0 ? 25 : (Number(boardid) == 1 ? 50 : 100)) / usdPrice;
    console.log(amount);
    console.log(refAccount);
    if (activeAccount) {
      //console.log(provider.chainId);
      // if(provider.chainId != activeNetwork.chainId)
      // {
      //   console.log(amount,activeAccountBalance);
      //   console.log(" 1111");
      //   //toast.warning("Your entered amount is less than minimum deposit");
      //   // setgamePlayState(1);
      //   toast.warning("Wrong Network connected");
      // }
      // else
      if ((useremail == null) || (useremail == "") && (userTransactions.length == 0)) {
        toast.warning("please enter your email id to get subscribed");
        setshowemailprompt(true);
      }
      else if (usdPrice * amount <= activeNetwork.mindeposit) {
        console.log(amount, activeAccountBalance);
        console.log(" 2222");
        //toast.warning("Your entered amount is less than minimum deposit");
        // setgamePlayState(1);
        toast.warning("minimum deposit is " + numberWithCommas(activeNetwork.mindeposit / usdPrice, 5) + "  " + activeNetwork.unit);
      }
      else if ((showreferralprompt == false) && (refAccount.toLowerCase() == ApplicationConstants.defaultreferral.toLowerCase())) {
        setshowreferralprompt(true);
      }
      else if (Number(amount) < Number(activeAccountBalance)) {
        if ((activeNetwork.type === "0")) {
          setshowemailprompt(false);
          setshowreferralprompt(false);
          //console.log(amount);
          //console.log(web3Instance.utils.toWei(amount));
          //console.log(amount);
          await activeContract.methods
            .Register(refAccount, treeid, boardid)
            .send(
              { from: activeAccount, value: web3Instance.utils.toWei(amount + "") },
              (error, result) => {

              }
            )
            .on("receipt", function (receipt) {
              setpopupstatus("1");
              //console.log(numberWithCommas(web3Instance.utils.fromWei(receipt.events.NewSale.returnValues.tokensreceived),3));
              console.log(receipt);
              if ((useremail != null) && (useremail != "") && (userTransactions.length == 0)) {
                servicerequest.post("/updateuser/", "account=" + activeAccount + "&email=" + useremail);
              }
              setUserTransactions([]);
              toast.success(<ToastMsg receipt={receipt} explorer={activeNetwork.explorer} type="gameplay" />);
              fetchImportantData(Contract, web3Instance, activeAccount);
              fetchDataFromContract(Contract, web3Instance, activeAccount);
              //getGlobalInformation(activeAccount);
            });
        } else if ((activeNetwork.type === "2")) {
          console.log(" invalid network");
        } else {
          toast.warning("Connect to your Wallet");
        }
      } else {
        console.log(amount, activeAccountBalance);
        console.log(" 2222");
        toast.warning("Your entered amount is more than your balance");
      }
    } else {
      toast.warning("Connect to your wallet");
      connectToWallet("wallet");
    }
  };
  const Reinvest = async (treeid, boardid, refAccount) => {
    if (isNaN(boardid))
      boardid = 1;
    if (isNaN(treeid))
      treeid = 1;
    let amount = truncateToDecimals((Number(boardid) == 0 ? 25 : (Number(boardid) == 1 ? 50 : 100)) / usdPrice, 15);
    console.log(amount);
    if (activeAccount) {
      //console.log(provider.chainId);
      // if(provider.chainId != activeNetwork.chainId)
      // {
      //   console.log(amount,activeAccountBalance);
      //   console.log(" 1111");
      //   //toast.warning("Your entered amount is less than minimum deposit");
      //   // setgamePlayState(1);
      //   toast.warning("Wrong Network connected");
      // }
      // else
      if ((useremail == null) || (useremail == "") && (userTransactions.length == 0)) {
        toast.warning("please enter your email id to get subscribed");
        setshowemailprompt(true);
      }
      else if (usdPrice * amount <= activeNetwork.mindeposit) {
        console.log(amount, activeAccountBalance);
        console.log(" 2222");
        //toast.warning("Your entered amount is less than minimum deposit");
        // setgamePlayState(1);
        toast.warning("minimum deposit is " + numberWithCommas(activeNetwork.mindeposit / usdPrice, 5) + "  " + activeNetwork.unit);
      }
      else if (Number(amount) < Number(activeAccountBalance)) {
        if ((activeNetwork.type === "0")) {
          setshowemailprompt(false);
          //console.log(amount);
          //console.log(web3Instance.utils.toWei(amount));
          //console.log(amount);
          await activeContract.methods
            .RegisterRejoin(refAccount, treeid, boardid)
            .send(
              { from: activeAccount, value: web3Instance.utils.toWei(amount + "") },
              (error, result) => {

              }
            )
            .on("receipt", function (receipt) {
              setpopupstatus("1");
              console.log(receipt);
              if ((useremail != null) && (useremail != "") && (userTransactions.length == 0)) {
                servicerequest.post("/updateuser/", "account=" + activeAccount + "&email=" + useremail);
              }
              setUserTransactions([]);
              toast.success(<ToastMsg receipt={receipt} explorer={activeNetwork.explorer} type="gameplay" />);
              fetchImportantData(Contract, web3Instance, activeAccount);
              fetchDataFromContract(Contract, web3Instance, activeAccount);
              //getGlobalInformation(activeAccount);
            });
        } else if ((activeNetwork.type === "2")) {
          console.log(" invalid network");
        } else {
          toast.warning("Connect to your Wallet");
        }
      } else {
        console.log(amount, activeAccountBalance);
        console.log(" 2222");
        toast.warning("Your entered amount is more than your balance");
      }
    } else {
      toast.warning("Connect to your wallet");
      connectToWallet("wallet");
    }
  };

  const disconnectWallet = async () => {
    if (web3Modal) {
      await web3Modal.clearCachedProvider();
    }
    setuserinfo(["0", "0", "0", "0"]);
    setActiveAccount(null);
    setActiveAccountBalance(null);
    localStorage.removeItem("WEB3_CONNECT_CACHED_PROVIDER");
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("TRONG_account");
    localStorage.removeItem("TRONG_baseRef");
    setProvider(null);
    setUserTransactions([]);
    setuseremail("");
  };

  const getHistoryInfo = async () => {
    if (activeAccount) {
      if (activeNetwork.type === "0") {

        await activeContract.methods
          .getUserInfo(activeAccount)
          .call(async (error, deposits) => {
            if (!error) {
              setuserinfo([web3Instance.utils.fromWei(deposits[0]), web3Instance.utils.fromWei(deposits[1]), web3Instance.utils.fromWei(deposits[2]), (deposits[3])]);
            }
          });

      }
      else if (activeNetwork.type === "2") {

        await activeContract.methods
          .getUserInfo(activeAccount)
          .call(async (error, deposits) => {
            if (!error) {
              setuserinfo([web3Instance.utils.fromWei(deposits[0]), web3Instance.utils.fromWei(deposits[1]), web3Instance.utils.fromWei(deposits[2]), (deposits[3])]);
            }
          });

      } else {
        console.log("Login Your TRX Wallet");

      }
    } else {
      toast.warning("Connect to your wallet");
    }
  };

  const context = {
    provider: provider,
    web3Instance: web3Instance,
    Contract: Contract,
    usdPrice: usdPrice,
    totalDeposit: totalDeposit,
    //totalRefEarn: totalRefEarn,
    walletBalance: walletBalance,
    userinfo: userinfo,
    harvestValue: harvestValue,
    historyTotalInfo: historyTotalInfo,
    userTransactions: userTransactions,
    //userInvitedInfo: userInvitedInfo,
    handleConnectToWallet: connectToWallet,
    handleDisconnectWallet: disconnectWallet,
    handleInvest: invest,
    handleGetHistoryInfo: getHistoryInfo,
    handleChangeNetwork: handleChangeNetwork,
    autoSlide: autoSlide,
    setAutoSlide: setAutoSlide,
    minDeposit: minDeposit,
    popupopen: popupopen,
    setpopupOpen: setpopupOpen,
    totalUsers: totalUsers,
    //////////
    useremail: useremail,
    setuseremail: setuseremail,
    activeNetwork: activeNetwork,
    activeAccount: activeAccount,
    activeAccountBalance: activeAccountBalance,
    //userHoldBonus: userHoldBonus,
    //depositBonus: depositBonus,
    popupstatus: popupstatus,
    getGlobalInformation,
    checkPolygonNetwork,
    checkEthNetwork,
    Reinvest,
    treecount,
    showemailprompt, setshowemailprompt, setshowreferralprompt, showreferralprompt
  };
  return (
    <BlockchainContext.Provider value={context}>
      {props.children}
    </BlockchainContext.Provider>
  );
};


export default BlockchainContext;
