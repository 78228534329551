import Footer from "./Footer"
import Header from "./Header"

const Seven = () => {
  return (
    <section>
      <div className="plansPage" style={{ paddingTop: "98px" }}>
        <Header />
        <div className="container-inner">
          <div className="row">
            <div className="col-sm-7 mx-auto">
              <div className="planDiagrams col-md-11">
                <div className="planDiagram parent">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      21  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      43 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      42 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      22  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      45 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      44 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      23  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      47 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      46 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      24  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      49 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      48 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      25  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      52 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      51 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      26  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      54 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      53 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      27  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      56 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      55 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      28  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                      58 </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      57 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      29  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                      60 </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                      59 </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      30  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                      62 </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                      61 </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      31  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                      64 </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                      63 </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      16  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      33 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      32 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      17  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      35 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      34 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      18  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">37 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      36 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      19  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      39 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      38 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      20  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      41 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      40 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">1 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">2      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">3      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">4      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">8      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">9      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">5      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">11      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">10      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">7      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">15      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">14      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">6      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">12      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">13      </text>
                    </g>
                  </svg></a>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/dUJxbEM3b2FsbkxwNnE5eUJxQmNXdz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      166  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      383 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      378 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      168  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      388 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      384 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      170  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      394 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      392 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      171  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      400 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      399 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      174  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      410 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      407 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      177  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      420 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      416 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      178  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      424 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      421 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      180  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                      440 </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      433 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      182  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                      442 </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                      441 </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      184  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                      445 </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                      443 </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      186  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                      452 </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                      449 </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      125  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      354 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      353 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      157  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      359 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      356 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      158  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">367 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      364 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      161  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      369 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      368 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      162  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      376 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      373 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">8 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">16      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">17      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">32      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">65      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">66      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">33      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">68      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">67      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">35      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">76      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">75      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">34      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">69      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">74      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/QjVvTi9UTkNraW9SUS9vTHhmMHlCdz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      93  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      462 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      460 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      119  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      473 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      466 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      130  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      475 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      474 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      159  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      482 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      476 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      187  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      486 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      485 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      188  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      488 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      487 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      189  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      497 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      492 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      191  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                      501 </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      500 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      197  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                      504 </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                      502 </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      202  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                      511 </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                      507 </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      203  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                      515 </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                      514 </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      80  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      266 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      257 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      84  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      282 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      270 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      85  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">355 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      296 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      90  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      426 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      403 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      91  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      457 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      431 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">9 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">18      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">19      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">36      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">50      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">70      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">37      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">72      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">71      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">39      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">79      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">78      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">38      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">73      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">77      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/ampBMmhSTndLL0lpUjA4ZVBvaUhpZz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      209  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      548 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      547 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      210  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      558 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      552 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      213  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      564 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      562 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      214  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      572 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      569 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      217  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      579 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      573 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      218  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      587 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      582 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      219  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      592 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      591 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      220  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                      604 </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      596 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      221  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                      617 </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                      616 </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      222  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                      627 </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                      620 </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      224  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                      632 </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                      630 </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      144  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      414 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      404 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      195  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      471 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      459 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      204  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">517 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      472 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      205  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      527 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      525 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      208  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      543 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      536 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">10 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">20      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">21      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">40      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">81      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">92      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">41      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">98      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">94      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">43      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">115      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">112      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">42      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">102      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">104      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/V3NiMHBWTWJQZ0FFNHlTclR2bXVDZz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      228  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      306 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      304 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      229  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      311 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      308 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      230  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      314 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      313 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      231  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      323 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      318 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      232  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      329 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      327 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      235  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      334 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      332 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      236  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      346 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      340 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      237  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                      348 </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      347 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      238  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                      358 </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                      349 </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      240  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                      377 </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                      370 </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      242  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                      390 </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                      379 </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      199  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      253 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      245 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      212  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      273 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      267 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      216  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">279 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      278 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      225  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      289 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      281 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      227  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      303 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      293 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">11 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">22      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">23      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">44      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">121      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">123      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">45      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">127      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">124      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">47      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">134      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">133      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">46      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">131      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">132      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/TmpRVWw5Ukk3UEFlRFJXTmFjN2UrZz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      117  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      181 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      179 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      118  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      185 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      183 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      120  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      192 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      190 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      122  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      194 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      193 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      129  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      206 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      196 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      136  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      211 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      207 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      137  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      233 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      223 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      141  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                      239 </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      234 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      147  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                      246 </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                      241 </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      149  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                      249 </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                      248 </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      152  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                      254 </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                      252 </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      107  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      155 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      154 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      108  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      164 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      156 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      111  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">167 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      165 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      113  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      172 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      169 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      114  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      176 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      173 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">12 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">24      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">25      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">48      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">87      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">89      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">49      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">96      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">95      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">52      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">106      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">105      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">51      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">100      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">103      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/RldVU0kxb1RiQ3JEZ1BpVmFxemtxUT09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      251  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      678 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      675 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      255  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      683 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      680 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      258  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      691 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      688 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      259  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      706 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      699 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      260  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      720 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      713 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      262  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      753 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      739 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      264  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      825 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      805 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      265  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                      851 </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      837 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      268  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                      861 </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                      855 </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      271  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                      929 </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                      891 </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      272  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                      976 </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                      959 </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      198  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      635 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      508 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      243  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      638 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      637 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      244  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">643 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      640 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      247  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      662 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      646 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      250  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      672 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      664 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">13 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">26      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">27      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">53      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">97      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">99      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">54      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">109      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">101      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">56      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">138      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">128      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">55      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">110      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">116      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/M0xBTldnT3R4Wkdkd3ZJZHNNaG9Bdz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      288  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      1258 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      1251 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      290  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      1300 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      1279 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      295  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      1339 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      1320 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      298  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      1351 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      1342 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      301  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      1355 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      1352 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      305  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      1367 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      1361 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      307  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      1374 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      1368 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      309  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                      1379 </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      1375 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      310  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                      1388 </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                      1384 </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      317  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                      1396 </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                      1391 </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      319  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                      1428 </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                      1426 </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      274  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      1014 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      1001 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      275  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      1043 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      1020 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      276  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">1083 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      1072 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      277  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      1134 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      1118 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      285  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      1240 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      1211 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">14 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">28      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">29      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">57      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">82      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">88      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">58      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">142      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">140      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">60      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">151      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">148      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">59      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">145      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">146      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/Vi92cjgzZTUrM0VDczZ0Q2xBbUhhdz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      331  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      833 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      832 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      333  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      936 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      844 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      336  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      967 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      963 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      338  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      978 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      970 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      339  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      996 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      981 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      341  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      1024 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      999 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      343  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      1063 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      1026 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      344  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                      1115 </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      1070 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      350  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                      1429 </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                      1166 </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      351  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                      1436 </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                      1431 </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      352  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                      1498 </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                      1440 </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      160  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      735 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      725 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      163  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      770 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      764 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      175  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">791 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      783 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      320  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      802 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      792 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      326  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      826 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      817 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">15 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">30      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">31      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">61      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">83      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">86      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">62      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">135      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">126      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">64      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">153      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">150      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">63      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">139      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">143      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </section>

  )
}

export default Seven