import { useState, useEffect, useContext } from "react";
import BlockchainContext from "../store/BlockchainContext";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import Footer from "./Footer"
import Header from "./Header"
import { Link } from "react-router-dom";
import { ApplicationConstants } from "../utils/ApplicationConstants";
import servicerequest from "../utils/config";

const News = () => {
  const [news, setnews] = useState([]);
  const gettreedata = async (event) => {
    
      const res = await servicerequest.post("/getnews/");
      console.log(res.data);
      setnews(res.data.news);
  }; 
  useEffect(() => {
    gettreedata();
  }, []);
  return (
    <section className="homepage">
      <Header />
      <div class="row" style={{ textAlign: "center", display: "block ruby", color:"#FFF" }}>
        <div class="col-md-11">
          <h4 >IN THE NEWS</h4>
        </div>
      </div>
      <div class="row" style={{ textAlign: "center", display: "block ruby", color:"#ffd840" }}>
        <div class="col-md-11">
          <p >Our services with the project outcome.</p>
        </div>
      </div>
      <div class="row" >
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row" >
            <div class="col-md-8">
            {news&& news.length >0 ? (
               <Link to={"/newsinfo/"+news[0].news_id}>
                <div>
                  <img src={news[0].news_image} />
                  <br />
                  <div style={{ backgroundColor: "#FFF", color:"#000",padding: "10px 20px 10px 20px", marginTop: "-2px", position: "relative", top: "-4px", margin: "-4px 20px auto" }}>
                    <h4>{news[0].news_title} </h4>
                    <p style={{height: "200px",  overflow: "hidden"}}  dangerouslySetInnerHTML={{ __html: news[0].news_description }}></p>
                  </div>
                </div>
              </Link>
              ):(<></>)}
            </div>
            <div class="col-md-4">
              {news ? news.map((info, index) => (
                <>
              {((index > 0 ) && (index < 5))?(
                <>
                <Link to={"/newsinfo/"+news[index].news_id}>
                <div className="row" >
                    <div class="col-md-6">
                      <img src={news[index].news_image} />
                    </div>
                    <div class="col-md-6">
                      <h6 data-id="quill-node-0">{news[index].news_title}</h6>
                    </div>
                  </div>
                </Link>
                <hr></hr>
                </>
                ):(<></>)}
                </>
              )) : null}
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <br/><br/><br/>
      <div class="container" style={{backgroundColor:"#FFF"}}>
        <div class="row" style={{padding: "20px"}}>
        {news ? news.map((info, index) => (
                <>
              {((index > 5 ) && (index < 9))?(
			
              <div class="col-sm">
                <Link to={"/newsinfo/"+news[index].news_id}>
                <div className="row">
                  <div class="col-md-12">
                    <img src={news[index].news_image} />
                  </div>
                  <div class="col-md-12">
                    <h5 data-id="quill-node-0" style={{color:"#000"}}>{news[index].news_title}</h5>
                    <p data-id="quill-node-0" style={{height: "100px",  overflow: "hidden",color:"#000"}} dangerouslySetInnerHTML={{ __html: news[index].news_description }} ></p>
                  </div>
                </div>
                </Link>
              </div>
			
          ):(<></>)}
                </>
              )) : null}
        </div>
      </div>
      <Footer />
    </section>
  )
}
export default News
