import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import "./far.scss";
import "./Connection.css";
import App from "./App";
import { BlockchainContextProvider } from "./store/BlockchainContext";
import { HistoryModalContextProvider } from "./store/HistoryModalContext";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <BlockchainContextProvider>
    <HistoryModalContextProvider>
      <App />
      <ToastContainer />
    </HistoryModalContextProvider>
  </BlockchainContextProvider>,
  document.getElementById("root")
);

// ReactDOM.render(
//   <BlockchainContextProvider>
//     <HistoryModalContextProvider>
//       <App />
//       <HistoryModal />
//       <ToastContainer />
//     </HistoryModalContextProvider>
//   </BlockchainContextProvider>,
//   document.getElementById("root")
// );
