import { useContext } from "react";
import BlockchainContext from "../store/BlockchainContext";
import { ApplicationConstants } from "../utils/ApplicationConstants";
import { Link } from "react-router-dom";

import Footer from "./Footer"
import Header from "./Header"
import { toast } from "react-toastify";
import { numberWithCommas } from "../utils/util";
const HomeScreen = () => {
  const { totalDeposit, handleInvest, usdPrice,userTransactions,useremail, setuseremail,showemailprompt,setshowemailprompt,setshowreferralprompt,showreferralprompt } = useContext(BlockchainContext);
  const checkvalidity = (_treeid, _boardid)=>{
    for( let loopvar=0;loopvar <userTransactions.length;loopvar++)
    {
      if( (userTransactions[loopvar].treeid == _treeid) && (userTransactions[loopvar].board == _boardid) )
      return false;
    }
    return true;
  }
  const closepopup=()=> {
    setshowreferralprompt(false);
    setshowemailprompt(false);
    setuseremail("");
  }
  const handleSubmitForm = async (event) => {
    event.preventDefault();
    if(checkvalidity(1,0))
    {
      handleInvest(1, 0, ApplicationConstants.defaultreferral);
    }
    else if(checkvalidity(1,1))
    {
      handleInvest(1, 1, ApplicationConstants.defaultreferral);
    }
    else if(checkvalidity(1,2))
    {
      handleInvest(1, 2, ApplicationConstants.defaultreferral);
    }
    else
    {
      toast.success("you joined all the boards");
    }
  };
  return (
    <section className="homepage">

      <Header />
      {/* Header End */}
      <input type="hidden" id="baseurl" defaultValue="https://www.bnbcrowdfunding.com/" />
      <input type="hidden" id="base_url" defaultValue="https://www.bnbcrowdfunding.com/" />
      <input type="hidden" id="curr_lang" defaultValue="en" />
      {(showemailprompt==true)?(
      <div >
        <div className="overlay"></div>
        <div className="modalPopup">
          <span class="close" onClick={closepopup}></span>
          <br/>
          <h3>Get notified with all our updates to your email</h3>
          <br/>
          <div >
            <label for="email">Enter your email:</label>&nbsp;
            <input type="email" id="email" name="email" placeholder="email" value={useremail} onChange={(event) => {setuseremail(event.target.value);}}/> 
            <div className="hplanSliderLink pl-3" >
                  <a onClick={handleSubmitForm} ><button className="btn noBoxShadow btnBr30 btnPrimay buttonsubmit">
                    JOIN</button></a>
                  </div>
          </div>
        </div>
      </div>
      ):(<></>)}
      {(showreferralprompt==true)?(
      <div >
        <div className="overlay"></div>
        <div className="modalPopup">
          <span class="close" onClick={closepopup}></span>
          <br/>
          <h3 className="cautiontext">You are joining without a referral link. We will like to notify you that this will still activate your account but your referrer will not get credit as you are proceeding without a referral link. Would you still like to proceed. If yes, click “JOIN” below. Click “CLOSE” to proceed again with the “Referral Link”.</h3>
          <br/>
          <div >
            <div className="hplanSliderLink pl-3" >
                  <a onClick={closepopup} ><button className="btn noBoxShadow btnBr30 btnPrimay buttoncancel">
                    Close</button></a> &nbsp;
                    <a onClick={handleSubmitForm} ><button className="btn noBoxShadow btnBr30 btnPrimay buttonsubmit">
                    JOIN</button></a>
                  </div>
          </div>
        </div>
      </div>
      ):(<></>)}
      <div id="cover-spin" style={{ display: 'none' }}>
        <div className="test">
          <center>
            <p className="show_text"> Transaction in Process... <br /> Please don’t click or refresh the page. <br />
              Please check your transaction on Tron link. </p>
          </center>
        </div>
      </div>    <div id="sideMenu">
      </div>
      <div id="singleWindow">
        {/* Banner Start */}
        <div className="fullscreen banner">
          <div className="container containerTwo">
            <div className="bannerContent">
              <div className="row flex-column ">
                <div className="col-sm-12" />
                <div className="col-sm-12" style={{transform: "translateY(-50%)"}}>
                  <div className="bannerMiddleContent">
                    <h2> TOTAL CROWDFUND DISBURSED</h2>
                    <div className="bannerCalc">
                      <div className="input-group">
                        <input type="text" className="form-control" name="trx_value" value={numberWithCommas(totalDeposit,8)} id="trx_value" readOnly />
                        <div className="input-group-append">
                          <span className="input-group-text">.BNB</span>
                        </div>
                      </div>
                      <div className="bannerCalcValue">
                        <span className="bcvSign"> ≈ </span>
                        <span className="bcvValue" id="bcvValue"> {numberWithCommas(totalDeposit * usdPrice,4)} </span>
                        <span className="bcvCurrency"> USD </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">

                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Banner End */}
        {/* Home About Start */}
        <div className="fullscreen homeAbout" id="single2">
          <div className="container containerTwo">
            <div className="row align-items-center">
              <div className="col-sm-12">

                <div className="homeAboutContent">

                  <h2>THE TRON GOOGOL PROJECT</h2>
                  <h3 style={{ textAlign: "center",color:"#f89e42",fontSize: "45px",fontWeight: "700"}}>ASSIST ONLY 5 PEOPLE TO PARTICIPATE ON THIS PLATFORM WITH YOUR PERSONALIZED REFERRAL LINK AND YOU ARE ALL SET TO EARN CONTINUOUSLY.</h3>
                  <p><b>Tron GOOGOL BSC Decentralized Crowdfunding platform</b> is not a <b>MLM but it is built on a unique closed matrix strategy</b> on Binance Smart Chain where contribution received from the other members all the way to the end of the 3rd Cycle will allow every participant on the platform to earn equally as follows</p>
                  <div style={{ textAlign: "center" }}>
                    <ul style={{ display: "inline-block", textAlign: "left", color: "#FFF", fontSize: "18px" }}>
                      <li>Join with US $ 25 value of BNB and earn US $ 3875 (Starting with US $ 25 is mandatory)</li>
                      <li>Join with US $ 50 value of BNB and earn US $ 7750 (You can join with US $ 50 provided you have already joined with US $ 25)</li>
                      <li>Join with US $ 100 value of BNB and earn US $ 15500 (You can join with US $ 100 provided you have already joined with US $ 50) </li>
                      <li> Furthermore, <b>the platform will trigger an auto-entry for every user</b> in the chain once each user is crowd funded by other members in the community for each user’s matrix all the way to the end of the 3rd Cycle on the platform.  </li>
                      <li>The auto re-entry happens over and over again at the end of the 3rd cycle once you get funded by the community for your matrix thereby assuring consistent income for life. <b>This means that every participant will make US $ 3875, US $ 7750 and US $ 15500 over and over again for life.</b></li>
                      <li>The good thing is that <b>apart from amount that you will make from Crowd funding for your own matrix,</b> you will also make a referral income for every participant referred by you to Tron Googol. <b>There are no limits to the users that you can refer.  This is a great addon to your continuous earning potential.</b></li>
                    </ul>    
                    <div style={{ color: "#FFF", fontSize: "16px" }}>
                      <h3>It is the world's first revolutionary & disruptive Crowdfunding model that will change the course in which most of the smart contracts are operating in the Blockchain space. </h3>
                      <h3>It is a closed matrix & not a MLM assuring everyone to earn equally over a long period of time. </h3>
                    </div>
                    <div className="col-sm-12" style={{borderStyle: "solid",borderRadius: "12px",borderColor: "#FFF",background: "rgb(84, 33, 31)",color:"#FFF",padding:"10px"}}>
                      <h5>THERE IS NO HOLD OF FUNDS ON THE SMART CONTRACT, WHICH MEANS THE PARTICIPANTS DO NOT HAVE TO SENT ANY SORT OF REQUEST TO ANYONE TO RECEIVE THEIR EARNED BNB. ALL BNB REACHES RECIPIENTS WALLETS REAL-TIME FOR THE MATRIX IN WHICH EACH RECIPIENTS ARE PLACED ON THIS PLATFORM. THIS MEANS THAT THIS PLATFORM IS TRULY DECENTRALIZED WITH NO INTERVENTION OF ANY KINDS ASSURING FAIRNESS OF DEFI PROTOCOLS & PRINCIPALS.</h5>
                    </div>
                  </div>
                  <p><b>Click the JOIN button today & start receiving crowdfunded by the participants on Tron GOOGOL BSC for life.</b></p>
                </div>
              </div>
            </div>
          </div>
          {/* Scroll Section button Start TD4aZNsWJW3seMdxnUJojcaVxwo7K4fQHi*/}

          {/* Scroll Section button End */}
        </div>
        {/* Home About End */}
        {/* Home Plan Animation Start */}
        <div className="fullscreen homePlansAnimation" id="single3">
          <div className="container">
            <div className="planAniBack inactive">
              <div className="container containerTwo w-100">
                <button className="btn noBoxShadow noPadding hplanAniImgTrig-back">
                  Back                      </button>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-sm-12">
                <div className="row align-items-center flex-md-nowrap homePaniRow">
                  <div className="col-sm-12 col-md-12 col-lg-6 homePaniCol show">
                    <div className="hplanAniContent">
                      <br /><br /><br />
                      <h2>DECENTRALIZED CROWDFUNDING</h2>
                      <h3> CORE 7 ABUNDANCE</h3>
                      <p>Participate in the Core 7 Board, introduce only 5 people and Earn Residual Income 100% in Autopilot for life. Start building your team today! All you need is US $ 25, US $ 50 & US $ 100 to earn residual income for life. Press the “JOIN” button to get started.</p>
                      <div className="hplanAniLink">
                        <a className="btn noBoxShadow btnBr30 btnPrimay hplanAniImgTrig-join" onClick={handleSubmitForm} >JOIN</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6 homePaniCol show noMobile">
                    <div className="hplanAniImage">
                      <button className="btn noBoxShadow noPadding hplanAniImgTrig-join">
                        <img src="../assets/images/plans/plan-path1.png" alt="plan-a-matrix-diagram" className="img-fluid" />
                      </button>
                    </div>
                  </div>
                  <span className="scrollDText new_scrollDText">
                    <br /><br />
                  </span>
                  <div className="col-sm-12 col-md-12 col-lg-6 homePaniCol">
                    <div className="hplanAniImage hplanAniImageBuy" data-id={1} data-amount={25}>
                      <img src="../assets/images/plans/plan-a-matrix-diagram.png" alt="plan-a-matrix-diagram" className="img-fluid" />
                      <div className="hplanAniPrice">
                        <span className="hpAniPriceText"> Entry with</span>
                        <span className="hpAniPriceAmt">$25</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* Home Plan Animation End */}
        {/* Home Plan QA Start */}
        <div className="fullscreen homePlansQA" id="single4">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <h2 className="sectionTitle">CORE 7 ABUNDANCE</h2>
                <p className="sectionPara">PASSIVE INCOME CROWDFUNDING MODEL | INFINITE ABUNDANCE | FREQUENTLY ASKED QUESTIONS</p>
                <div className="hplanQAblocks">
                  <div className="row">
                    {/* QA content Start */}
                    <div className="col-sm-12 col-md-6">
                      <a className="popover__wrapper__" data-id={8}>
                        <div className="hplanQAcontent popover__wrapper">
                          <div className="d-flex align-items-center popover__title">
                            <div className="hplanQAicon">
                              <div className="hplanQAiconText">?</div>
                            </div>
                            <div className="hplanQAtext">
                              <h4 id="head_8">
                                WHY WOULD YOU JOIN? </h4>
                              <p id="content_8">If you were given <b>US $ 26625</b> in the next 3 weeks or so, what would you do with it?</p>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* QA content End */}
                    {/* QA content Start */}
                    <div className="col-sm-12 col-md-6">
                      <a className="popover__wrapper__" data-id={7}>
                        <div className="hplanQAcontent popover__wrapper">
                          <div className="d-flex align-items-center popover__title">
                            <div className="hplanQAicon">
                              <div className="hplanQAiconText">?</div>
                            </div>
                            <div className="hplanQAtext">
                              <h4 id="head_7">
                                HOW WILL THIS INFLUENCE YOU?</h4>
                              <p id="content_7">
                                It is an amazing opportunity that can potentially change
                                the trajectory of your financial life.</p>
                              <input type="hidden" name="l_content" id="l_content_7" defaultValue="It is an amazing opportunity that can potentially change the trajectory of your financial life.<br><br>Created for generating WEALTH FOR LIKE-MINDED PEOPLE by working as a community to reach personal and professional goals by displaying equal efforts to achieve equal outcomes." />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* QA content End */}
                    {/* QA content Start */}
                    <div className="col-sm-12 col-md-6">
                      <a className="popover__wrapper__" data-id={6}>
                        <div className="hplanQAcontent popover__wrapper">
                          <div className="d-flex align-items-center popover__title">
                            <div className="hplanQAicon">
                              <div className="hplanQAiconText">?</div>
                            </div>
                            <div className="hplanQAtext">
                              <h4 id="head_6">
                                TEAM'S COMMITMENT</h4>
                              <p id="content_6">Unlike MLM, it is a closed matrix. Few people together as a team can take the advantage of this unique matrix which will assure passive income for life.  </p>
                              <input type="hidden" name="l_content" id="l_content_6" defaultValue="How do I fit into the team's commitment circle?<br><br>~I AM A VISUAL PERSON. <br>~PLEASE SHOW ME WHAT THE GIVE-IN-CYCLE LOOKS LIKE.<br><br>Please watch the Video Presentation here https://youtu.be/DQ9Jbs-oPb8" />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* QA content End */}
                    {/* QA content Start */}
                    <div className="col-sm-12 col-md-6">
                      <a className="popover__wrapper__" data-id={5}>
                        <div className="hplanQAcontent popover__wrapper">
                          <div className="d-flex align-items-center popover__title">
                            <div className="hplanQAicon">
                              <div className="hplanQAiconText">?</div>
                            </div>
                            <div className="hplanQAtext">
                              <h4 id="head_5">
                                HISTORY OF GIVING</h4>
                              <p id="content_5">
                                Crowdfunding may seem like a new idea, but it actually has a long and rich history with roots going back to the 1700’s.</p>
                              <input type="hidden" name="l_content" id="l_content_5" defaultValue="Crowd-funding may seem like a new idea, but it actually has a long and rich history with roots going back to the 1700’s. Some of the finding in different cultures of the world are as below.<br><br>ASIAN CULTURE<br><br>Members of a family will put in money to contribute to one family getting a business. All members will work at that one family’s business until enough wealth is generated to open the next family’s business.<br><br>JAMAICAN CULTURE<br><br>Individuals come together by giving designated person a certain amount of money on a weekly basis and at the end of the year that you have contributed is returned in totality. There is no additional profit is made on the contribution.<br><br>HISPANIC CULTURE<br><br>Families contribute a weekly set amount to a certain family for a set period of time. One the time is fulfilled, each family continues to contribute and the next family collects. There is also no profit made on this contribution." />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* QA content End */}
                    {/* QA content Start */}
                    <div className="col-sm-12 col-md-6">
                      <a className="popover__wrapper__" data-id={4}>
                        <div className="hplanQAcontent popover__wrapper">
                          <div className="d-flex align-items-center popover__title">
                            <div className="hplanQAicon">
                              <div className="hplanQAiconText">?</div>
                            </div>
                            <div className="hplanQAtext">
                              <h4 id="head_4">
                                WHEN YOU COMPLETE THE 3RD CYCLE</h4>
                              <p id="content_4">You can earn US $ 3875, US $ 7750 & US $ 15500 worth of BNB approximately by Crowdfunding from other participants on the platform by joining with US $ 25, US $ 50 & US $ 100 in BNB respectively. Moreover, you will qualify for auto re-entry over the over again at the end of the 3rd cycle thereby qualifying you to earn the same amount over & over lifelong. </p>
                              <input type="hidden" name="l_content" id="l_content_4" defaultValue="You will earn US $ 25, 967.75, US $ 51,917.50 & US $ 103,835 over and over again from 3 boards respectively.<br><br>Please watch the Video Presentation here to learn how https://youtu.be/DQ9Jbs-oPb8" />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* QA content End */}
                    {/* QA content Start */}
                    <div className="col-sm-12 col-md-6">
                      <a className="popover__wrapper__" data-id={3}>
                        <div className="hplanQAcontent popover__wrapper">
                          <div className="d-flex align-items-center popover__title">
                            <div className="hplanQAicon">
                              <div className="hplanQAiconText">?</div>
                            </div>
                            <div className="hplanQAtext">
                              <h4 id="head_3">
                                HOW TO JOIN AND REJOIN?</h4>
                              <p id="content_3">
                                You can Join with US $ 25, US $ 50 & US $ 100 value in BNB respectively. Once you have reached you income level of US $ 3875, US $ 7750 & US $ 15500 worth of BNB, you will qualify for a re-entry which you can do by rejoining again with US $ 25, US $ 50 & US $ 100 value in BNB respectively. You can do this as many time as you want once you have reached your income level of US $ 3875, US $ 7750 & US $ 15500 worth of BNB every time. </p>
                              <input type="hidden" name="l_content" id="l_content_3" defaultValue="Once you have filled the board and reached the income of the Core 7 board, you can re-enter these boards as many times as you want.<br><br>~There are 3 boards where you need US $ 25, US $ 50 & US $ 100 of Tron Cryptocurrency for starting participating in these boards. You can join either one or two or all three boards at the same time.<br>~Once you have filled a board and reached the income of the board, you can reenter these boards as many times as you want to achieve the maximum income for each one of these boards.<br>~There is no end to how much you can make. Participate in the boards as many times as you want." />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* QA content End */}
                    {/* QA content Start */}
                    <div className="col-sm-12 col-md-6">
                      <a className="popover__wrapper__" data-id={2}>
                        <div className="hplanQAcontent popover__wrapper">
                          <div className="d-flex align-items-center popover__title">
                            <div className="hplanQAicon">
                              <div className="hplanQAiconText">?</div>
                            </div>
                            <div className="hplanQAtext">
                              <h4 id="head_2">
                                LUCRATIVE REFERRAL INCOME</h4>
                              <p id="content_2">In addition to your earnings from CROWDFUNDING which assures <b>US $ 3875, US $ 7750 and US $ 15500</b> approximately, you will also earn continuously by referring others to the Tron Googol BSC platform. You will earn 5%, 4% & 3% as a referral fee amount from US $ 25, US $ 50 & US $ 100 value in BNB respectively for every person that you will be referring to Tron Googol BSC. </p>
                              <input type="hidden" name="l_content" id="l_content_2" defaultValue="The mission of GIVING FORWARD  is to unite the Tron Googol community to come forward by creating awareness and raising money for charitable endeavors that directly impact those close to us.<br><br>GF (Giving Forward) is formed in order to help those who may need a little extra help in life. We may not all have a lot to give, but we can all help in giving back by assisting together. Whether it is an issue where someone is suffering in diseases such as diabetes or cancer, or something closer to our community such as helping a family who lost everything in a house fire. There are so many unforeseen challenges faced by so many people all over the world. Our goal is to give forward by creating awareness about our community which supports people in need and raises money for charities that directly impact those close to us. GF consists of ordinary people like you and me, with normal everyday jobs that want to give back in any way that we can to uplift others from miseries.<br><br>There is a lot to do in order to get GF where we want it. Volunteers will be needed for our upcoming Ride. In addition, GF is welcoming community members and business professionals alike to join our team. Help us help others in our GF initiative.<br><br>
You can contribute to GIVING FORWARD FUND by sending TRX to the following address;<br>TUZSE5AZRWgcMJRfsA8DeS9cCDvqCJ8iER.<br><br>There is no cap to how much you want to contribute. It is totally up to you. All funds contributed by the Tron Googol Community will be used for the GIVING FORWARD initiative. Please be requested to send the transaction HASH to gf@bnbcrowdfunding.com along with your name, email, and contact details once the transaction is made. We will publish your name as a note of thanks for coming forward to assist people in need.<br><br>Thank you" />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* QA content End */}
                    {/* QA content Start */}
                    <div className="col-sm-12 col-md-6">
                      <a className="popover__wrapper__" data-id={1}>
                        <div className="hplanQAcontent popover__wrapper">
                          <div className="d-flex align-items-center popover__title">
                            <div className="hplanQAicon">
                              <div className="hplanQAiconText">?</div>
                            </div>
                            <div className="hplanQAtext">
                              <h4 id="head_1">
                                COMMUNITY UPLIFTMENT PROJECTS</h4>
                              <p id="content_1">
                                If you know of a project which will be going to uplift a community, please do let us know the story and we will connect with you. </p>
                              <input type="hidden" name="l_content" id="l_content_1" defaultValue="Countries have a lot of work to do to achieve the United Nations’ Sustainable Development Goals by 2030. But the development of projects doesn’t always go the way you expect. Therefore, it is the responsibility of the public and well as private entities to come forward together in whatever capacity each one can do to assist in sustainable development projects. The TRON GOOGOL Community is dedicated and will play a very important role by participating in different community projects to facilitate and uplift underprivileged in the areas of EDUCATION, SANITATION, DRINKING WATER,  HEALTH & MEDICAL.<br><br>The world is facing a dire emergency. Now, more than ever, we need access to science and facts – not vested interests. You & us can help together.<br><br>If you know of a project which will be going to uplift a community, please do let us know the story and we will connect with you to listen to your stories to find a way to assist communities seeking assistance. For information, please send an email to sd@bnbcrowdfunding.com " /> 
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* QA content End */}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* Home Plan QA End */}
        {/* Home Plan Slider Start */}
        <div className="fullscreen homePlansSlider pad_7" id="single5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-4">
                <figure >
                  <figure>
                    <div className="plan_sec">
                      <h4>Option 1</h4>
                      <h3> Receive Crowdfund <br/>155 Times from Members <br/>For Your Matrix <br/>Over & Over Again</h3>
                      <ul>
                        <li>Contribute  US $ 25</li>
                        <li>Others contribute to you US $ 3875 for every 3 cycles</li>
                        <li>Introduce only 5 people & recieve Residual earnings 100% in Autopilot for life.</li>
                        <li>In addition you make 5% referral Bonus on every joining with your referral link</li>
                      </ul>
                      <Link to={"/buy/1/0/"+(ApplicationConstants.defaultreferral)} style={{textAlign: "center",  display: "block"}}><button className="btn noBoxShadow btnBr30 btnPrimay buttonsubmit"> JOIN</button></Link>
                    </div>
                  </figure>
                </figure>
              </div>
              <div className="col-sm-4">
                <figure>
                  <figure>
                    <div className="plan_sec">
                      <h4>Option 2</h4>
                      <h3> Receive Crowdfund <br/>155 Times from Members <br/>For Your Matrix <br/>Over & Over Again</h3>
                      <ul>
                        <li>Contribute US $ 50 </li>
                        <li> Others contribute to you US $ 7750 for every 3 cycles</li>
                        <li>Introduce only 5 people & recieve Residual earnings 100% in Autopilot for life.</li>
                        <li>In addition you make 4% referral Bonus on every joining with your referral link</li>
                      </ul>
                      <Link to={"/buy/1/1/"+(ApplicationConstants.defaultreferral)} style={{textAlign: "center",  display: "block"}}><button className="btn noBoxShadow btnBr30 btnPrimay buttonsubmit"> JOIN</button></Link>
                    </div>
                  </figure>
                </figure>
              </div>
              <div className="col-sm-4">
                <figure>
                  <figure>
                    <div className="plan_sec">
                      <h4>Option 3</h4>
                      <h3> Receive Crowdfund <br/>155 Times from Members <br/>For Your Matrix <br/>Over & Over Again</h3>
                      <ul>
                        <li>Contribute US $ 100</li>
                        <li> Others contribute to you US $ 15500 for every 3 cycles</li>
                        <li>Introduce only 5 people & recieve Residual earnings 100% in Autopilot for life.</li>
                        <li>In addition you make 3% referral Bonus on every joining with your referral link</li>
                      </ul>
                      <Link to={"/buy/1/2/"+(ApplicationConstants.defaultreferral)} style={{textAlign: "center",  display: "block"}}><button className="btn noBoxShadow btnBr30 btnPrimay buttonsubmit"> JOIN</button></Link>
                    </div>
                  </figure>
                </figure>
              </div>
              {/* <div class="col-sm-6">
                        <div class="plan_details homepSliderTBcontent ">
                            < ?php if ($plan) {
                              $i = 0;
                              foreach ($plan as $result) {
                              if ($i == 1) {break;}
                            ?>

                            <h2>
                                < ?php echo lang('LWT'); ?>
                            </h2>
                            <h4>< ?php echo lang('ASP'); ?></h4>
                            <div class="homepSliderTBscroll">
                                <h3> < ?php echo lang('Hold bonus'); ?></h3>
                                <p>< ?php echo $result->hold_bonus ?></p>
                                <h3> < ?php echo lang('Fund bonus'); ?></h3>
                                <p>< ?php echo $result->fund_bonus ?></p>
                                <h3> < ?php echo lang('Referral bonus'); ?></h3>
                                <p>< ?php echo $result->referral_bonus ?></p>
                                <h3> < ?php echo lang('Referral rewards'); ?></h3>
                                <ul>
                                    < ?php echo $result->referral_rewards ?>
                                </ul>
                            </div>
                            < ?php $lang_url = FS::uri()->segment(1);?>
                            <div class="hplanSliderLink">
                                <a href="https://livewithtron.io" target="_blank"><button 
                                        class="btn noBoxShadow btnBr30 btnPrimay">
                                        < ?php echo lang('JOIN'); //juego_id() == '' || empty($ref_url_b) ? lang('JOIN') : lang('Deposit');              ?></button></a>

                                <a href="< ?php echo base_url() . $lang_url . '/cms/plan'; ?>"> <button
                                        class="btn noBoxShadow btnBr30 btnPrimay">< ?php echo lang('Readmore'); ?></button> </a>
                            </div>
                            < ?php $i++;}}?>
                        </div>
                    </div> */}
            </div>
          </div>

        </div>
        {/* Home Plan Slider End */}
        {/* Home Why Choose Start */}
        <div className="fullscreen whyChoose" id="single6">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12 text-center">
                <h2>WHY CHOOSE TRON GOOGOL?</h2>
                <p>Here is why TRON GOOGOL is right for you…</p>
                <div className="whyChoosePoints">
                  <div className="row align-items-center">
                    <div className="col-sm-12 col-md-6">
                      <div className="d-flex align-items-center">
                        <div className="whyChoosePointsIcons">
                          <img src="../assets/images/1607342143.png" alt="tick" />
                        </div>
                        <div className="whyChoosePointsText">
                          Decentralized Crowd Funding                                      </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="d-flex align-items-center">
                        <div className="whyChoosePointsIcons">
                          <img src="../assets/images/1607342143.png" alt="tick" />
                        </div>
                        <div className="whyChoosePointsText">
                          Fully Audited &amp; Verified                                      </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="d-flex align-items-center">
                        <div className="whyChoosePointsIcons">
                          <img src="../assets/images/1607342143.png" alt="tick" />
                        </div>
                        <div className="whyChoosePointsText">
                          Disruptive Closed Matrix                                      </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="d-flex align-items-center">
                        <div className="whyChoosePointsIcons">
                          <img src="../assets/images/1607342143.png" alt="tick" />
                        </div>
                        <div className="whyChoosePointsText">Not a MLM</div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="d-flex align-items-center">
                        <div className="whyChoosePointsIcons">
                          <img src="../assets/images/1607342143.png" alt="tick" />
                        </div>
                        <div className="whyChoosePointsText">Earn BNB For Life</div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <div className="d-flex align-items-center">
                        <div className="whyChoosePointsIcons">
                          <img src="../assets/images/1607342143.png" alt="tick" />
                        </div>
                        <div className="whyChoosePointsText">Where Everyone Makes Same</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Home Why Choose End */}
      </div>
      {/* homeSignShop content start */}
      <div className="homeSignShopSwap">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-12 col-lg-6 mb-4">
              <div className="homeSignShop">
                <div className="homeSign">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="homeSignImage">
                        <img src="../assets/images/1654442676_advlogo.png" alt="homeSignup" />
                        1654442676_advlogo.png                                  </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="homeSignContent">
                        <h3>SIGN UP WITH CB4U &amp; GET A PREMIUM ACCESS TO CLASSIFIEDS PORTAL</h3>
                        <p>Participate with US $ 25 on Tron Googol Crowdfunding Platform. Once done, please visit www.cryptocurrencybazaar4u.io & sign up to receive free premium access to classifieds portal www.cryptocurrencybazaar.4u.io.</p>
                        <div className="homeSignLink">
                          <a href="https://www.cryptocurrencybazaar4u.io/" className="btn" target="_blank"> SIGN UP</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="homeSign homeShop">
                  <div className="row">
                    <div className="col-12">
                      <div className="homeShopImage">
                        <img src="../assets/images/1654493319_advContentlogo.png" alt="crypto-mytrip" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="homeSignContent">
                        <h3>SHOP AT PLANET ONE TRAVEL AT SPECIAL DISCOUNTED PRICE</h3>
                        <p>Book your next flight from over 500+ airlines, rooms from over 80000+ hotels, Car rentals, Train passes, Holiday packages & more, and all these comes to you for special discounted price for being a community participant of Tron Googol BSC.</p>
                        <div className="homeSignLink">
                          <a href="https://planetone.travel/" className="btn" target="_blank"> SHOP NOW</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="homeSection7-contain">
                <div className="section7-item mb-3">
                  <div className="section7-img">
                    <img src="../assets/images/1654494576_Sec7_logo1.png" alt="tron googol" />
                  </div>
                  <div className="section7-content">
                    <div className="content-header">Cryptocurrency Bazaar 4U &gt; Crypto News</div>
                    <div className="content-content">
                      Stay updated with news from the global cryptocurrency world updated to the last minute round the clock. Subscribe to daily Crypto news updates for absolutely no cost.                              </div>
                    <div className="content-more-btn">
                      <a href="https://cryptocurrencybazaar4u.io/" target="_blank">Readmore</a>
                    </div>
                  </div>
                </div>
                <div className="section7-item mb-3">
                  <div className="section7-img">
                    <img src="../assets/images/1654676199_Sec7_logo1.png" alt="tron googol" />
                  </div>
                  <div className="section7-content">
                    <div className="content-header">CB4U - WHERE CRYPTO ADVERTISERS MEETS</div>
                    <div className="content-content">
                      G7 countries are all moving to CRYPTO. Position early, get your services heard. First classified is always free on us. Try our classifieds today. Join Tron Googol &amp; get full free access to all features of classified listings on CB4U.                              </div>
                    <div className="content-more-btn">
                      <a href="https://cryptocurrencybazaar4u.io/classified/" target="_blank">Readmore</a>
                    </div>
                  </div>
                </div>
                <div className="section7-item">
                  <div className="section7-img">
                    <img src="../assets/images/1656574606_Sec7_logo1.png" alt="tron googol" />
                  </div>
                  <div className="section7-content">
                    <div className="content-header">Planetone Exchange TRC 20 Tokens Sopported</div>
                    <div className="content-content">
                      New launched worldclass decentralized exchange in Tron network. Create your own wallet &amp; introduce others to the exchange to trade. Once they trade, you can earn upto 4 levels for every trade made by the person you have introduced &amp; 3 levels below them. Buy POI Tokens, ICO is live now.                              </div>
                    <div className="content-more-btn">
                      <a href="https://www.planetone.exchange/" target="_blank">Readmore</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* todo */}
          {/* <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="homeSwap">
                        <iframe src="https://widget.changelly.com?from=usd&to=*&amount=1&address=&fromDefault=usd&toDefault=trx&theme=azure&merchant_id=68x3s2ingvgym8oi&payment_id=&v=2" width="100%" height="440" class="changelly" scrolling="no" onLoad="function pe(e){var t=e.target,n=t[removed],r=t.contentWindow,o=function(){return r.postMessage({width:n.offsetWidth},'https://widget.changelly.com')};window.addEventListener('resize',o),o()};pe.apply(this, arguments);" style="min-width: 100%; width: 100px; overflow-y: hidden; border: none">Can't load widget</iframe>                    </div>
                </div> */}
        </div>
      </div>
      {/* homeSignShop content End */}
      {/* Plan Modal Start */}
      {/* The Modal */}
      <div className="modal fade planModal" id="planB-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <input type="hidden" name="referrer_value" id="referrer_value" defaultValue={1} />
              <h2></h2>
              <h4> Choose plan </h4>
              <form id="planb_invest" method="post" acceptCharset="utf-8">
                <select name="plans" id="planb" defaultValue={100} className="custom-select">
                  <option value="0" data-min={100}> Silver</option>
                  <option value="1" data-min={1000}>GOLD</option>
                  <option value="2" data-min={5000}>DIAMOND</option>
                  <option value="3" data-min={20000}>PLATINUM</option>
                </select>
                <div className="input-group">
                  <input type="text" className="form-control" name="plan_amt" id="plan_amt" placeholder="Enter amount" />
                  <input type="hidden" className="form-control" name="set_min" id="set_min" defaultValue={100} />
                  <div className="input-group-append">
                    <span className="input-group-text">.TRX</span>
                  </div>
                </div>
                <label htmlFor="plan_amt" className="error" />
                <p className="popupHints min_deposit"> Minimum amount required:                        100</p>
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="customCheck" name="customCheck" defaultValue={1} />
                  <label className="custom-control-label" htmlFor="customCheck">
                    By clicking this check box I confirm to invest the amount in TRX entered above                            .</label>
                </div>
                <label htmlFor="customCheck" className="error" />
                <div className="popupSubmit">
                  <button type="submit" id="investplanb" className="btn noBoxShadow"> invest                      </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Plan Modal End */}
      <div className="modal fade planModal" id="info-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <h2 className="text-center"> Information </h2>
              <h4 className="clr_org">
                We are in main network. Kindly change your Tron node at TRON Mainnet (TronGrid). If you have already in Mainnet, Kindly refresh the page (CTRL + F5) .
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal history-modal" id="planb-history-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h6> PLAN B - <span id="planName"> </span> <span>
                DEPOSIT HISTORY</span> </h6>
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <div className="table-responsive modalTableContainer">
                <table className="table table-responsive">
                  <thead>
                    <tr>
                      <th> Sl.No</th>
                      <th className="width_5"> Amount</th>
                      <th> Date Time</th>
                      <th> Days</th>
                    </tr>
                  </thead>
                  <tbody id="view_history">
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade join_popup" id="modal1" tabIndex={-1} role="dialog" aria-labelledby="modal1Label" aria-hidden="true">
        {/* Modal */}
        {/* <div class="modal fade align-items-center join_popup" id="basicExampleModal modal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel" />
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body" id="show_modl_content">
            </div>
            {/*  <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
          </div>
        </div>
      </div>
      <div className="modal fade planModal" id="withdraw-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <h6> WITHDRAW </h6>
              <h4> Withdraw Amount </h4>
              <form action="https://www.bnbcrowdfunding.com/" id="withdraw_form" method="post" acceptCharset="utf-8">
                <input type="text" name="with_amt" id="with_amt" className="form-control" />
                <label htmlFor="with_amt" className="error" />
                {/* <p class="popupHints min_deposit">   </p> */}
                <div className="popupSubmit">
                  <button type="submit" id="investplanb" className="btn noBoxShadow"> WITHDRAW                      </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal history-modal" id="planb-withhistory-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h6> WITHDRAW - <span id="planName"> </span> <span>
                HISTORY</span> </h6>
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <div className="table-responsive modalTableContainer">
                <table className="table table-responsive" id="with_view_history" data-page-length={5}>
                  <thead>
                    <tr>
                      <th> Sl.No</th>
                      <th> TXn Hash</th>
                      <th className="width_5"> Amount</th>
                      <th> Date Time</th>
                    </tr>
                  </thead>
                  <tbody id="withdraw_view_history">
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal" id="joinModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <h6>You can join the board using the referral link only. Kindly register using an existing board person's referral link or get the referral link from admin and register on Plan A.</h6>
              <h6> Get Referral Link Via : </h6>
              <ul className="list-unstyled d-flex justify-content-center social-icons nav nav-tabs border-bottom-0" id="myTab" role="tablist">
                <li>
                  <a href="#" data-toggle="tab" data-target="#google" role="tab" aria-controls="google" aria-selected="false">
                    <i className="fab fa-google" />
                  </a>
                </li>
                <li>
                  <a href="#" data-toggle="tab" data-target="#whatsapp" role="tab" aria-controls="whatsapp" aria-selected="false">
                    <i className="fab fa-whatsapp" />
                  </a>
                </li>
                <li>
                  <a href="#" data-toggle="tab" data-target="#telegram" role="tab" aria-controls="telegram" aria-selected="false">
                    <i className="fab fa-telegram-plane" />
                  </a>
                </li>
              </ul>
              <div className="tab-content mt-4" id="myTabContent">
                <div className="tab-pane" id="google" role="tabpanel" aria-labelledby="google-tab">
                  <form className="common_form" id="form_gmail" method="post">
                    <div className="form-group">
                      <input type="email" className="form-control" name="email_field" placeholder="Enter Gmail Id" id="email_field" />
                    </div>
                    <div className="form-group text-center">
                      <input type="submit" className="btnBr30 btnPrimay-small py-2" defaultValue="Submit" />
                    </div>
                  </form>
                </div>
                <div className="tab-pane" id="whatsapp" role="tabpanel" aria-labelledby="whatsapp-tab">
                  <form className="common_form" id="form_whatsapp" method="post">
                    <div className="form-group">
                      <input type="email" className="form-control" name="email_field" placeholder="Enter Gmail Id" id="wemail_field" />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Enter Your Whatsapp Number" name="number_field" id="Wnumber_field" />
                    </div>
                    <div className="form-group text-center">
                      <input type="submit" className="btnBr30 btnPrimay-small py-2" defaultValue="Submit" />
                    </div>
                  </form>
                </div>
                <div className="tab-pane" id="telegram" role="tabpanel" aria-labelledby="telegram-tab">
                  <form className="common_form" id="form_telegram" method="post">
                    <div className="form-group">
                      <input type="email" className="form-control" name="email_field" placeholder="Enter Gmail Id" id="temail_field" />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Enter Your Telegram Number" name="number_field" id="Tnumber_field" />
                    </div>
                    <div className="form-group text-center">
                      <input type="submit" className="btnBr30 btnPrimay-small py-2" defaultValue="Submit" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal video_pop" id="video-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <div className="col-md-12 text-center">
                Video i frame
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal video_pop" id="video-modal-new">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <div className="col-md-12 text-center">
                video iframe
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal new_pop" id="video-modal-news">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-md-12 text-center">
                <a href="https://bit.ly/3eSf2US" target="_blank">
                  <img src="../ajqgzgmedscuoc/front/images/pop_img.png" alt="tron googol" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal" id="Reg_Modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <h6>Please insert your Gmail email ID.
                <br />We need this information to keep you updated on Tron Googol
                project
              </h6>
              <div className="mt-4 reg-gmail-input-containner">
                <input type="gmail" className="reg-input" id="user-mail-info" />
              </div>
              <div className="reg-btn-containner mt-3">
                <button className="reg-btn" id="reg-btn">submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  )
}
export default HomeScreen