import { useState, useEffect, useContext } from "react";
import BlockchainContext from "../store/BlockchainContext";
import { useParams } from 'react-router-dom';
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import Footer from "./Footer"
import Header from "./Header"
import { Link } from "react-router-dom";
import { ApplicationConstants } from "../utils/ApplicationConstants";
import servicerequest from "../utils/config";

const NewsDetails = () => {
  const [news, setnews] = useState([]);
  const [related, setrelated] = useState([]);
  const { newsid } = useParams();
  const gettreedata = async (event) => {
    
      const res = await servicerequest.post("/getnewsDetails/","id="+newsid);
      console.log(res.data);
      setnews(res.data.news);
      setrelated(res.data.related);
  }; 
  useEffect(() => {
    gettreedata();
  }, [newsid]);
  return (
    <section className="homepage">
      <Header />
      <div class="row" style={{ textAlign: "center", display: "block ruby", color:"#FFF" }}>
        <div class="col-md-11">
          {((news.length > 0))?(
            <><h4 >{news[0].news_title}</h4><br/></>
            ):(<></>)}
        </div>
      </div>
      <div class="row" >
        <div class="col-md-1"></div>
        <div class="col-md-10">
          <div class="row" >
            <div class="col-md-8">
            {news&& news.length >0 ? (
              <div>
                <div style={{width:"100%"}}>
                  <img src={news[0].news_image}  style={{display: "block",marginLeft: "auto",marginRight: "auto"}}/>
                </div>
                <br />
                <div style={{ backgroundColor: "#FFF",color:"#000", padding: "20px 30px 10px 40px", marginTop: "-2px", position: "relative", top: "-4px", margin: "-30px 20px auto" }}>
                  <h4>{news[0].news_title} </h4>
                  <p dangerouslySetInnerHTML={{ __html: news[0].news_description }}></p>
                </div>
              </div>
              ):(<></>)}
            </div>
            <div class="col-md-4">
              {related ? related.map((info, index) => (
                <>
              {((index >= 0 ) && (index < 4))?(
                <>
		<Link to={"/newsinfo/"+related[index].news_id}>
              <div className="row" >
                <div class="col-md-6">
                  <img src={related[index].news_image} />
                </div>
                <div class="col-md-6">
                  <h6 data-id="quill-node-0">{related[index].news_title}</h6>
                </div>
              </div>
		</Link>
    <hr></hr>
                </>
                ):(<></>)}
                </>
              )) : null}
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
      </div>
      <br/><br/><br/>
      <Footer />
    </section>
  )
}
export default NewsDetails
