import { useRef,useState, useEffect, useContext } from "react";
import BlockchainContext from "../store/BlockchainContext";
import { useParams } from 'react-router-dom';
import Footer from "./Footer"
import Header from "./Header"

const Invest = () => {
  const [agreetnc,setagreetnc] = useState(false);
  const { treeid } = useParams();
  const { boardid } = useParams();
  const { referrid } = useParams();
  const { totalDeposit, handleInvest,userTransactions, useremail, setuseremail,showemailprompt,setshowemailprompt,setshowreferralprompt,showreferralprompt } = useContext(BlockchainContext);
  const handlecheckbox = (event) => {
    console.log("click");
    setagreetnc(!agreetnc);
  };
  const handleSubmitForm = async (event) => {
    event.preventDefault();
    handleInvest(treeid, boardid, referrid);
  };
  const closepopup=()=> {
    setshowreferralprompt(false);
    setshowemailprompt(false);
    setuseremail("");
  }
  const checkvalidity = (_treeid, _boardid)=>{
    for( let loopvar=0;loopvar <userTransactions.length;loopvar++)
    {
      if( (userTransactions[loopvar].treeid == _treeid) && (userTransactions[loopvar].board == _boardid) )
      return false;
    }
    return true;
  }
  return (
    <section className="homepage">

      <Header />
      {/* Header End */}
      {(showemailprompt==true)?(
      <div >
        <div className="overlay"></div>
        <div className="modalPopup">
          <span class="close" onClick={closepopup}></span>
          <br/>
          <h3>Get notified with all our updates to your email</h3>
          <br/>
          <div >
            <label for="email">Enter your email:</label>&nbsp;
            <input type="email" id="email" name="email" placeholder="email" value={useremail} onChange={(event) => {setuseremail(event.target.value);}}/> 
            <div className="hplanSliderLink pl-3" >
                  { (( checkvalidity(treeid,boardid) == true) ? (
                  <a onClick={handleSubmitForm} ><button className="btn noBoxShadow btnBr30 btnPrimay buttonsubmit">
                    JOIN</button></a>
                  ):(
                    <a ><button className="btn noBoxShadow btnBr30 btnPrimay buttonsubmit"> Already Member</button></a>
                  ))}
                </div>
          </div>
        </div>
      </div>
      ):(<></>)}
      {(showreferralprompt==true)?(
      <div >
        <div className="overlay"></div>
        <div className="modalPopup">
          <span class="close" onClick={closepopup}></span>
          <br/>
          <h3 className="cautiontext">You are joining without a referral link. We will like to notify you that this will still activate your account but your referrer will not get credit as you are proceeding without a referral link. Would you still like to proceed. If yes, click “JOIN” below. Click “CLOSE” to proceed again with the “Referral Link”.</h3>
          <br/>
          <div >
            <div className="hplanSliderLink pl-3" >
                  <a onClick={closepopup} ><button className="btn noBoxShadow btnBr30 btnPrimay buttoncancel">
                    Close</button></a> &nbsp;
                    <a onClick={handleSubmitForm} ><button className="btn noBoxShadow btnBr30 btnPrimay buttonsubmit">
                    JOIN</button></a>
                  </div>
          </div>
        </div>
      </div>
      ):(<></>)}
      <div id="cover-spin" style={{ display: 'none' }}>
        <div className="test">
          <center>
            <p className="show_text"> Transaction in Process... <br /> Please don’t click or refresh the page. <br />
              Please check your transaction on Tron link. </p>
          </center>
        </div>
      </div>    <div id="sideMenu">
      </div>
      <div className="fullscreen homePlansSlider pad_7" id="single5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <div id="carousel">
                <figure id="spinner">
                  <figure>
                    <div className="plan_sec">
                    <h4>Option {Number(boardid)+1}</h4>
                      <h3> Receive Crowdfund <br/>155 Times from Members <br/>For Your Matrix <br/>Over & Over Again</h3>
                      <ul>
                        <li>Contribute  US $ {boardid == 0 ? "25" : (boardid == 1 ? "50" : " 100")}</li>
                        <li>Others contribute to you US $ {boardid == 0 ? "3875" : (boardid == 1 ? "7750" : " 15500")} for every 3 cycles</li>
                        <li>Introduce only 5 people & recieve Residual earnings 100% in Autopilot for life.</li>
                        <li>In addition you make {boardid == 0 ? "5" : (boardid == 1 ? "4" : "3")}% referral Bonus on every joining with your referral link</li>
                      </ul>
                    </div>
                  </figure>
                </figure>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="plan_details homepSliderTBcontent ">
                <div className="fullscreen banner">
                  <div className="container containerTwo">
                    <div className="bannerContent">
                      <div className="row justify-content-center">
                        <div className="col-md-7">
                          <div className="plans_details_pg mt-3">
                            <h3 className="text-white">GET CROWDFUNDED CONTINUOUSLY WITH BNB ONCE PARTICIPANTS OBSERVES THE FOLLOWING.</h3>
                            <br/>
                            <h4 className="text-white">UNDERSTANDING THE 3 CYCLES</h4>
                            <hr />
                            <ul>
                              <li style={{textAlign:"left"}}>1. There are 3 cycles from which a participant in this platform will earn BNB by crowdfunding.</li>
                              <li style={{textAlign:"left"}}>2. For receiving crowdfund earnings from the first 2 cycles, you must assist at-least 2 people to participate on this platform using your personalized referral link. </li>
                              <li style={{textAlign:"left"}}>3. For receiving crowdfund earnings from the 3rd cycle, you must introduce another 3 people to participate on this platform using your personalized referral link.</li>
                              <li style={{textAlign:"left"}}>4. You will not receive earnings from the 3rd cycle if you do not bring the add-on 3 people by the time you reach the upper limit earnings by the end of the 2nd Cycle. </li>
                              <li style={{textAlign:"left"}}>5. It is suggested that all participates individually introduce 5 people in total. This will assure seamless upper limit earnings all the way to the end of the 3rd Cycles. </li>
                              <li style={{textAlign:"left"}}>6. Once you reach the upper limit at the end of the 3rd cycle, the platform will generate a re-entry option for you to re-enter the platform again. Please rejoin immediately once you reach at this point. </li>
                              <li style={{textAlign:"left"}}>7. After you have rejoined, all the people who has joined after you initially for your matrix will be placed under your matrix again once they have reached the upper limit earnings at the end of the 3rd cycle. </li>
                              <li style={{textAlign:"left"}}>8. This process will continue over and over again thereby enabling participants to earn BNB by crowdfunding continuously.</li>
                            </ul>
                            <h3 className="text-white">WHAT DO YOU NEED TO EARN BNB CONTINUOUSLY?</h3>
                            <p className="text-white">ALL THAT YOU NEED TO DO IS TO ASSIST AT-LEAST 5 PEOPLE TO PARTICIPATE ON THIS PLATFORM WITH YOUR PERSONALIZED REFERRAL LINK. THAT IS ALL AND YOU ARE ALL SET. </p>
                            <h3 className="text-white">HOW SMART CONTRACT EXECUTES CONTRIBUTIONS TO THE PARTICIPANTS?</h3>
                            <p className="text-white">THERE IS NO HOLD OF FUNDS ON THE SMART CONTRACT, WHICH MEANS THE PARTICIPANTS DO NOT HAVE TO SENT ANY SORT OF REQUEST TO ANYONE TO RECEIVE THEIR EARNED BNB. ALL BNB's REACHES RECIPIENTS WALLETS REAL-TIME AUTOMATICALLY TRIGGERED BY THE SMART CONTRACT FOR THE MATRIX IN WHICH EACH RECIPIENTS ARE PLACED ON THIS PLATFORM. THIS MEANS THAT THIS PLATFORM IS TRULY DECENTRALIZED WITH NO INTERVENTION OF ANY KINDS ASSURING FAIRNESS OF DEFI PROTOCOLS & PRINCIPALS. </p>
                            <h3 className="text-white">WHO CAN JOIN? </h3>
                            <p className="text-white">THE PLATFORM WELCOMES EVERYONE FROM ALL OVER THE WORLD TO ONE OF THE MOST INNOVATIVE YET SIMPLE TO UNDERSTAND LIFETIME EARNING OPPORTUNITY WITH AN ONETIME ENTRY CONTRIBUTION OF US $ {boardid == 0 ? "25" : (boardid == 1 ? "50" : " 100")}.    </p>
                            <h3 className="text-white">TRON GOOGOL CROWDFUNDING BY BNB LOOKS FORWARD TO YOUR PARTICIPATION.</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hplanSliderLink pl-3" style={{color: "#fff"}}>
                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" onChange={handlecheckbox} /> I agree for the following Terms and Conditions:<br/>
                  WE UNDERSTAND THAT THIS IS NOT AN INVESTMENT PLATFORM. BY PARTICIPATING ON THIS PLATFORM, WE ARE CONTRIBUTING FUNDS TO THE OTHER MEMBER REAL TIME BY THE ASSISTANCE OF THIS PLATFORM WHICH  CONNECTS TO THE BINANCE SMART CHAIN. SIMILARLY ANY FUNDS RECEIVED BY US FROM OTHER MEMBERS WILL ALSO BE RECEIVED BY US REAL TIME IN OUR PERSONAL WALLET WHEN OUR MATRIX WILL QUALIFY FOR SUCH RECEIPTS FROM OTHER MEMBERS PARTICIPATING ON THIS CONTRACT. THERE IS NO HOLD OF FUNDS ON THE SMART CONTRACT, WHICH MEANS THE PARTICIPANTS DO NOT HAVE TO SENT ANY SORT OF REQUEST TO ANYONE TO RECEIVE THEIR EARNED BNB. ALL BNB REACHES RECIPIENTS WALLETS REAL-TIME FOR THE MATRIX IN WHICH EACH RECIPIENTS ARE PLACED ON THIS PLATFORM. THIS MEANS THAT THIS PLATFORM IS TRULY DECENTRALIZED WITH NO INTERVENTION OF ANY KINDS ASSURING FAIRNESS OF DEFI PROTOCOLS & PRINCIPALS.
                <br/>
                {(agreetnc==true )?(
                   (( checkvalidity(treeid,boardid) == true) ? (
                  <a onClick={handleSubmitForm} ><button className="btn noBoxShadow btnBr30 btnPrimay">
                    JOIN</button></a>
                  ):(
                    <a ><button className="btn noBoxShadow btnBr30 btnPrimay"> Already Member</button></a>
                  ))
                ):(
                   (( checkvalidity(treeid,boardid) == true) ? (
                    <a ><button className="btn noBoxShadow btnBr30 " style={{backgroundColor: "#CAC4C417",border: "0px",minWidth: "203px",fontSize: "35px",transition: "ease-in-out 0.5s transform",color: "#C2CACA"}}> JOIN</button></a>
                    ):(
                      <a ><button className="btn noBoxShadow btnBr30" style={{backgroundColor: "#CAC4C417",border: "0px",minWidth: "203px",fontSize: "35px",transition: "ease-in-out 0.5s transform",color: "#C2CACA"}}> Already Member</button></a>
                    ))
                )}
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      {/* Plan Modal Start */}
      {/* The Modal */}
      <div className="modal fade planModal" id="planB-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <input type="hidden" name="referrer_value" id="referrer_value" defaultValue={1} />
              <h2></h2>
              <h4> Choose plan </h4>
              <form id="planb_invest" method="post" acceptCharset="utf-8">
                <select name="plans" id="planb" defaultValue={100} className="custom-select">
                  <option value="0" data-min={100}> Silver</option>
                  <option value="1" data-min={1000}>GOLD</option>
                  <option value="2" data-min={5000}>DIAMOND</option>
                  <option value="3" data-min={20000}>PLATINUM</option>
                </select>
                <div className="input-group">
                  <input type="text" className="form-control" name="plan_amt" id="plan_amt" placeholder="Enter amount" />
                  <input type="hidden" className="form-control" name="set_min" id="set_min" defaultValue={100} />
                  <div className="input-group-append">
                    <span className="input-group-text">.BNB</span>
                  </div>
                </div>
                <label htmlFor="plan_amt" className="error" />
                <p className="popupHints min_deposit"> Minimum amount required: 100</p>
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="customCheck" name="customCheck" defaultValue={1} />
                  <label className="custom-control-label" htmlFor="customCheck">
                    By clicking this check box I confirm to invest the amount in TRX entered above                            .</label>
                </div>
                <label htmlFor="customCheck" className="error" />
                <div className="popupSubmit">
                  <button type="submit" id="investplanb" className="btn noBoxShadow"> invest                      </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Plan Modal End */}
      <div className="modal fade planModal" id="info-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <h2 className="text-center"> Information </h2>
              <h4 className="clr_org">
                We are in main network. Kindly change your Tron node at TRON Mainnet (TronGrid). If you have already in Mainnet, Kindly refresh the page (CTRL + F5) .
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal history-modal" id="planb-history-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h6> PLAN B - <span id="planName"> </span> <span>
                DEPOSIT HISTORY</span> </h6>
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <div className="table-responsive modalTableContainer">
                <table className="table table-responsive">
                  <thead>
                    <tr>
                      <th> Sl.No</th>
                      <th className="width_5"> Amount</th>
                      <th> Date Time</th>
                      <th> Days</th>
                    </tr>
                  </thead>
                  <tbody id="view_history">
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade join_popup" id="modal1" tabIndex={-1} role="dialog" aria-labelledby="modal1Label" aria-hidden="true">
        {/* Modal */}
        {/* <div class="modal fade align-items-center join_popup" id="basicExampleModal modal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel" />
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body" id="show_modl_content">
            </div>
            {/*  <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
          </div>
        </div>
      </div>
      <div className="modal fade planModal" id="withdraw-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <h6> WITHDRAW </h6>
              <h4> Withdraw Amount </h4>
              <form action="https://www.bnbcrowdfunding.com/" id="withdraw_form" method="post" acceptCharset="utf-8">
                <input type="text" name="with_amt" id="with_amt" className="form-control" />
                <label htmlFor="with_amt" className="error" />
                {/* <p class="popupHints min_deposit">   </p> */}
                <div className="popupSubmit">
                  <button type="submit" id="investplanb" className="btn noBoxShadow"> WITHDRAW                      </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal history-modal" id="planb-withhistory-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h6> WITHDRAW - <span id="planName"> </span> <span>
                HISTORY</span> </h6>
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <div className="table-responsive modalTableContainer">
                <table className="table table-responsive" id="with_view_history" data-page-length={5}>
                  <thead>
                    <tr>
                      <th> Sl.No</th>
                      <th> TXn Hash</th>
                      <th className="width_5"> Amount</th>
                      <th> Date Time</th>
                    </tr>
                  </thead>
                  <tbody id="withdraw_view_history">
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal" id="joinModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <h6>You can join the board using the referral link only. Kindly register using an existing board person's referral link or get the referral link from admin and register on Plan A.</h6>
              <h6> Get Referral Link Via : </h6>
              <ul className="list-unstyled d-flex justify-content-center social-icons nav nav-tabs border-bottom-0" id="myTab" role="tablist">
                <li>
                  <a href="#" data-toggle="tab" data-target="#google" role="tab" aria-controls="google" aria-selected="false">
                    <i className="fab fa-google" />
                  </a>
                </li>
                <li>
                  <a href="#" data-toggle="tab" data-target="#whatsapp" role="tab" aria-controls="whatsapp" aria-selected="false">
                    <i className="fab fa-whatsapp" />
                  </a>
                </li>
                <li>
                  <a href="#" data-toggle="tab" data-target="#telegram" role="tab" aria-controls="telegram" aria-selected="false">
                    <i className="fab fa-telegram-plane" />
                  </a>
                </li>
              </ul>
              <div className="tab-content mt-4" id="myTabContent">
                <div className="tab-pane" id="google" role="tabpanel" aria-labelledby="google-tab">
                  <form className="common_form" id="form_gmail" method="post">
                    <div className="form-group">
                      <input type="email" className="form-control" name="email_field" placeholder="Enter Gmail Id" id="email_field" />
                    </div>
                    <div className="form-group text-center">
                      <input type="submit" className="btnBr30 btnPrimay-small py-2" defaultValue="Submit" />
                    </div>
                  </form>
                </div>
                <div className="tab-pane" id="whatsapp" role="tabpanel" aria-labelledby="whatsapp-tab">
                  <form className="common_form" id="form_whatsapp" method="post">
                    <div className="form-group">
                      <input type="email" className="form-control" name="email_field" placeholder="Enter Gmail Id" id="wemail_field" />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Enter Your Whatsapp Number" name="number_field" id="Wnumber_field" />
                    </div>
                    <div className="form-group text-center">
                      <input type="submit" className="btnBr30 btnPrimay-small py-2" defaultValue="Submit" />
                    </div>
                  </form>
                </div>
                <div className="tab-pane" id="telegram" role="tabpanel" aria-labelledby="telegram-tab">
                  <form className="common_form" id="form_telegram" method="post">
                    <div className="form-group">
                      <input type="email" className="form-control" name="email_field" placeholder="Enter Gmail Id" id="temail_field" />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Enter Your Telegram Number" name="number_field" id="Tnumber_field" />
                    </div>
                    <div className="form-group text-center">
                      <input type="submit" className="btnBr30 btnPrimay-small py-2" defaultValue="Submit" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal video_pop" id="video-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <div className="col-md-12 text-center">
                Video i frame
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal video_pop" id="video-modal-new">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <div className="col-md-12 text-center">
                video iframe
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal new_pop" id="video-modal-news">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-md-12 text-center">
                <a href="https://bit.ly/3eSf2US" target="_blank">
                  <img src="../ajqgzgmedscuoc/front/images/pop_img.png"  alt="tron googol"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal" id="Reg_Modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <h6>Please insert your Gmail email ID.
                <br />We need this information to keep you updated on Tron Googol
                project
              </h6>
              <div className="mt-4 reg-gmail-input-containner">
                <input type="gmail" className="reg-input" id="user-mail-info" />
              </div>
              <div className="reg-btn-containner mt-3">
                <button className="reg-btn" id="reg-btn">submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* footer content start */}

      {/*Start of Tawk.to Script*/}
      {/*

*/}
      {/*End of Tawk.to Script*/}
    </section>
  )
}
export default Invest