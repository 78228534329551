import axios from "axios";

export default axios.create({
    baseURL: "https://api.bnbcrowdfunding.com/",
    params: {
        // part: 'snippet',
        // type: 'video',
        // maxResults: 12,
    },
    body:{
        maxResults: 12,
    },
    crossDomain: true,
    headers: {
        'Access-Control-Allow-Origin': '*',
        //"Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
        //"Content-Type": "application/json",
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        "Accept-Encoding": "gzip, deflate, br",
        "Accept-Language": "en-US,en;q=0.9,zh-CN;q=0.8,zh;q=0.7,ar;q=0.6,zh-TW;q=0.5,ru;q=0.4",
        "Access-Control-Request-Headers": "content-type",
        "Access-Control-Request-Method": "POST",
        "Connection": "keep-alive",
        "Host": "178.62.121.96:6400",
        //"sec-ch-ua": "Chromium";v="104", " Not A;Brand";v="99", "Google Chrome";v="104"
        "sec-ch-ua-mobile": "?0",
        "sec-ch-ua-platform": "Windows",
        "Sec-Fetch-Dest": "empty",
        "Sec-Fetch-Mode": "cors",
        "Sec-Fetch-Site": "cross-site",
        "User-Agent": "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
      }
});