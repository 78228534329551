import { ABI } from "./abi";

export const networks = {



  bsc: {
    contract: "0x5b95940408b47bf0ac1e470574edb0ed0b5f82d4",
    chainId: "56",
    rpcconfig: {56:"https://bsc-dataseed.binance.org"},
    projectId:"40",
    color: "F3BA2F",
    trustwalletnetworkname:"binance",
    bonusamount:0,
    displaydecimals:3,
    mindeposit:0.5,
    logoname: "bnb.png",
    fullName: "Binance Smart Chain",
    ABI: ABI,
    title: "bsc",
    rpcURl: "https://bsc-dataseed.binance.org",
    explorerlink: ["https://bscscan.com/address/0x5b95940408b47bf0ac1e470574edb0ed0b5f82d4"],
    blockchain: "Binance Smart Chain",
    type: "0",
    tokenId: "binancecoin",
    mintransactionamount:0.023,
    maxtransactionamount:5,
    unit: "BNB",
    explorer:"https://bscscan.com/tx/",
    networkData: [
      {
        chainId: "0x38",
        chainName: "BSC MainNet",
        rpcUrls: ["https://bsc-dataseed.binance.org"],
        nativeCurrency: {
          name: "BINANCE COIN",
          symbol: "BNB",
          decimals: 18,
        },
        blockExplorerUrls: ["https://bscscan.com/"],
      },
    ],
  },


  // bsc: {
  //   contract: "0x5b95940408b47bf0ac1e470574edb0ed0b5f82d4",
  //   chainId: "56",
  //   rpcconfig: {56:"https://bsc-dataseed.binance.org"},
  //   projectId:"40",
  //   color: "F3BA2F",
  //   rustwalletnetworkname:"binance",
  //   bonusamount:0,
  //   displaydecimals:3,
  //   mindeposit:0.5,
  //   logoname: "bnb.png",
  //   fullName: "Binance Smart Chain",
  //   ABI: ABI,
  //   title: "bsc",
  //   rpcURl: "https://bsc-dataseed.binance.org",
  //   explorerlink: ["https://bscscan.com/address/0x5b95940408b47bf0ac1e470574edb0ed0b5f82d4"], //0x5ca3589cE8D50f6Ac793810534eFF118A2cD983C
  //   blockchain: "Binance Smart Chain",
  //   type: "0",
  //   tokenId: "binancecoin",
  //   mintransactionamount:0.023,
  //   maxtransactionamount:5,
  //   unit: "BNB",
  //   explorer:"https://bscscan.com/tx/",
  //   networkData: [
  //     {
  //       chainId: "0x38",
  //       chainName: "BSC MainNet",
  //       rpcUrls: ["https://bsc-dataseed.binance.org"],
  //       nativeCurrency: {
  //         name: "BINANCE COIN",
  //         symbol: "BNB",
  //         decimals: 18,
  //       },
  //       blockExplorerUrls: ["https://bscscan.com/"],
  //     },
  //   ],
  // }
};
