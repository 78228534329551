import Footer from "./Footer"
import Header from "./Header"

const Eight = () => {
  return (
    <section>
      <div className="plansPage" style={{ paddingTop: "98px" }}>
        <Header />
        <div className="container-inner">
          <div className="row">
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      21  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      43 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      42 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      22  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      45 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      44 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      23  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      47 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      46 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      24  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      49 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      48 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      25  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      52 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      51 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      26  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      54 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      53 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      27  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      56 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      55 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      28  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                      58 </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      57 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      29  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                      60 </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                      59 </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      30  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                      62 </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                      61 </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      31  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                      64 </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                      63 </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      16  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      33 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      32 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      17  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      35 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      34 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      18  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">37 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      36 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      19  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      39 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      38 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      20  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      41 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      40 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">1 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">2      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">3      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">4      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">8      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">9      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">5      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">11      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">10      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">7      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">15      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">14      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">6      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">12      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">13      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/dUJxbEM3b2FsbkxwNnE5eUJxQmNXdz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      166  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      383 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      378 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      168  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      388 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      384 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      170  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      394 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      392 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      171  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      400 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      399 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      174  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      410 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      407 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      177  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      420 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      416 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      178  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      424 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      421 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      180  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                      440 </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      433 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      182  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                      442 </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                      441 </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      184  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                      445 </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                      443 </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      186  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                      452 </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                      449 </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      125  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      354 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      353 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      157  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      359 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      356 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      158  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">367 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      364 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      161  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      369 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      368 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      162  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      376 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      373 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">8 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">16      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">17      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">32      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">65      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">66      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">33      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">68      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">67      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">35      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">76      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">75      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">34      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">69      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">74      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NS94Uyt2dTJpNnhMdmYydnFvM2NaUT09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      593  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      1551 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      1546 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      598  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      1710 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      1707 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      602  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      2682 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      1714 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      607  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      3000 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      2812 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      608  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      3714 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      3005 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      611  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      3874 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      3836 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      615  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      4022 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      3965 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      619  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                      4077 </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      4073 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      625  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                      4195 </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                      4085 </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      626  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                      4201 </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                      4199 </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      628  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                      4221 </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                      4215 </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      550  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      838 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      724 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      567  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      897 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      870 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      576  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">1191 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      1067 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      580  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      1529 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      1523 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      583  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      1541 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      1535 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">65 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">125      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">157      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">353      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">385      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">389      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">354      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">423      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">391      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">359      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">539      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">524      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">356      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">519      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">520      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/S2F5dnJjQVEwakRXOWlUZUlGZDV5dz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      1283  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      4542 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      1287  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      1288  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      1291  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      1293  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      1314  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      1343  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      1447  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      1455  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      1457  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      1554  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      1270  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      1850 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      1769 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      1273  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      3312 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      2621 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      1277  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">3647 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      3313 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      1278  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      3760 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      3652 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      1282  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      4530 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      4466 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">66 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">158      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">161      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">364      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">645      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">660      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">367      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">850      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">729      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">369      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">1164      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">1148      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">368      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">1089      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">1130      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/U1kwcVZtZEtyL3FWTFVuN1ljQkthdz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      2125  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      4114 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      4043 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      2134  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      4290 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      4129 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      2136  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      4323 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      4298 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      2154  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      4347 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      4336 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      2158  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      4381 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      4365 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      2163  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      4393 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      4391 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      2173  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      4468 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      4430 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      2180  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      5136 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      2187  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      2238  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      2256  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      1625  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      2833 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      2679 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      1629  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      2895 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      2879 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      1637  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">2992 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      2937 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      1978  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      3694 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      2996 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      2019  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      3945 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      3747 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">67 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">162      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">166      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">373      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">1169      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">1172      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">376      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">1183      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">1181      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">383      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">1209      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">1205      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">378      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">1193      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">1201      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/dlRvdC8zb2JuTjMzWlRHeWhnVUhqUT09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      2039  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      2045  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      2065  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      2072  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      2239  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      2265  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      2615  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      2737  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      2752  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      2802  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      2949  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      1290  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      3566 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      3509 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      1519  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      1526  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px"> </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      1984  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      2027  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">68 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">168      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">170      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">384      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">1214      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">1219      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">388      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">1222      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">1221      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">394      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">1233      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">1229      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">392      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">1223      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">1226      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/cTB3ZVpxWUE1YUhndmpBck82RGNKZz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      2970  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      3086  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      3220  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      3223  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      3400  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      3771  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      3812  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      3904  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      3906  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      3929  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      3948  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      1422  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      5215 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      4478 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      1469  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      5719 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      5702 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      1486  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px"> </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      1503  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      2313  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">69 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">171      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">174      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">399      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">456      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">641      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">400      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">1105      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">679      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">410      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">1241      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">1238      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">407      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">1235      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">1236      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/Q05SazFDekpmVS9pTDVkMlV6Zk9mZz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      3316  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      3317  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      3339  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      3593  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      3595  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      3599  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      3601  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      3842  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      4262  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      4300  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      4379  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      1439  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      5038 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      4683 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      1549  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      5043 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      5041 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      1670  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">5530 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      5119 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      1744  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      2591  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                    </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">74 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">177      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">178      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">416      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">455      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">469      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">420      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">1243      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">943      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">424      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">1259      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">1253      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">421      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">1245      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">1249      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/Tjl6YXF6UE5ONHFlZW02OThwbTB5dz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      650  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      818 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      808 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      653  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      847 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      840 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      671  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      866 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      856 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      673  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      907 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      899 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      674  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      915 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      910 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      676  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      920 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      917 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      682  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      939 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      934 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      687  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                      987 </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      985 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      696  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                      1000 </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                      990 </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      702  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                      1054 </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                      1005 </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      710  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                      1071 </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                      1059 </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      540  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      718 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      712 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      556  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      747 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      741 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      557  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">758 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      757 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      578  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      785 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      771 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      621  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      804 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      797 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">75 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">180      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">182      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">433      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">463      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">467      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">440      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">478      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">477      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">442      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">529      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">522      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">441      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">481      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">493      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="planDiagrams">
                <div className="planDiagram child">
                  <a href="https://www.trongoogol.io/en/plandetail/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/NDY3RTN6V0c0TnNtYlBpWGFKdUgrdz09/U3ErUDFHV24zblpqOXBiS3B2RGtqdz09"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1298 1296.2" style={{ enableBackground: 'new 0 0 1298 1296.2' }} xmlSpace="preserve">
                    <style type="text/css" dangerouslySetInnerHTML={{ __html: "\n    .st0 {\n        fill: #545454;\n    }\n\n    .st1 {\n        display: none;\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #606060;\n    }\n\n    .st2 {\n        fill: none;\n    }\n\n    .st3 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #A8E6CE;\n    }\n\n    .st4 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #9F7FBA;\n    }\n\n    .st5 {\n        fill-rule: evenodd;\n        clip-rule: evenodd;\n        fill: #FF8C94;\n    }\n\n    .st6 {\n        fill: #A8E6CE;\n    }\n    " }} />
                    <g>
                      <path className="st0" d="M692.2,361.6" />
                      <path className="st0" d="M748.8,285.6" />
                    </g>
                    <ellipse className="st1" cx={649} cy="652.1" rx="538.8" ry="544.5" />
                    <line className="st2" x1={944} y1="307.6" x2="1020.5" y2="281.6" />
                    <line className="st2" x1={928} y1="289.6" x2={957} y2="235.6" />
                    <line className="st2" x1="902.5" y1="332.1" x2={829} y2="397.6" />
                    <circle className="st3" cx={913} cy="319.6" r={39} />
                    <text x={913} y="319.6" textAnchor="middle" fill="black" fontSize="30px">
                      1996  </text>
                    <circle className="st4" cx="1034.5" cy="278.7" r="31.5" />
                    <text x="1034.5" y="278.7" textAnchor="middle" fill="white" fontSize="30px">
                      3343 </text>
                    <circle className="st4" cx="968.5" cy="213.1" r="31.5" />
                    <text x="968.5" y="213.1" textAnchor="middle" fill="white" fontSize="30px">
                      3297 </text>
                    <g>
                      <line className="st2" x1="1053.5" y1="454.2" x2={1117} y2="460.6" />
                      <line className="st2" x1={1045} y1="442.6" x2={1090} y2="379.6" />
                      <line className="st2" x1={1009} y1="469.6" x2={919} y2="496.6" />
                    </g>
                    <circle className="st3" cx="1022.1" cy="463.6" r={39} />
                    <text x="1022.1" y="463.6" textAnchor="middle" fill="black" fontSize="30px">
                      2110  </text>
                    <circle className="st4" cx="1148.5" cy="456.1" r="31.5" />
                    <text x="1148.5" y="456.1" textAnchor="middle" fill="white" fontSize="30px">
                      3504 </text>
                    <circle className="st4" cx="1112.5" cy="366.1" r="31.5" />
                    <text x="1112.5" y="366.1" textAnchor="middle" fill="white" fontSize="30px">
                      3495 </text>
                    <line className="st2" x1={1090} y1="613.6" x2="1147.3" y2="649.4" />
                    <line className="st2" x1={1090} y1="595.6" x2="1149.3" y2={565} />
                    <line className="st2" x1="1051.8" y1={604} x2={955} y2="621.7" />
                    <circle className="st3" cx={1060} cy="598.6" r={39} />
                    <text x={1060} y="598.6" textAnchor="middle" fill="black" fontSize="30px">
                      2278  </text>
                    <circle className="st4" cx="1175.5" cy="654.1" r="31.5" />
                    <text x="1175.5" y="654.1" textAnchor="middle" fill="white" fontSize="30px">
                      3788 </text>
                    <circle className="st4" cx="1173.3" cy="555.1" r="31.5" />
                    <text x="1173.3" y="555.1" textAnchor="middle" fill="white" fontSize="30px">
                      3724 </text>
                    <g>
                      <line className="st2" x1="1070.3" y1={783} x2="1110.7" y2="844.1" />
                      <line className="st2" x1="1084.2" y1="766.9" x2="1143.7" y2="765.3" />
                      <line className="st2" x1={1045} y1="757.6" x2="956.2" y2="713.7" />
                    </g>
                    <circle className="st3" cx={1048} cy="760.6" r={39} />
                    <text x={1048} y="760.6" textAnchor="middle" fill="black" fontSize="30px">
                      2750  </text>
                    <circle className="st4" cx="1133.2" cy="857.6" r="31.5" />
                    <text x="1133.2" y="857.6" textAnchor="middle" fill="white" fontSize="30px">
                      3798 </text>
                    <circle className="st4" cx="1169.1" cy="767.6" r="31.5" />
                    <text x="1169.1" y="767.6" textAnchor="middle" fill="white" fontSize="30px">
                      3792 </text>
                    <line className="st2" x1={991} y1="946.6" x2="1009.6" y2="1008.3" />
                    <line className="st2" x1={1000} y1="928.6" x2="1063.3" y2="943.2" />
                    <line className="st2" x1="964.4" y1="912.8" x2={884} y2={856} />
                    <circle className="st3" cx={979} cy="916.6" r={39} />
                    <text x={979} y="916.6" textAnchor="middle" fill="black" fontSize="30px">
                      2753  </text>
                    <circle className="st4" cx={1024} cy="1035.1" r="31.5" />
                    <text x={1024} y="1035.1" textAnchor="middle" fill="white" fontSize="30px">
                      4071 </text>
                    <circle className="st4" cx="1085.5" cy="951.1" r="31.5" />
                    <text x="1085.5" y="951.1" textAnchor="middle" fill="white" fontSize="30px">
                      3801 </text>
                    <g>
                      <line className="st2" x1="855.1" y1="1063.5" x2="850.6" y2="1121.8" />
                      <line className="st2" x1="874.8" y1="1054.6" x2="928.4" y2="1084.4" />
                      <line className="st2" x1="840.2" y1="1012.5" x2="782.5" y2="912.6" />
                    </g>
                    <circle className="st3" cx={853} cy="1030.6" r={39} />
                    <text x={853} y="1030.6" textAnchor="middle" fill="black" fontSize="30px">
                      2757  </text>
                    <circle className="st4" cx="851.1" cy="1152.3" r="31.5" />
                    <text x="851.1" y="1152.3" textAnchor="middle" fill="white" fontSize="30px">
                      4117 </text>
                    <circle className="st4" cx="941.5" cy="1093.8" r="31.5" />
                    <text x="941.5" y="1093.8" textAnchor="middle" fill="white" fontSize="30px">
                      4080 </text>
                    <line className="st2" x1={694} y1="1099.6" x2="662.6" y2="1156.6" />
                    <line className="st2" x1={730} y1="1099.6" x2={757} y2="1153.6" />
                    <line className="st2" x1={703} y1="1047.9" x2={694} y2="946.6" />
                    <circle className="st3" cx={709} cy="1069.6" r={39} />
                    <text x={709} y="1069.6" textAnchor="middle" fill="black" fontSize="30px">
                      2866  </text>
                    <circle className="st4" cx="653.5" cy="1185.1" r="31.5" />
                    <text x="653.5" y="1185.1" textAnchor="middle" fill="white" fontSize="30px">
                      4162 </text>
                    <circle className="st4" cx="761.5" cy="1176.1" r="31.5" />
                    <text x="761.5" y="1176.1" textAnchor="middle" fill="white" fontSize="30px">
                      4155 </text>
                    <g>
                      <line className="st2" x1={496} y1="1072.6" x2="448.8" y2="1110.7" />
                      <line className="st2" x1="526.4" y1="1085.3" x2="539.9" y2="1143.4" />
                      <line className="st2" x1="527.6" y1="1048.4" x2={577} y2="937.6" />
                    </g>
                    <circle className="st3" cx={520} cy="1048.6" r={39} />
                    <text x={520} y="1048.6" textAnchor="middle" fill="black" fontSize="30px">
                      2898  </text>
                    <circle className="st4" cx="437.5" cy="1133.8" r="31.5" />
                    <text x="437.5" y="1133.8" textAnchor="middle" fill="white" fontSize="30px">
                      4170 </text>
                    <circle className="st4" cx="539.9" cy="1167.1" r="31.5" />
                    <text x="539.9" y="1167.1" textAnchor="middle" fill="white" fontSize="30px">
                      4167 </text>
                    <line className="st2" x1="346.6" y1="996.7" x2="295.9" y2="1004.6" />
                    <line className="st2" x1={359} y1="1013.9" x2={351} y2="1077.6" />
                    <line className="st2" x1="384.4" y1="973.5" x2="476.4" y2={891} />
                    <circle className="st3" cx={385} cy="985.6" r={39} />
                    <text x={385} y="985.6" textAnchor="middle" fill="black" fontSize="30px">
                      2900  </text>
                    <circle className="st4" cx="266.5" cy="1012.3" r="31.5" />
                    <text x="266.5" y="1012.3" textAnchor="middle" fill="white" fontSize="30px">
                      4202 </text>
                    <circle className="st4" cx="344.9" cy="1086.1" r="31.5" />
                    <text x="344.9" y="1086.1" textAnchor="middle" fill="white" fontSize="30px">
                      4171 </text>
                    <g>
                      <line className="st2" x1="240.5" y1="849.6" x2="173.8" y2="831.3" />
                      <line className="st2" x1="245.2" y1="870.3" x2="211.2" y2="919.2" />
                      <line className="st2" x1="284.2" y1="842.8" x2={397} y2="802.6" />
                    </g>
                    <circle className="st3" cx="271.7" cy={845} r={39} />
                    <text x="271.7" y={845} textAnchor="middle" fill="black" fontSize="30px">
                      3055  </text>
                    <circle className="st4" cx="149.5" cy="834.8" r="31.5" />
                    <text x="149.5" y="834.8" textAnchor="middle" fill="white" fontSize="30px">
                      4268 </text>
                    <circle className="st4" cx="193.4" cy="933.1" r="31.5" />
                    <text x="193.4" y="933.1" textAnchor="middle" fill="white" fontSize="30px">
                      4263 </text>
                    <line className="st2" x1="199.8" y1="667.7" x2={145} y2="649.6" />
                    <line className="st2" x1="201.5" y1="688.9" x2={145} y2="739.6" />
                    <line className="st2" x1="244.1" y1="667.4" x2="341.1" y2="650.4" />
                    <circle className="st3" cx={220} cy="670.6" r={39} />
                    <text x={220} y="670.6" textAnchor="middle" fill="black" fontSize="30px">
                      3061  </text>
                    <circle className="st4" cx="113.5" cy="645.1" r="31.5" />
                    <text x="113.5" y="645.1" textAnchor="middle" fill="white" fontSize="30px">
                      4283 </text>
                    <circle className="st4" cx="122.5" cy="744.1" r="31.5" />
                    <text x="122.5" y="744.1" textAnchor="middle" fill="white" fontSize="30px">
                      4273 </text>
                    <g>
                      <line className="st2" x1="234.3" y1="519.6" x2={172} y2="451.6" />
                      <line className="st2" x1="221.6" y1="536.6" x2={154} y2="541.6" />
                      <line className="st2" x1="272.7" y1="530.7" x2="355.9" y2={576} />
                    </g>
                    <circle className="st3" cx={238} cy="520.6" r={39} />
                    <text x={238} y="520.6" textAnchor="middle" fill="black" fontSize="30px">
                      1528  </text>
                    <circle className="st4" cx="149.5" cy="438.1" r="31.5" />
                    <text x="149.5" y="438.1" textAnchor="middle" fill="white" fontSize="30px">
                      3073 </text>
                    <circle className="st4" cx="122.5" cy="537.1" r="31.5" />
                    <text x="122.5" y="537.1" textAnchor="middle" fill="white" fontSize="30px">
                      3066 </text>
                    <line className="st2" x1="295.7" y1="351.3" x2="278.7" y2={274} />
                    <line className="st2" x1="273.7" y1="370.6" x2={213} y2="356.6" />
                    <line className="st2" x1={325} y1="396.1" x2="406.3" y2="451.6" />
                    <circle className="st3" cx={310} cy="382.6" r={39} />
                    <text x={310} y="382.6" textAnchor="middle" fill="black" fontSize="30px">
                      1598  </text>
                    <circle className="st4" cx="263.8" cy="258.6" r="31.5" />
                    <text x="263.8" y="258.6" textAnchor="middle" fill="white" fontSize="30px">
                      3111 </text>
                    <circle className="st4" cx="194.4" cy="344.2" r="31.5" />
                    <text x="194.4" y="344.2" textAnchor="middle" fill="white" fontSize="30px">
                      3080 </text>
                    <g>
                      <line className="st2" x1={459} y1="242.6" x2={451} y2="154.6" />
                      <line className="st2" x1="439.4" y1="248.6" x2={370} y2="208.6" />
                      <line className="st2" x1="469.9" y1="285.3" x2="511.1" y2="370.6" />
                    </g>
                    <circle className="st3" cx="466.8" cy={273} r={39} />
                    <text x="466.8" y={273} textAnchor="middle" fill="black" fontSize="30px">
                      1879  </text>
                    <circle className="st4" cx="437.5" cy="132.1" r="31.5" />
                    <text x="437.5" y="132.1" textAnchor="middle" fill="white" fontSize="30px">3120 </text>
                    <circle className="st4" cx="347.5" cy="186.1" r="31.5" />
                    <text x="347.5" y="186.1" textAnchor="middle" fill="white" fontSize="30px">
                      3116 </text>
                    <line className="st2" x1="627.9" y1="193.9" x2={656} y2="126.4" />
                    <line className="st2" x1="606.7" y1="193.1" x2="564.3" y2="130.9" />
                    <line className="st2" x1="623.9" y1="233.8" x2={640} y2="334.6" />
                    <circle className="st3" cx="624.3" cy="225.3" r={39} />
                    <text x="624.3" y="225.3" textAnchor="middle" fill="black" fontSize="30px">
                      1908  </text>
                    <circle className="st4" cx="662.5" cy="96.1" r="31.5">
                      <title>1</title>
                    </circle>
                    <text x={670} y={100} textAnchor="middle" fill="white" fontSize="30px">
                      3137 </text>
                    <circle className="st4" cx="545.5" cy="105.1" r="31.5" />
                    <text x="545.5" y="105.1" textAnchor="middle" fill="white" fontSize="30px">
                      3136 </text>
                    <line className="st2" x1="800.5" y1="223.2" x2={866} y2="163.6" />
                    <line className="st2" x1="782.3" y1="212.3" x2="775.2" y2="137.4" />
                    <line className="st2" x1="777.9" y1="256.2" x2="749.2" y2="350.4" />
                    <circle className="st3" cx="782.3" cy={249} r={39} />
                    <text x="782.3" y={249} textAnchor="middle" fill="black" fontSize="30px">
                      1958  </text>
                    <circle className="st4" cx="878.5" cy="150.1" r="31.5" />
                    <text x="878.5" y="150.1" textAnchor="middle" fill="white" fontSize="30px">
                      3255 </text>
                    <circle className="st4" cx="779.5" cy="114.1" r="31.5" />
                    <text x="779.5" y="114.1" textAnchor="middle" fill="white" fontSize="30px">
                      3148 </text>
                    <g>
                      <polyline className="st5" points="318.6,621.4 399.1,862.8 626.8,976.6 868.2,896.2 982,668.5 901.5,427.1 673.9,313.3 432.5,393.8
" />
                    </g>
                    <g>
                      <g>
                        <polyline className="st4" points="415,595.6 879.9,595.6 838,460.6 647.4,374.2 469,460.6" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,515.3c62.7,0,115.1,34.4,127.8,80.2H865L647.4,374.2L442,595.6h74.7
C529.4,549.7,581.8,515.3,644.5,515.3z" />
                    <g>
                      <g>
                        <polyline className="st4" points="415,689.2 879.9,689.2 838,824.2 647.4,910.6 469,824.2" />
                      </g>
                    </g>
                    <path className="st6" d="M644.5,769.5c62.7,0,115.1-34.4,127.8-80.2H865L647.4,910.6L442,689.2h74.7
C529.4,735.1,581.8,769.5,644.5,769.5z" />
                    <ellipse className="st5" cx={640} cy="645.1" rx={144} ry="139.5"> </ellipse>
                    <g>
                      <text x={650} y="645.1" textAnchor="middle" fill="white" fontSize="40px">76 </text>
                      <text x={650} y={480} textAnchor="middle" fill="black" fontSize="30px">184      </text>
                      <text x={650} y={830} textAnchor="middle" fill="black" fontSize="30px">186      </text>
                      <text x={500} y={480} textAnchor="middle" fill="white" fontSize="30px">443      </text>
                      <text x={440} y={480} textAnchor="middle" fill="white" fontSize="30px">483      </text>
                      <text x={510} y={400} textAnchor="middle" fill="white" fontSize="30px">614      </text>
                      <text x={800} y={480} textAnchor="middle" fill="white" fontSize="30px">445      </text>
                      <text x={890} y={500} textAnchor="middle" fill="white" fontSize="30px">1002      </text>
                      <text x={800} y={400} textAnchor="middle" fill="white" fontSize="30px">964      </text>
                      <text x={500} y={820} textAnchor="middle" fill="white" fontSize="30px">452      </text>
                      <text x={420} y={780} textAnchor="middle" fill="white" fontSize="30px">1267      </text>
                      <text x={500} y={890} textAnchor="middle" fill="white" fontSize="30px">1265      </text>
                      <text x={800} y={820} textAnchor="middle" fill="white" fontSize="30px">449      </text>
                      <text x={880} y={800} textAnchor="middle" fill="white" fontSize="30px">1047      </text>
                      <text x={800} y={890} textAnchor="middle" fill="white" fontSize="30px">1261      </text>
                    </g>
                  </svg></a>                      </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Footer />
    </section>

  )
}

export default Eight