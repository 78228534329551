import {useEffect} from 'react'
import { Route, Switch, BrowserRouter } from "react-router-dom";
import HomeScreen from './components/HomeScreen';
import Profile from './components/Profile';
import Invest from './components/Invest';
import FAQ from './components/FAQ';
import Seven from './components/seven';
import Eight from './components/eight';
import News from './components/news';
import NewsDetails from './components/newsdetails';
import Treeview from './components/Treeview';
import Scrolltop from './components/scrolltop';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-5LYKPCX0TK"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);



const App = () => {
  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname + window.location.search);
    if (window.location.href.includes('?ref=')) {
      let getAddress = getParameterByName('ref');
      let final = getAddress;
      localStorage.setItem('baseRef', final);
  }
  }, [])
 
 


  return (
    <>
      <BrowserRouter>
        <Scrolltop/>
        <Switch>
          <Route path="/" exact component={HomeScreen} />
          <Route path="/faq" exact component={FAQ} />
          <Route path="/news" exact component={News} />
          <Route path="/newsinfo/:newsid" exact component={NewsDetails} />
          <Route path="/seven" exact component={Seven} />
          <Route path="/profile" exact component={Profile} />
          <Route path="/Buy/:treeid/:boardid/:referrid" exact component={Invest} />
          <Route path="/tree/:treeid/:boardid/:referrid" exact component={Treeview} />
          <Route path="/eight" exact component={Eight }/>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
