import { useContext } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import BlockchainContext from "../store/BlockchainContext";
import servicerequest from "../utils/config";
//import { Link } from "react-router-dom";

const Footer = () => {
  const { activeNetwork } = useContext(BlockchainContext);

  const [email, setEmail] = useState("");
  const [telegram, setTelegram] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const  handleSubmit= async (event) => {
    event.preventDefault();
    console.log(email);
    servicerequest.post("/subscribe/", "telegram=" + telegram+"&email="+email+"&whatsapp="+whatsapp).then((response)=> {
          console.log(response);
          console.log(response.data.message);
          //toast.success(response.data.message);
      if (response.data.status == 1) {
        toast.success(response.data.message);
        setTelegram("");
         setEmail("");
         setWhatsapp("");
         //toast.success("please unlock your TronLink wallet");
      } else if(response.data.status != 1) {
        toast.error(response.message);
        setTelegram("");
        setEmail("");
        setWhatsapp("");
        //toast.warn("please install TronLink wallet");
      }
    })
  };

  return (
    <section className="homepage">
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-3">
              <div className="footerContent footerContent-links">
                <h2 className="pb-0">TRON GOOGOL BSC</h2>
                <ul>
                  <li style={{textAlign:"left"}}>
                    <a href={activeNetwork.explorerlink[0]} target="_blank">SMART CONTRACT</a>
                  </li>
                  <li style={{textAlign:"left"}}>
                   {/* <Link to="/faq">FAQ</Link> */ }
                   <a href="#">FAQ</a>
                  </li>
                  <li style={{textAlign:"left"}}>
                    <a href="#">
                      Events &amp; Announcements</a>
                  </li>
                  <li style={{color:"#FFF",textAlign:"left"}}>
                    
                    View Brochure in your language<br/>
                    <select id="lang"  value={selectedOption} onChange={(e) => { setSelectedOption(e.target.value); window.open("https://www.bnbcrowdfunding.com/assets/docs/"+e.target.value, "_blank", "noreferrer");}}> 
                      <option value="">Select</option>
                      <option value="Vietnamese.pdf">Vietnamese</option>
                      <option value="Bengali.pdf">Bengali</option>
                      <option value="Chinese.pdf">Chinese</option>
                      <option value="English.pdf">English</option>
                      <option value="Filipino.pdf">Filipino</option>
                      <option value="French.pdf">French</option>
                      <option value="German.pdf">German</option>
                      <option value="Hindi.pdf">Hindi</option>
                      <option value="Indonesian.pdf">Indonesian</option>
                      <option value="Japanese.pdf">Japanese</option>
                      <option value="Portuguese.pdf">Portuguese</option>
                      <option value="Punjabi.pdf">Punjabi</option>
                      <option value="Russian.pdf">Russian</option>
                      <option value="Spanish.pdf">Spanish</option>
                      <option value="Thai.pdf">Thai</option>
                  </select>
                  </li>
                   <li style={{textAlign:"left"}}>
                    <a href="#" >How to Join Guide - Step By Step </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 pl-0">
              <div className="footerContent">
                <h2 className="pb-0">CONTACTS</h2>
                <ul>
                  <li>
                    <a href="mailto:support@bnbcrowdfunding.com">support@bnbcrowdfunding.com</a>
                  </li>
                </ul>
                <div className="col-md-12 mt-3 mb-3">
                  <a href="https://trongoogol.groovepages.com/bsc" target="_blank"><img src={`${process.env.PUBLIC_URL}/assets/images/165659306417.png`}  alt="tron googol"/></a>
                </div>
                <ul>
                  <li> <a > Join Tron Googol &amp; get your Sales &amp; Marketing Funnel For Free. Click the image above to learn more. </a> </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-4 col-lg-6">
              <div className="footerContent">
                <h2>REVIEWS</h2>
                <div className="row align-items-center">
                  <div className="col-6 col-sm-4 col-md-6 col-lg-4 text-center">
                    <div className="reviewsImage">
                      <a href="https://www.dappstats.com/" target="_blank">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/1607347370.png`} alt="Dapp-stats-logo-white" />
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-sm-4 col-md-6 col-lg-4 text-center">
                    <div className="reviewsImage">
                      <a href="https://www.dapp.com/" target="_blank">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/1607347353.png`} alt="Dapp-stats-logo-white" />
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-sm-4 col-md-6 col-lg-4 text-center">
                    <div className="reviewsImage">
                      <a href="https://dappradar.com/" target="_blank">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/1607347340.png`} alt="Dapp-stats-logo-white" />
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-sm-4 col-md-6 col-lg-4 text-center">
                    <div className="reviewsImage">
                      <a href="https://dapp.review/" target="_blank">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/1607347323.png`} alt="Dapp-stats-logo-white" />
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-sm-4 col-md-6 col-lg-4 text-center">
                    <div className="reviewsImage">
                      <a href="https://dapp-world.com/" target="_blank">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/1607347306.png`} alt="Dapp-stats-logo-white" />
                      </a>
                    </div>
                  </div>
                  <div className="col-6 col-sm-4 col-md-6 col-lg-4 text-center">
                    <div className="reviewsImage">
                      <a href="https://dapponline.io/" target="_blank">
                        <img src={`${process.env.PUBLIC_URL}/assets/images/1607347289.png`} alt="Dapp-stats-logo-white" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="join_request">
                  <h2 className="join_request-header">Request Referral Joining Link &amp; For Newsletter</h2>
                  <form id="Join_request_form" method="post" encType="multipart/form-data" onSubmit={handleSubmit.bind(this)}>
                    <div className="join_request-contain row">
                      <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="email" className="form-label">Email address : </label>
                          <input type="email" className="form-control" name="email" value={email} onChange={(event) => {setEmail(event.target.value);}} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="Whatsapp" className="form-label">Whatsapp Link : </label>
                          <input type="text" className="form-control" name="Whatsapp" id="join-request-watapp" value={whatsapp} onChange={(event) => {setWhatsapp(event.target.value);}}  />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6">
                        <div className="form-group">
                          <label htmlFor="Telegram" className="form-label">Telegram Link : </label>
                          <input type="text" className="form-control" name="Telegram" id="join-request-telegram" value={telegram} onChange={(event) => {setTelegram(event.target.value);}}  />
                        </div>
                      </div>
                      <div className=" col-sm-12 col-md-12 col-lg-6">
                        <div className="g-recaptcha" data-sitekey="6Lf4SQwjAAAAAAnMvP5g4NZAcQy36wrfDadSNi7e" name="captcha" />
                      </div>
                      <div className=" col-sm-12 col-md-12 col-lg-6">
                        <button type="submit" className="btn btn-success">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* footer content End */}
      <div className="copyRight">
        <div className="container-fluid d-flex justify-content-between">
          <p>2023©All rights reserved | Tron Googol BSC https://www.bnbcrowdfunding.com </p>
          <div className="soc_icons">
            <ul>
              <li><a href="https://t.me/trongoogolofficial" target="_blank"><i className="fab fa-telegram" /></a></li>
              <li><a href="https://t.me/cryptomediaservices" target="_blank"><i className="fab fa-telegram" /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Footer 