import { useContext } from "react";
import BlockchainContext from "../store/BlockchainContext";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import Footer from "./Footer"
import Header from "./Header"
import { Link } from "react-router-dom";
import { ApplicationConstants } from "../utils/ApplicationConstants";

const Profile = () => {
  const { userinfo, userTransactions, activeAccount, handleInvest, usdPrice, Reinvest, treecount } = useContext(BlockchainContext);
  const getboardreferer = (_treeid) => {
    for (let loopvar = 0; loopvar < userTransactions.length; loopvar++) {
      if ((userTransactions[loopvar].treeid == _treeid))
        return userTransactions[loopvar].refferrer;
    }
    return ApplicationConstants.defaultreferral;
  }
  const buyFirstTree = (_treeid) => {
    for (let loopvar = 0; loopvar < userTransactions.length; loopvar++) {
      if ((userTransactions[loopvar].treeid == _treeid) && (userTransactions[loopvar].board == 0))
        return false;
    }
    return true;
  }
  const buySecondTree = (_treeid) => {
    for (let loopvar = 0; loopvar < userTransactions.length; loopvar++) {
      if ((userTransactions[loopvar].treeid == _treeid) && (userTransactions[loopvar].board == 1))
        return false;
    }
    return true;
  }
  const buyThirdTree = (_treeid) => {
    for (let loopvar = 0; loopvar < userTransactions.length; loopvar++) {
      if ((userTransactions[loopvar].treeid == _treeid) && (userTransactions[loopvar].board == 2))
        return false;
    }
    return true;
  }
  const checkpendingtrees = (_boardid) => {
    for (let loopvar = 1; loopvar <= treecount; loopvar++) {
      if (_boardid == 0) {
        if (buyFirstTree(loopvar))
          return true;
      }
      else if (_boardid == 1) {
        if (buySecondTree(loopvar))
          return true;
      }
      else if (_boardid == 2) {
        if (buyThirdTree(loopvar))
          return true;
      }
    }
    return false;
  }
  const investnow = async (_treeid, _boardid) => {
    handleInvest(_treeid, _boardid, getboardreferer(_treeid));
  };
  const reinvestnow = async (_treeid, _boardid) => {
    Reinvest(_treeid, _boardid, ApplicationConstants.defaultreferral);
  };


  return (
    <section className="homepage">
      <Header />
      {/* Header End */}
      <input type="hidden" id="baseurl" defaultValue="https://www.bnbcrowdfunding.com/" />
      <input type="hidden" id="base_url" defaultValue="https://www.bnbcrowdfunding.com/" />
      <input type="hidden" id="curr_lang" defaultValue="en" />
      <div id="cover-spin" style={{ display: 'none' }}>
        <div className="test">
          <center>
            <p className="show_text"> Transaction in Process... <br /> Please don’t click or refresh the page. <br />
              Please check your transaction on Tron link. </p>
          </center>
        </div>
      </div>    <div id="sideMenu">
      </div>
      <div id="singleWindow">
        {/* Banner Start */}
        <div className="fullscreen homePlansQA" id="single4">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <h2 className="sectionTitle">Your Profile</h2>
                <p className="sectionPara" style={{wordWrap: "break-word"}}>PASSIVE EARNING CROWDFUNDING MODEL</p>
                <p className="sectionPara" style={{wordWrap: "break-word"}}>Account ID : {activeAccount}</p>
                <div className="hplanQAblocks">
                  <div className="row">
                    {/* QA content Start */}
                    <div className="col-sm-12 col-md-6">
                      <a className="popover__wrapper__" data-id={8}>
                        <div className="hplanQAcontent popover__wrapper" style={{ width: "100%" }}>
                          <div className="d-flex align-items-center popover__title">
                            <div className="hplanQAicon">
                              <div className="hplanQAiconText">?</div>
                            </div>
                            <div className="hplanQAtext" style={{ width: "100%" }}>
                              <h4 id="head_8">
                                Your Contribution </h4>
                              <p id="content_8">{userinfo[0]} BNB</p>
                              <p id="content_8"> ≈ {userinfo[0] * usdPrice} USD</p>
                              <input type="hidden" name="l_content" id="l_content_8" defaultValue="If you were given US $ 181,720.25 in the next 3 weeks, what would you do with it?<br><br>~Maybe you want to pay of DEBT<br>~Maybe you want to purchase a HOME<br>~Maybe you want to own a BUSINESS<br>~Or Maybe you want to INVEST<br><br>ARE YOU WILLING TO COMMIT FILLING THIS WONDERFUL BOARD?<br><br>~All that you need is your 2 and they need their 2 to fill the board with 63 members.<br>~If you want, you can fill the board within a day and re-enter as many times you want." />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* QA content End */}
                    {/* QA content Start */}
                    <div className="col-sm-12 col-md-6">
                      <a className="popover__wrapper__" data-id={7}>
                        <div className="hplanQAcontent popover__wrapper" style={{ width: "100%" }}>
                          <div className="d-flex align-items-center popover__title">
                            <div className="hplanQAicon">
                              <div className="hplanQAiconText">?</div>
                            </div>
                            <div className="hplanQAtext" style={{ width: "100%" }}>
                              <h4 id="head_7">Contribution Received From Crowdfunding</h4>
                              <p id="content_7"> {userinfo[1]} BNB</p>
                              <p id="content_7"> ≈ {userinfo[1] * usdPrice} USD</p>
                              <input type="hidden" name="l_content" id="l_content_7" defaultValue="It is an amazing opportunity that can potentially change the trajectory of your financial life.<br><br>Created for generating WEALTH FOR LIKE-MINDED PEOPLE by working as a community to reach personal and professional goals by displaying equal efforts to achieve equal outcomes." />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* QA content End */}
                    {/* QA content Start */}
                    <div className="col-sm-12 col-md-6">
                      <a className="popover__wrapper__" data-id={6}>
                        <div className="hplanQAcontent popover__wrapper" style={{ width: "100%" }}>
                          <div className="d-flex align-items-center popover__title">
                            <div className="hplanQAicon">
                              <div className="hplanQAiconText">?</div>
                            </div>
                            <div className="hplanQAtext" style={{ width: "100%" }}>
                              <h4 id="head_6">Earnings From Referral</h4>
                              <p id="content_6">{userinfo[2]} BNB </p>
                              <p id="content_6"> ≈ {userinfo[2] * usdPrice} USD</p>
                              <input type="hidden" name="l_content" id="l_content_6" defaultValue="How do I fit into the team's commitment circle?<br><br>~I AM A VISUAL PERSON. <br>~PLEASE SHOW ME WHAT THE GIVE-IN-CYCLE LOOKS LIKE.<br><br>Please watch the Video Presentation here https://youtu.be/DQ9Jbs-oPb8" />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* QA content End */}
                    {/* QA content Start */}
                    <div className="col-sm-12 col-md-6">
                      <a className="popover__wrapper__" data-id={5}>
                        <div className="hplanQAcontent popover__wrapper" style={{ width: "100%" }}>
                          <div className="d-flex align-items-center popover__title">
                            <div className="hplanQAicon">
                              <div className="hplanQAiconText">?</div>
                            </div>
                            <div className="hplanQAtext" style={{ width: "100%" }}>
                              <h4 id="head_5">Count of Contributions joined </h4>
                              <p id="content_5">
                                you have joined in {userTransactions.length} boards </p>
                              <input type="hidden" name="l_content" id="l_content_5" defaultValue="Crowd-funding may seem like a new idea, but it actually has a long and rich history with roots going back to the 1700’s. Some of the finding in different cultures of the world are as below.<br><br>ASIAN CULTURE<br><br>Members of a family will put in money to contribute to one family getting a business. All members will work at that one family’s business until enough wealth is generated to open the next family’s business.<br><br>JAMAICAN CULTURE<br><br>Individuals come together by giving designated person a certain amount of money on a weekly basis and at the end of the year that you have contributed is returned in totality. There is no additional profit is made on the contribution.<br><br>HISPANIC CULTURE<br><br>Families contribute a weekly set amount to a certain family for a set period of time. One the time is fulfilled, each family continues to contribute and the next family collects. There is also no profit made on this contribution." />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* QA content Start */}
                    {/* QA content Start */}
                    <div className="col-sm-12 col-md-6">
                      <a className="popover__wrapper__" data-id={5}>
                        <div className="hplanQAcontent popover__wrapper" style={{ width: "100%" }}>
                          <div className="d-flex align-items-center popover__title">
                            <div className="hplanQAicon">
                              <div className="hplanQAiconText">?</div>
                            </div>
                            <div className="hplanQAtext" style={{ width: "100%" }}>
                              <h4 id="head_5">Total Earnings </h4>
                              <p id="content_5">{Number(userinfo[1]) + Number(userinfo[2])} BNB </p>
                              <p id="content_5"> ≈ {(Number(userinfo[1]) + Number(userinfo[2])) * usdPrice} USD </p>

                              <input type="hidden" name="l_content" id="l_content_5" defaultValue="Crowd-funding may seem like a new idea, but it actually has a long and rich history with roots going back to the 1700’s. Some of the finding in different cultures of the world are as below.<br><br>ASIAN CULTURE<br><br>Members of a family will put in money to contribute to one family getting a business. All members will work at that one family’s business until enough wealth is generated to open the next family’s business.<br><br>JAMAICAN CULTURE<br><br>Individuals come together by giving designated person a certain amount of money on a weekly basis and at the end of the year that you have contributed is returned in totality. There is no additional profit is made on the contribution.<br><br>HISPANIC CULTURE<br><br>Families contribute a weekly set amount to a certain family for a set period of time. One the time is fulfilled, each family continues to contribute and the next family collects. There is also no profit made on this contribution." />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* QA content Start */}
                    {/* QA content Start */}
                    <div className="col-sm-12 col-md-6">
                      <a className="popover__wrapper__" data-id={5}>
                        <div className="hplanQAcontent popover__wrapper" style={{ width: "100%" }}>
                          <div className="d-flex align-items-center popover__title">
                            <div className="hplanQAicon">
                              <div className="hplanQAiconText">?</div>
                            </div>
                            <div className="hplanQAtext" style={{ width: "100%" }}>
                              <h4 id="head_5">Total Referral Count </h4>
                              <p id="content_5"> {Number(userinfo[3])} </p>
                              <input type="hidden" name="l_content" id="l_content_5" defaultValue="Crowd-funding may seem like a new idea, but it actually has a long and rich history with roots going back to the 1700’s. Some of the finding in different cultures of the world are as below.<br><br>ASIAN CULTURE<br><br>Members of a family will put in money to contribute to one family getting a business. All members will work at that one family’s business until enough wealth is generated to open the next family’s business.<br><br>JAMAICAN CULTURE<br><br>Individuals come together by giving designated person a certain amount of money on a weekly basis and at the end of the year that you have contributed is returned in totality. There is no additional profit is made on the contribution.<br><br>HISPANIC CULTURE<br><br>Families contribute a weekly set amount to a certain family for a set period of time. One the time is fulfilled, each family continues to contribute and the next family collects. There is also no profit made on this contribution." />
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                    {/* QA content Start */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="fullscreen banner">
          <div className="container containerTwo">
            <div className="bannerContent">
              <div className="row flex-column">
                <div className="col-sm-12" />
                <div className="col-sm-12">
                  <div className="affiliate-box ">
                    <div className="d-flex" style={{ alignitems: "center", justifycontent: "center",overflow:"scroll" }}>
                      <table className="table_1" style={{ width: '100%', height: '100px', textAlign: 'center', padding: '20px', background: '#1a1917', border: '1px solid', color: '#FFF' }}>
                        <thead style={{ background: "#53514d", border: "1px solid" }}>
                          <tr>
                            <th style={{ width: "5%" }}> Tree</th>
                            <th style={{ width: "5%" }}> Board</th>
                            <th style={{ width: "5%" }}> Member Id</th>
                            <th style={{ width: "8.5%" }}> Amount</th>
                            <th style={{ width: "5%" }}>Referred By</th>
                            <th style={{ width: "12.5%" }}>Crowdfund Earned</th>
                            <th style={{ width: "12.5%" }}>Rejoining Status</th>
                            <th style={{ width: "12.5%" }}> View</th>
                            <th style={{ width: "12.5%" }}> Referral Link</th>
                            <th style={{ width: "12.5%" }}>Referred</th>
                            <th style={{ width: "12.5%" }}>Fund Higher Option </th>
                          </tr>
                        </thead>
                        <tbody>
                          {userTransactions
                            ? userTransactions.map((info, index) => (
                              <tr key={index}>
                                <td> {info.treeid} </td>
                                <td>{info.board == 0 ? " 25 $" : (info.board == 1 ? "50 $ " : " 100 $")}</td>
                                <td> {info.position} </td>
                                <td> {info.amount}</td>
                                <td> {info.refferrer}</td>
                                <td> {info.amountreceivedfrom} user paid you {info.amountreceived}</td>
                                <td>
                                  {((info.iseligible == true) && (info.reentryused == true)) ?
                                    (
                                      <p>Reentered</p>
                                    ) : (
                                      (info.iseligible == true) && (info.reentryused == false) && (Number(info.referralusers) >= 5) ? (
                                        (checkpendingtrees(info.board)) ? (
                                          <button className="nav-link link display-7 connect Click-here btn" onClick={() => reinvestnow(info.treeid, info.board)}>Rejoin</button>
                                        ) : (
                                          <p> you are member of all trees</p>
                                        )
                                      ) : (
                                        (Number(info.referralusers) >= 5) ?
                                          <p>Qualified for re-entry.</p> : <p>Not Qualified for re-entry.</p>)
                                    )}
                                </td>
                                <td><Link to={`/tree/${info.treeid}/${info.board}/${activeAccount}`}> <button className="pers-copy-btn">View Board</button></Link></td>
                                <td>
                                  <CopyToClipboard
                                    text={`https://${window.location.host}/buy/${info.treeid}/${info.board}/${activeAccount}`}
                                    onCopy={() => {
                                      toast.success("Referral link Copied Successfully");
                                    }}
                                  >
                                    <button className="pers-copy-btn">Copy</button>
                                  </CopyToClipboard>
                                </td>
                                <td> {info.referralusers}</td>
                                <td>
                                  {(buyFirstTree(info.treeid) == true) ? (
                                    <button className="nav-link link display-7 connect Click-here btn" onClick={() => investnow(info.treeid, "0")}>Join with US $ 25</button>
                                  ) : (<></>)}
                                  {((buySecondTree(info.treeid) == true) && (buyFirstTree(info.treeid) == false) && (info.board == 0)) ? (
                                    <button className="nav-link link display-7 connect Click-here btn" onClick={() => investnow(info.treeid, "1")}>Join with US $ 50</button>
                                  ) : (<></>)}
                                  {((buyThirdTree(info.treeid) == true) && (buySecondTree(info.treeid) == false) && (buyFirstTree(info.treeid) == false) && (info.board == 1)) ? (
                                    <button className="nav-link link display-7 connect Click-here btn" onClick={() => investnow(info.treeid, "2")}>Join with US $ 100</button>
                                  ) : (<></>)}
                                </td>
                              </tr>
                            ))
                            : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12">
                  <h3 style={{ color: "#FFF", marginTop: "20px" }}>Legends:</h3>
                  <ul style={{ color: "#FFF" }}>
                    <li> Entry point is US $ 25. You will earn US $ 3875 once you reach income limit at the end of the 3rd Cycle. At this point you will qualify for an Auto Re-Entry.</li>
                    <li>To qualify for US $ 50 entry point, you must first enter the US $ 25 crowdfunding plan. You will earn US $ 7750 once you reach income limit at the end of the 3rd Cycle. At this point you will qualify for an Auto Re-Entry. </li>
                    <li>To qualify for US $ 100 entry point, you must also enter with US $ 50 crowdfunding plan. You will earn US $ 15500 once you reach income limit at the end of the 3rd Cycle. At this point you will qualify for an Auto Re-Entry. </li>
                    <li>In total you will earn <b>US $ 26625 worth of BNB</b> once your income level has reached the end of the 3rd cycle for US $ 25, US $ 50 & US $ 100 value in BNB respectively.</li>
                    <li>In addition to your earnings from CROWDFUNDING which assures <b>US $ 26625</b> approximately, you will also earn continuously by referring others to the Tron Googol BSC platform. You will earn 5%, 4% & 3% as a referral fee amount from US $ 25, US $ 50 & US $ 100 value in BNB respectively for every person that you will be referring to Tron Googol BSC. It is highly recommended to invite as many people you can. This will ensure even higher income in addition to your projected income from crowdfunding.</li>
                  </ul>
                </div>

                <div className="col-sm-12" style={{ borderStyle: "solid", borderRadius: "12px", borderColor: "#FFF", background: "rgb(84, 33, 31)" }}>
                  <h4 style={{ color: "#FFF", marginTop: "20px" }}>THE BELOW MESSAGE IS VERY IMPORTANT. THEREFORE, PLEASE TAKE A NOTE OF IT. YOU WILL GET CROWDFUNDED WITH BNB FOR LIFE IF YOU OBSERVE THE FOLLOWING.</h4>
                  <ul style={{ color: "#FFF" }}>
                    <li> 1. There are 3 cycles from which a participant in this platform will earn BNB by crowdfunding.</li>

                    <li>2. For receiving crowdfund earnings from the first 2 cycles, you must assist at-least 2 people to participate on this platform using your personalized referral link. </li>

                    <li>3. For receiving crowdfund earnings from the 3rd cycle, you must introduce another 3 people to participate on this platform using your personalized referral link.</li>

                    <li>4. You will not receive earnings from the 3rd cycle if you do not bring the add-on 3 people by the time you reach the upper limit earnings by the end of the 2nd Cycle. </li>

                    <li>5. It is suggested that all participates individually introduce 5 people in total. This will assure seamless upper limit earnings all the way to the end of the 3rd Cycles. </li>

                    <li>6. Once you reach the upper limit at the end of the 3rd cycle, the platform will generate a re-entry option for you to re-enter the platform again. Please rejoin immediately once you reach at this point. </li>

                    <li>7. After you have rejoined, all the people who has joined after you initially for your matrix will be placed under your matrix again once they have reached the upper limit earnings at the end of the 3rd cycle. </li>

                    <li>8. This process will continue over and over again thereby enabling participants to earn BNB by crowdfunding continuously.</li>
                  </ul>
                  <hr style={{ borderTop: "3px solid rgba(255,255,255,.8)" }} />
                  <div style={{ color: "#FFF" }}>
                    <h4>ALL THAT YOU NEED TO DO IS TO ASSIST AT-LEAST 5 PEOPLE TO PARTICIPATE ON THIS PLATFORM WITH YOUR PERSONALIZED REFERRAL LINK. THAT IS ALL.</h4> <br /><br />
                    THERE IS NO HOLD OF FUNDS ON THE SMART CONTRACT, WHICH MEANS THE PARTICIPANTS DO NOT HAVE TO SENT ANY SORT OF REQUEST TO ANYONE TO RECEIVE THEIR EARNED BNB. ALL BNB's REACHES RECIPIENTS WALLETS REAL-TIME AUTOMATICALLY TRIGGERED BY THE SMART CONTRACT FOR THE MATRIX IN WHICH EACH RECIPIENTS ARE PLACED ON THIS PLATFORM. THIS MEANS THAT THIS PLATFORM IS TRULY DECENTRALIZED WITH NO INTERVENTION OF ANY KINDS ASSURING FAIRNESS OF DEFI PROTOCOLS & PRINCIPALS. <br /><br />
                    THE PLATFORM WELCOMES EVERYONE FROM ALL OVER THE WORLD TO ONE OF THE MOST INNOVATIVE YET SIMPLE TO UNDERSTAND LIFETIME EARNING OPPORTUNITY WITH A MINIMUM ONETIME ENTRY CONTRIBUTION OF US $ 25.     <br /><br />
                    <h4>TRON GOOGOL CROWDFUNDING BY BNB LOOKS FORWARD TO YOUR PARTICIPATION.</h4>
                  </div>
                  <br />
                </div>
                <div className="col-sm-12" style={{ display: "none" }}>
                  <h3 style={{ color: "#FFF", marginTop: "20px" }}>How to Share Referral Links?</h3>
                  <ul style={{ color: "#FFF", marginBottom: "50px", fontSize: "18px" }}>
                    <li>For referring a person to US $ 25 entry, please share the referral link for US $ 25 by clicking <b>“COPY”</b>.</li>
                    <li>For referring a person to US $ 50 entry, please share the referral link for US $ 50 by clicking <b>“COPY”</b>.</li>
                    <li>For referring a person to US $ 100 entry, please share the referral link for US $ 100 by clicking <b>“COPY”</b>. </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br /> <br />
        {/* Home Plan QA End */}
      </div>

      {/* The Modal */}
      <div className="modal fade planModal" id="planB-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <input type="hidden" name="referrer_value" id="referrer_value" defaultValue={1} />
              <h2></h2>
              <h4> Choose plan </h4>
              <form id="planb_invest" method="post" acceptCharset="utf-8">
                <select name="plans" id="planb" defaultValue={100} className="custom-select">
                  <option value="0" data-min={100}> Silver</option>
                  <option value="1" data-min={1000}>GOLD</option>
                  <option value="2" data-min={5000}>DIAMOND</option>
                  <option value="3" data-min={20000}>PLATINUM</option>
                </select>
                <div className="input-group">
                  <input type="text" className="form-control" name="plan_amt" id="plan_amt" placeholder="Enter amount" />
                  <input type="hidden" className="form-control" name="set_min" id="set_min" defaultValue={100} />
                  <div className="input-group-append">
                    <span className="input-group-text">.BNB</span>
                  </div>
                </div>
                <label htmlFor="plan_amt" className="error" />
                <p className="popupHints min_deposit"> Minimum amount required: 100</p>
                <div className="custom-control custom-checkbox">
                  <input type="checkbox" className="custom-control-input" id="customCheck" name="customCheck" defaultValue={1} />
                  <label className="custom-control-label" htmlFor="customCheck">
                    By clicking this check box I confirm to invest the amount in BNB entered above                            .</label>
                </div>
                <label htmlFor="customCheck" className="error" />
                <div className="popupSubmit">
                  <button type="submit" id="investplanb" className="btn noBoxShadow"> invest                      </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* Plan Modal End */}
      <div className="modal fade planModal" id="info-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <h2 className="text-center"> Information </h2>
              <h4 className="clr_org">
                We are in main network. Kindly change your Tron node at TRON Mainnet (TronGrid). If you have already in Mainnet, Kindly refresh the page (CTRL + F5) .
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal history-modal" id="planb-history-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h6> PLAN B - <span id="planName"> </span> <span>
                DEPOSIT HISTORY</span> </h6>
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <div className="table-responsive modalTableContainer">
                <table className="table table-responsive">
                  <thead>
                    <tr>
                      <th> Sl.No</th>
                      <th className="width_5"> Amount</th>
                      <th> Date Time</th>
                      <th> Days</th>
                    </tr>
                  </thead>
                  <tbody id="view_history">
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade join_popup" id="modal1" tabIndex={-1} role="dialog" aria-labelledby="modal1Label" aria-hidden="true">
        {/* Modal */}
        {/* <div class="modal fade align-items-center join_popup" id="basicExampleModal modal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"> */}
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel" />
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body" id="show_modl_content">
            </div>
            {/*  <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
          </div>
        </div>
      </div>
      <div className="modal fade planModal" id="withdraw-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <h6> WITHDRAW </h6>
              <h4> Withdraw Amount </h4>
              <form action="https://www.bnbcrowdfunding.com/" id="withdraw_form" method="post" acceptCharset="utf-8">
                <input type="text" name="with_amt" id="with_amt" className="form-control" />
                <label htmlFor="with_amt" className="error" />
                {/* <p class="popupHints min_deposit">   </p> */}
                <div className="popupSubmit">
                  <button type="submit" id="investplanb" className="btn noBoxShadow"> WITHDRAW                      </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal history-modal" id="planb-withhistory-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h6> WITHDRAW - <span id="planName"> </span> <span>
                HISTORY</span> </h6>
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <div className="table-responsive modalTableContainer">
                <table className="table table-responsive" id="with_view_history" data-page-length={5}>
                  <thead>
                    <tr>
                      <th> Sl.No</th>
                      <th> TXn Hash</th>
                      <th className="width_5"> Amount</th>
                      <th> Date Time</th>
                    </tr>
                  </thead>
                  <tbody id="withdraw_view_history">
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal" id="joinModal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <h6>You can join the board using the referral link only. Kindly register using an existing board person's referral link or get the referral link from admin and register on Plan A.</h6>
              <h6> Get Referral Link Via : </h6>
              <ul className="list-unstyled d-flex justify-content-center social-icons nav nav-tabs border-bottom-0" id="myTab" role="tablist">
                <li>
                  <a href="#" data-toggle="tab" data-target="#google" role="tab" aria-controls="google" aria-selected="false">
                    <i className="fab fa-google" />
                  </a>
                </li>
                <li>
                  <a href="#" data-toggle="tab" data-target="#whatsapp" role="tab" aria-controls="whatsapp" aria-selected="false">
                    <i className="fab fa-whatsapp" />
                  </a>
                </li>
                <li>
                  <a href="#" data-toggle="tab" data-target="#telegram" role="tab" aria-controls="telegram" aria-selected="false">
                    <i className="fab fa-telegram-plane" />
                  </a>
                </li>
              </ul>
              <div className="tab-content mt-4" id="myTabContent">
                <div className="tab-pane" id="google" role="tabpanel" aria-labelledby="google-tab">
                  <form className="common_form" id="form_gmail" method="post">
                    <div className="form-group">
                      <input type="email" className="form-control" name="email_field" placeholder="Enter Gmail Id" id="email_field" />
                    </div>
                    <div className="form-group text-center">
                      <input type="submit" className="btnBr30 btnPrimay-small py-2" defaultValue="Submit" />
                    </div>
                  </form>
                </div>
                <div className="tab-pane" id="whatsapp" role="tabpanel" aria-labelledby="whatsapp-tab">
                  <form className="common_form" id="form_whatsapp" method="post">
                    <div className="form-group">
                      <input type="email" className="form-control" name="email_field" placeholder="Enter Gmail Id" id="wemail_field" />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Enter Your Whatsapp Number" name="number_field" id="Wnumber_field" />
                    </div>
                    <div className="form-group text-center">
                      <input type="submit" className="btnBr30 btnPrimay-small py-2" defaultValue="Submit" />
                    </div>
                  </form>
                </div>
                <div className="tab-pane" id="telegram" role="tabpanel" aria-labelledby="telegram-tab">
                  <form className="common_form" id="form_telegram" method="post">
                    <div className="form-group">
                      <input type="email" className="form-control" name="email_field" placeholder="Enter Gmail Id" id="temail_field" />
                    </div>
                    <div className="form-group">
                      <input type="text" className="form-control" placeholder="Enter Your Telegram Number" name="number_field" id="Tnumber_field" />
                    </div>
                    <div className="form-group text-center">
                      <input type="submit" className="btnBr30 btnPrimay-small py-2" defaultValue="Submit" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal video_pop" id="video-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <div className="col-md-12 text-center">
                Video i frame
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal video_pop" id="video-modal-new">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <div className="col-md-12 text-center">
                video iframe
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal new_pop" id="video-modal-news">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-md-12 text-center">
                <a href="https://bit.ly/3eSf2US" target="_blank">
                  <img src="../ajqgzgmedscuoc/front/images/pop_img.png" alt="tron googol" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade planModal" id="Reg_Modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h4 className="modal-title" />
              <button type="button" className="close" data-dismiss="modal">
                <svg width="16.971" height="16.971" viewBox="0 0 16.971 16.971">
                  <g id="close_button" data-name="close button" transform="translate(14.142 -356.382) rotate(45)">
                    <rect id="Rectangle_16" data-name="Rectangle 16" width={4} height={20} transform="translate(252 252)" fill="#fff" />
                    <rect id="Rectangle_17" data-name="Rectangle 17" width={4} height={20} transform="translate(264 260) rotate(90)" fill="#fff" />
                  </g>
                </svg>
              </button>
            </div>
            {/* Modal body */}
            <div className="modal-body">
              <h6>Please insert your Gmail email ID.
                <br />We need this information to keep you updated on Tron Googol
                project
              </h6>
              <div className="mt-4 reg-gmail-input-containner">
                <input type="gmail" className="reg-input" id="user-mail-info" />
              </div>
              <div className="reg-btn-containner mt-3">
                <button className="reg-btn" id="reg-btn">submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* footer content start */}

      {/*Start of Tawk.to Script*/}
      {/*

*/}
      {/*End of Tawk.to Script*/}
    </section>
  )
}
export default Profile
