import Footer from "./Footer"
import Header from "./Header"

const FAQ = () => {
  return (

    <section className="homepage">
      <Header />
      <div id="singleWindow" className="faq-block single_window">
        <div className="fullscreen homePlansQA" id="single4">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-12">
                <h2 className="sectionTitle">FAQ</h2>
                <div className="hplanQAblocks">
                  <div className="row justify-content-center">
                    <div className="col-md-10">
                      <div id="main">
                        <div className="container">
                          <div className="accordion" id="faq">
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_0" aria-expanded="true" aria-controls="faq_0">How To Withdraw Funds</a>
                              </div>
                              <div id="faq_0" className="collapse show" aria-labelledby="faqhead_0" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  Tron Googol is based on a decentralised model. This means that Tron Googol holds no funds themselves.
                                  As participates join and raise community funds, this gets evenly distributed to the network accordingly within the 4 cycles.
                                  Therefore, there is no withdraw option on Tron Googol. As you earn from each cycle, this gets automatically populated into your wallets.
                                  All transactions are visible via your own wallet and also on TronScan.
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_1" aria-expanded="true" aria-controls="faq_1">How to get my link to refer others</a>
                              </div>
                              <div id="faq_1" className="collapse " aria-labelledby="faqhead_1" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  <p>To obtain your link which you can share with others, follow these steps:</p>
                                  <p>1) Ensure your wallet is connect to the dapp</p>
                                  <p>2) Click on My Profile</p>
                                  <p>3) Click on Copy Referral Link</p>
                                  <p>In order to share your personal link, ensure that your Core 7 team has been formed.</p>
                                  <p>If your Core 7 team has not been formed as yet, click on “Copy Sponsors Link”.</p>
                                  <p>This will enable you to use your sponsors link, which in turn by default will form your Core 7. Once your Core 7 has been formed, you can then use your personal link to share. </p>
                                  <p><b>Teamwork Is Key</b></p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_2" aria-expanded="true" aria-controls="faq_2">How To Clear My Cache?</a>
                              </div>
                              <div id="faq_2" className="collapse " aria-labelledby="faqhead_2" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  <p>As Tron Googol functions on a Smart Contract on the blockchain, there are times outside of the control of Tron Googol, that your wallet does not connect to the Tron network. </p>
                                  <p>This will result in data not populating on the “My Profile” page or the total transacted amount not populating on the home page. It will also mean that you cannot join the $25, $50 or the $100 boards.</p>
                                  <p>To overcome this, you can refresh your browser until it does connect.</p>
                                  <p>If this does not work, you can clear the cache memory of your browser. </p>
                                  <p>1. To do this for PC users Press and hold the button CTRL on your keyboard and then press F5 and let go</p>
                                  <p>2. For Apple Mac users, press and hold the Cmd button, Shift and the letter “R”. </p>
                                  <p>This will refresh the page and you should see the green notice box as shown below.
                                    If the green notice box does not show, repeat the steps above to clear your browser cache until it does. (Ctrl F5 or Cmd Shift + R).</p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_3" aria-expanded="true" aria-controls="faq_3">How To Freeze TRX</a>
                              </div>
                              <div id="faq_3" className="collapse " aria-labelledby="faqhead_3" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  <p>In order to join Tron Googol, you need to have enough bandwidth and energy. If you get the an insufficient energy error message, it means you do not have enough energy to complete the transaction. Therefore, you can obtain and freeze Tron to support the transaction to join Tron Googol.</p>
                                  <p>Transaction consumes your bandwidth and Energy. You can obtain bandwidth and Energy by freezing TRX, or make sure that there are enough TRX in your account balance for burning.</p>
                                  <ol>
                                    <li>Each account has 5000 BP bandwidth resources free of charge every 24 hours.</li>
                                    <li>When you generate a transaction, the system will first consume the resources that you freeze TRX, and if it is not enough, it will consume your free quota resources.</li>
                                    <li>If the bandwidth and energy resources obtained by freezing TRX and free resources are exhausted, it will directly consume your TRX.&nbsp;</li>
                                  </ol>
                                  <p>&nbsp;</p>
                                  <p>If you have just created a brand-new wallet, most likely, your energy will be 0.</p>
                                  <p>To increase this, please follow the following steps:</p>
                                  <ol>
                                    <li>Go to your TronLink Wallet and select the wallet you join Tron Googol with.</li>
                                    <li>Press the “Freeze” icon</li>
                                  </ol>
                                  <p><img src="https://lh3.googleusercontent.com/5Ve-ckV-vUPbgF4e3sV_eGCxfTxuRRru6qpIOFvE4z0pfXmOj8NTD5fcECewSKYa3k9GSfw2dGhKiHeAXO3krD6_7ysh5PDLp0LltkCPe3GH9-f7naiPQ8aVVGQAbnr4ypCdHFtD=w2400" alt="tron googol" /></p>
                                  <p>This will open up a new window.</p>
                                  <ol start={3}>
                                    <li>On the top right of the screen, click on “Obtain”</li>
                                  </ol>
                                  <p><img src="https://lh3.googleusercontent.com/7zAuYBgbKooUsgWtUwXaZ-Vkcf68UZZJVtnmdTCCqUDb4ZmPg0FIQo-y89qetf2mw6Pg25dYMXepjuUK8IObfI7vSoTrXdyTfoOmeHXERgQ_tPSkyXWywhcKqiBTm-rZW259deg-=w2400" alt="tron googol" /></p>
                                  <p>4. On the first option, click on the dropdown box and select Tron Power and Energy</p>
                                  <p><img src="https://lh3.googleusercontent.com/OGHps615Dn3767_VTanofGxqHqaPM9Tu2vHcs31w096jr5iWfGJMZ34QMqx9coQ7cLl3r2za8D3j9fuMr-a3ePHnzOL2CTA46x1LH7B0V66CfluLiDL5mlVw0EeErMYaMGb4F-o4=w2400" alt="tron googol" /></p>
                                  <p>5. It is suggested to freeze at lease 100 Tron. Once confirmed, press on the Freeze and accept. The Tron will be frozen for 72 hours. After 72 hours you will receive this back into your wallet. Please ensure you have enough in your wallet to still join Tron Googol as now some of your Tron has been frozen/reserved so it cannot be used</p>
                                  <p>&nbsp;</p>
                                  <p><strong>Important: </strong>There are times when the Tron chain will deduct from your TRX balance if it does not have enough energy. This is outside of the control of Tron Googol and any additional transaction amount goes towards the Tron chain as network fees.</p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_4" aria-expanded="true" aria-controls="faq_4">Explain The Concept</a>
                              </div>
                              <div id="faq_4" className="collapse " aria-labelledby="faqhead_4" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <img width={480} height={315} src="../ajqgzgmedscuoc/img/admin/FAQ/1621085084.png" alt="tron googol"/>
                                    </div>
                                    <div className="col-sm-6">
                                    </div>
                                  </div>
                                  <p>In order to fully understand the concept behind Tron Googol, we suggest watching the official business presentation.</p>
                                  <p /><a href="https://youtu.be/AIxQRhDbhjc">Official Business Presentation Video</a><p />
                                  <p>Tron Googol is based on the concept of crowdfunding. The concept is nothing new apart from the fact we are using Blockchain technology. </p>
                                  <p>There are numerous examples of crowdfunding in operation today, whether to raise funds for a charity, start-up company or to help the community. </p>
                                  <p>In Asian culture, the members of a family will put in money to contribute to one family starting a business. Then all of the family members will work at that business, until enough wealth is generated to open the next families business. </p>
                                  <p>In Jamaican culture, individuals give a certain amount of money to a designated person on a weekly basis and at the end of the year, all the money you have given is fully returned. There is no additional profit or interest made on the contribution of each individual.</p>
                                  <p>In Hispanic culture, families contribute a fix amount of money to another family for a set period of time. Once that defined period passes, each family continue to contribute and then the next family collects. There is no profit or interest made on the contribution.</p>
                                  All of these 3 methods still occurs today and is built on the model of crowdfunding and helping each other within that community. Crowdfunding has been happening for over 200 years. The only difference now with Tron Googol is that all of this is automated and done through a smart contract using blockchain technology. All transactions are transparent via the open ledger and automatically done for you without any holds of funds.
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_5" aria-expanded="true" aria-controls="faq_5">What is a smart contract?</a>
                              </div>
                              <div id="faq_5" className="collapse " aria-labelledby="faqhead_5" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  <p>Tron Googol is a smart contract which is available on the Tron Blockchain. It is completely audited and verified by an independent company. </p>
                                  <p>A smart contract is basically a protocol or program that has been put into the blockchain whereby no one, including the designers or developers of Tron Googol can change the rules and all transaction are transparent and you have full visibility. </p>
                                  <p>There are no mediators or intermediaries and no human interaction, which is effectively guaranteeing the fairness of all people participating their funds according to the rules of the Smart Contract.</p>
                                  <p>We believe it is the most reasonable, safest, and best cutting-edge financial support model available at this time.</p>
                                  <p>To start a Smart Contract, there is no need to register with your email or phone number or name or address. All we need is for you to have a wallet topped up with Tron currency. </p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_6" aria-expanded="true" aria-controls="faq_6">Do I have to refer others?</a>
                              </div>
                              <div id="faq_6" className="collapse " aria-labelledby="faqhead_6" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  <p>You do not need to refer others. Just like any other crowdfunding platform, the more people that participate, the more funds that will be raised and therefore, the more that will get distributed.</p>
                                  <p>It is not a requirement to have to refer others. However, if you do, then this benefits the community as everyone can earn faster.</p>
                                  <p>As a minimum, once you refer 2 people using your referral link, you are entitled to join the $50 and $100 boards.</p>
                                  <p>If you are able to refer more than 2 people by sharing this great opportunity, then the faster everyone will earn.</p>
                                  <p>Through marketing campaigns, Tron Googol will also promote the platform. Anyone that joins through the marketing campaign will get spilled over and populate any empty positions. </p>
                                  <p>However, participants should not rely on this as the most powerful way to grow the network is word of mouth, which can only come from the participants. </p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_7" aria-expanded="true" aria-controls="faq_7">What happens when my board of 63 is full?</a>
                              </div>
                              <div id="faq_7" className="collapse " aria-labelledby="faqhead_7" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <img width={480} height={315} src="../ajqgzgmedscuoc/img/admin/FAQ/1621085475.png" alt="tron googol" />
                                    </div>
                                  </div>
                                  <p>When a board gets filled with 63 members it gets split automatically into 8 individual separate boards. </p>
                                  <p>In a board of 63, 7 of those are the Core 7 team.</p>
                                  <p>This leaves 56 people remaining.</p>
                                  <p>56 divided by 8 equals 7.</p>
                                  <p>This means that when a board gets filled, 8 further teams of 7 get formed. </p>
                                  <p>They will get their own boards each and the members become the “Core 7”, or the people in the middle of the new boards. </p>
                                  <p>If your board of 63 is full, and you refer others to join using your link, those members will get spilled over and populate one of the empty positions on the 8 split boards. </p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_8" aria-expanded="true" aria-controls="faq_8">Why can’t I get access to my referral link straight away?</a>
                              </div>
                              <div id="faq_8" className="collapse " aria-labelledby="faqhead_8" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  <p>The concept behind Tron Googol is to work as a team to raise funds. Once the funds have been raised, this gets evenly distributed across the network. This is the basis of the decentralised crowdfunding platform. </p>
                                  <p>In this unique concept, every participant works in a team of 7. This increases the chances of people succeeding and earning. The team of 7 is known as the CORE 7. </p>
                                  <p>By using this method, it means that no one is left behind. All previous boards are completed first before the next boards can be generated. This ensures that no one is left behind in this amazing opportunity. This is unlike MLM where the networks/trees/arms can vary depending on the efforts of a few and only a few people at the top actually generate any funds. In Tron Googol, everyone in the family benefits and no one gets left behind </p>
                                  <p>Therefore, when you join, you only get access to your personal link once your core 7 is formed. </p>
                                  <p>If you want to refer people prior to your core 7 being formed, you do not have to wait. You can work as a team</p>
                                  <p>Click on My Profile</p>
                                  <p>Click on “Copy Sponsors Link”</p>
                                  <p>This will enable you to use the link of your sponsor. When people join, this will by default populate your own board and core 7.</p>
                                  To fully understand, we suggest watching the following video:
                                  <a href="https://youtu.be/jhCL5weOf2Y?t=840">Link Explanation</a>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_9" aria-expanded="true" aria-controls="faq_9">How To Join Step 1: Create a digital wallet</a>
                              </div>
                              <div id="faq_9" className="collapse " aria-labelledby="faqhead_9" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  <p>As Tron Googol functions on a Smart Contract on the blockchain, to join, you have to use the Tron crypto currency. The first step is to create a wallet where you can hold and store Tron. This wallet is unique to you and will be the location where you will send and receive Tron from Tron Googol.</p>
                                  <p>There are many wallets available which can be used to join Tron Googol. Our recommendation is to use TronLink Pro as advised by the Tron Foundation.</p>
                                  <p>TronLink Pro is available as a desktop extension as well as a mobile application. This means that it can be used on a PC, Laptop, as well as a mobile device (Android or iOS).</p>
                                  <p>In order to use it on a PC or laptop, you have to use the Google Chrome Internet browser.</p>
                                  <ol>
                                    <li>Click on the following link and download the Chrome extension</li>
                                  </ol>
                                  <p><a href="https://chrome.google.com/webstore/detail/tronlink（波宝钱包）/ibnejdfjmmkpcnlpebklmnkoeoihofec">https://chrome.google.com/webstore/detail/tronlink（波宝钱包）/ibnejdfjmmkpcnlpebklmnkoeoihofec</a></p>
                                  <ol start={2}>
                                    <li>Once Installed, on the top right of your Google Chrome browser, click on the “Extensions” icon and pin the TronLink Pro application so that it always appears as a shortcut on your browser.</li>
                                  </ol>
                                  <p><strong>Extension Icon</strong></p>
                                  <p><strong><img src="https://drive.google.com/file/d/1qAdSGjpH-5Th9ZADr_f6avEQ5WFHjO5Z/view?usp=sharing" alt="tron googol" /></strong><strong><img src="https://lh3.googleusercontent.com/bkj8RNKghFuJ5Liw9K4jeD7hQyEvGwEIZ3N1TUBVLjA-qRCgIu88LtBNRFf0udHKlAzV1Fu2ZYRKkkC8M5Ol8Gq2OgOU-u34xS3TQw016BYhWYD8uB9tv7Gryt85VTFtEbcuA5Cs=w2400" alt="tron googol" /></strong></p>
                                  <p><strong>Pin Tron Link Icon</strong></p>
                                  <p><img src="https://lh3.googleusercontent.com/Nmg_bQP4wMseErS_VR6YO_-mU4cCYvFVwD4-2OYv6BM9OjjzopExvSSPsn9eBn0K1SahUH0NI0O5WlcMG5aNzU1U6LGJJUR60bZ-88CoI7TN_7KHNCWjHJ-D21AxhGlkSINjdKrb=w2400" alt="tron googol" /></p>
                                  <ol start={3}>
                                    <li>Next click on the icon and create a new account.</li>
                                  </ol>
                                  <p><strong>&nbsp;</strong><strong>Important: </strong>Please take a note of the password as this cannot be recovered if forgotten</p>
                                  <ol start={4}>
                                    <li>Next click on Create Wallet</li>
                                  </ol>
                                  <p>&nbsp; &nbsp; &nbsp; &nbsp; Enter a Wallet Name</p>
                                  <p><img src="https://lh3.googleusercontent.com/GRvkmOQcvFj9Cc_RVk5abfkzPuXa_PnIqm92bri2T-hkF0NUbUzcLwQBKY3CW7diprSIidy1nW-_nC-nPolO9xgWlFr30DBLTtKgav3cX1VJRCw_g2nX-cCKhUMuZHUq3_KcxtjW=w2400" alt="tron googol" /></p>
                                  <ol start={5}>
                                    <li>You will then be presented with your mnemonic phrase. Please take a note of this phrase and write it down.</li>
                                  </ol>
                                  <p><strong>&nbsp;</strong><strong>Important: </strong>In order to recover your wallet in the future you will need this mnemonic phrase so please store it somewhere safe. <strong><u>Do not lose the 12 words</u></strong></p>
                                  <p>&nbsp;</p>
                                  <p><img src="https://lh3.googleusercontent.com/xb2mV8_h7FFOGLBdHm5yIyyLg53h7Jtg0e_Pq24hqc-URbOPwaLlBcmibV0uDI2vs2kbHDi_YnFU1aV3XTHhLbMztDwalEkQ4UdrquJEi3q26wwfm6suOrjiKwJ0LeqXITF8MK16=w2400" alt="tron googol" /></p>
                                  <ol start={6}>
                                    <li>Once you press continue, you will be prompted to re-enter the phrase in the same order as you see it on the screen</li>
                                  </ol>
                                  <p><img src="https://lh3.googleusercontent.com/lmayf5R6b6OeBdaNWIgYbiu8xJaFjoaIQwaQTv9SR41dlmTxzEmM9iPqWOgVZMuPYldc4N7sjysQWZq3_kI5H0LmH8h1YyD_VgUNYuBqmNoIi7BV8z6XUoklrX0PToQMHcjQJT6p=w2400" alt="tron googol" /></p>
                                  <p>Once entered correctly, you can press confirm and then your wallet is created</p>
                                  <p><strong>Important:</strong> It is good practice to keep a record of your wallet ID and your private key in case you need to recover your account in the future.</p>
                                  <ol start={7}>
                                    <li>Your wallet ID can be copied by pressing the icon</li>
                                  </ol>
                                  <p><img src="https://lh3.googleusercontent.com/o4ES1VghF4lh356HQb7VXLPJsUSA4nAB6z0Gt8np-GIr_u7b1o99PYuV45VJSp6ksG8syq41j-rp6IARYq9p51lrB0uiyNwHkh0LG-KwudPmdd-PernVZyrRXwVDhVZL2ZdVkoZN=w2400" alt="tron googol" /></p>
                                  <ol start={8}>
                                    <li>To get your private key, press the menu 3 lines option</li>
                                  </ol>
                                  <p><img src="https://lh3.googleusercontent.com/-unW_H6xenp8SKQuNVkLybL-C5h0eQ6gblg2fWb4Tvem9tfbTN1ozE8iPw5oeFMToPc44hjgewLMHwwipBKDDbsh8mZnW3vcEuEh5cHxda-HjCkEJARH19UvBfeQGKLHARyJKWYM=w2400" alt="tron googol" /></p>
                                  <p>Then from the drop down list press Export Account</p>
                                  <p><img src="https://lh3.googleusercontent.com/wR5vdO4Tl_MrIuwqOSn_iH66ebH_2_MQqsNyZFdZzDAbiSoBB2SARN3DtrmmjUtltIM0Ed_-pg8qD9FyfmSKVSilFl7UwqFLinn8EFMX8kYA84lIv2W_bpiI1Xo4km9otGfaKPf3=w2400" alt="tron googol" /></p>
                                  <p>Enter your account password and then press “Private Key”</p>
                                  <p><img src="https://lh3.googleusercontent.com/vNhuRZf2uIM8rhS87vsHp64CP2S6eOcbvmzGpgjZbvhMKUnysXKKKb1oT-8rPLKE0WS4qePjsYFQenyjPg-NvdYUKdFfCpNV0f3qbRMx0l9yScjA98TYQSIrFjJAlAOiC2S_GvKB=w2400" alt="tron googol" /></p>
                                  <p>You will then be presented with your private key. Please take note of this key and store it somewhere safe. This will be required if you want to restore your wallet in the future</p>
                                  <p>Your wallet is now ready for funds to be transferred into.</p>
                                  <p>&nbsp;</p>
                                  <p><strong>MOBILE</strong></p>
                                  <p>TronLink Pro also have a mobile application. Once you download the app from Google Play Store or the Apple App Store, the process to create a wallet is the same. Follow the instructions and take note of the mnemonic phrase and private key</p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_10" aria-expanded="true" aria-controls="faq_10">How To Join Tron Googol</a>
                              </div>
                              <div id="faq_10" className="collapse " aria-labelledby="faqhead_10" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  <p>The process to join Tron Googol can be completed in 3 simple steps.</p>
                                  <p>Please watch the short videos below which explain how to join:</p>
                                  <p><a href="https://youtu.be/ypmnglQSJOw">Step 1: Create a digital wallet</a></p>
                                  <p><a href="https://youtu.be/g6kzUXAL2-o">Step 2: Fund your digital wallet with Tron</a></p>
                                  <p><a href="https://youtu.be/fFRRheEXHrE">Step 3: Join Tron Googol</a></p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_11" aria-expanded="true" aria-controls="faq_11">How To Join Step 2: Fund Wallet</a>
                              </div>
                              <div id="faq_11" className="collapse " aria-labelledby="faqhead_11" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  <p>The next step is to convert your fiat currency into crypto currency.</p>
                                  <ol>
                                    <li>To do this, go to <a href="https://www.bnbcrowdfunding.com/">www.bnbcrowdfunding.com</a> and scroll down the page until you see the following:</li>
                                  </ol>
                                  <p><img src="https://lh3.googleusercontent.com/Q00pRhtkWYQNZpRWypMPbV9dzM7CrhDhkz7pb1JoJkbS4lGNuuvAyu0gFyrdboYRC-VdFIuxmDrHKiG2n3PvOrOm_hyyoMPbJXsGxfldUJgEhLIK8YbY0N7gJ0aWQ3hESFtvK73R=w2400" alt="tron googol" /></p>
                                  <p>Alternatively, go to <a href="https://changelly.com/">https://changelly.com/</a> where you can do the same.</p>
                                  <ol start={2}>
                                    <li>Convert the amount required from your local currency to Tron or TRX</li>
                                    <li>Click on Exchange Now where you will see various rates</li>
                                    <li>Once selected follow the instructions onscreen. You may need to do a KYC</li>
                                    <li>You will be asked to enter your wallet ID or where you want to send the asset to.</li>
                                    <li>Go to the wallet created on TronLink and copy and paste the wallet ID accordingly</li>
                                  </ol>
                                  <p><img src="https://lh3.googleusercontent.com/PlrUqiQaUI0ltToEbWJdHEVAGBK1okWjdEu6kcADqz-_EHawq5qZrRutmwkTHHGsQ9W8w8f-o7nfimTA_y9uJKjPonaq0S4k9YCUblplULvF_3ncB1HC0zCjotjjQzJ2sNNP1jBh=w2400" alt="tron googol" /></p>
                                  <p>Once the transaction has been completed successfully, you will now see funds appear on your Tron Link Wallet. You can press "Refresh" on your wallet to double check.</p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_12" aria-expanded="true" aria-controls="faq_12">How To Join Step 3: Join Tron Googol</a>
                              </div>
                              <div id="faq_12" className="collapse " aria-labelledby="faqhead_12" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  <p><strong>DESKTOP</strong></p>
                                  <ol>
                                    <li>To join from your desktop, paste the link provided by the person who introduced you or from the email received from Tron Googol.</li>
                                    <li>Click on the TronLink icon on your Chrome Browser and log in&nbsp;<img src="https://lh3.googleusercontent.com/24syKjmKqK6EpoeScB0hwP84z4GNfpbzShkWLVhdybY5l8vmE8tck4jnzPjyq9F2dF0-tfjwqrSZhmPqRsK_ls_zg3WwaoLgjdbZaMw7V2c6xcty-2VZlKVQvKFZOcn_YXXYoaGi=w2400" alt="tron googol" /></li>
                                    <li>Click on the Wallet so that is open</li>
                                    <li>Your wallet is now connected to the DApp (decentralised application i.e., Tron Googol).</li>
                                    <li>To confirm this you will receive a success message which displays in green on the top right of the screen</li>
                                  </ol>
                                  <p><img src="https://lh3.googleusercontent.com/zGj9UmUJKymNUQPBJV4YxOJhdbJdG5_yVxbmn4r7MdrVIXssZGjGAQIPnaRBDGRwBYmhZTzfK6KgWzzTjG7zdUVqNeiRHQvnVybzSBXdgEv4DV23SDcqeMOZ4laHJZBUh12k7FAK=w2400" alt="tron googol" /></p>
                                  <ol start={6}>
                                    <li>There are times when the message received from the Tron Blockchain is delayed. Therefore, you need to clear the cache before joining.</li>
                                  </ol>
                                  <p>To do this for PC users Press and hold the button <strong>CTRL</strong> on your keyboard and then press <strong>F5</strong> and let go</p>
                                  <p>For Apple Mac users, press and hold the <strong>Cmd</strong> button<strong>, Shift</strong> and the letter “<strong>R</strong>”.</p>
                                  <p>This will refresh the page and you should see the green notice box as shown above.</p>
                                  <p>If the green notice box does not show, press the repeat the steps to clear your browser cache until it does. (Ctrl F5 or Cmd Shift + R).</p>
                                  <p>Once you can see the green box, navigate to the "Join" button on the Tron Googol website. Then Press the text where it says "Entry $25"</p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_13" aria-expanded="true" aria-controls="faq_13">When will I get paid?</a>
                              </div>
                              <div id="faq_13" className="collapse " aria-labelledby="faqhead_13" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  <p>The payments work in 4 cycles.</p>
                                  <p>The nature of Tron Googol is that the purple positions pay other purple positions, pink positions pay other pink positions. Green positions pay other green position.</p>
                                  <p>In order to fully understand how the payments work, we suggest watching the official business presentation.</p>
                                  <p><a href="https://youtu.be/AIxQRhDbhjc">https://youtu.be/AIxQRhDbhjc</a></p>
                                  <p><strong>Cycle 1:</strong></p>
                                  <p>This gets paid when your first board of 63 people has been populated.</p>
                                  <p><strong>Cycle 2:</strong></p>
                                  <p>The first board then gets split into 8 separate boards.</p>
                                  <p>When all of the boards are filled, you then get your cycle 2 payment.</p>
                                  <p><strong>Cycle 3:</strong></p>
                                  <p>The 8 boards then get split into 8 boards each.</p>
                                  <p>Once these 64 boards have been filled, you then get your cycle 3 payment</p>
                                  <p><strong>Cycle 4:</strong></p>
                                  <p>Those 64 boards then get split into 8 each.</p>
                                  <p>Once those 512 boards get filled, you then get paid your cycle 4 payment.</p>
                                  <p>The cycle 4 payment is just over $25k, $50k or $100k depending on the board</p>
                                  <p><strong>Re-Entry:</strong></p>
                                  <p>Once you have completed cycle 4, you automatically get re-entered onto the board.</p>
                                  <p>As others also complete their cycle 4, they also get automatically populated on the board.</p>
                                  <p>This means you can potentially earn passively from an one time effort.</p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_14" aria-expanded="true" aria-controls="faq_14">Will my referral link change?</a>
                              </div>
                              <div id="faq_14" className="collapse " aria-labelledby="faqhead_14" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  No your referral link will never change. It will always remain the same. Remember if your 1st board has been filled, if they join using your link, they will join one of the next 8 boards. If those are also filled, it will join one of the next set of boards which have an empty position. Participants that join using your link, will always remain in your network of 4 cycles.
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_15" aria-expanded="true" aria-controls="faq_15">I have received a Sending Transaction Failed error message</a>
                              </div>
                              <div id="faq_15" className="collapse " aria-labelledby="faqhead_15" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  <p>The sending transaction failed error message can appear even though you have frozen Tron. The reason is typically because the total amount of Tron that you have available plus the frozen Tron is less than the amount required to join.</p>
                                  <p>For example, if you need 400 TRX to join and your account balance shows 450 TRX. However, you have frozen 100 TRX. Therefore, you only have 350 TRX to join and therefore do not have enough TRX for the transaction.</p>
                                  <p>To view how much TRX you have available follow these steps:</p>
                                  <ol>
                                    <li>Log onto your TronLink Pro wallet</li>
                                    <li>Select the menu option by clicking on the 3 lines on the top right</li>
                                  </ol>
                                  <p><img src="https://lh3.googleusercontent.com/IahDccikXnAe2GVVds837NfH2xMZAqFnaSeKqeEsnpxlzV34PrF_kMOAJl59czCZinB_ZXijMeO7l36t_zEbslHJo42Ym98tQ6j-MVw6e_TbW2ZLeJyI7B7RFhOfQ3tHiGk-a8MG=w2400" alt="tron googol" /></p>
                                  <ol start={3}>
                                    <li>Click on Account Details which open up a new window on your browser</li>
                                  </ol>
                                  <p><img src="https://lh3.googleusercontent.com/VLfnDs_dxj16W7RbZvEGzYrW-M0Vz6Oo62ggZbEByFcwgL8EIPPyieY9v9NpaFfW247DCy88NLx84-9gcw3uK8-HYeF_0ytn0q-uH3Ozz_bDFf-FR5Z2TZbmh7TPSeyvuitL9JHo=w2400" alt="tron googol" /></p>
                                  <ol start={4}>
                                    <li>On the top left you will see your Total Assets.</li>
                                    <li>Directly underneath you will see how much of this is available to use</li>
                                  </ol>
                                  <p>&nbsp;</p>
                                  <p>Example:</p>
                                  <p><img src="https://lh3.googleusercontent.com/p80FS23fxbBroa_b0V9RxgpD9PEEc8ecGLp_g8S5KyBEVke9t20gvDT-k3TAXq8UJflzLHVdqplyHJf4oaCQkY4Z4ojidLV881iysVGtiVwvPzjCZrUjGNk122QqJqRDJx0swe55=w2400" alt="tron googol" width={860} height={513} /></p>
                                </div>
                              </div>
                            </div>
                            <div className="card">
                              <div className="card-header" id="faqhead1">
                                <a href="#" className="btn btn-header-link" data-toggle="collapse" data-target="#faq_16" aria-expanded="true" aria-controls="faq_16">I have received a Submit Failed error message</a>
                              </div>
                              <div id="faq_16" className="collapse " aria-labelledby="faqhead_16" data-parent="#faq">
                                <div className="card-body">
                                  <div className="row">
                                  </div>
                                  <p>You may receive the “Submit Failed” error message for the following reasons:</p>
                                  <ol>
                                    <li>Block rollback</li>
                                  </ol>
                                  <p>Your order might fail if the MainNet is experiencing block rollback.<br /><br /></p>
                                  <ol start={2}>
                                    <li>Failure to call contracts</li>
                                  </ol>
                                  <p><strong>Your order might fail when contracts cannot be called, which will happen if you do not have enough Energy, bandwidth or token balance in your address.</strong></p>
                                  <p>Transaction consumes your bandwidth and Energy. You can obtain bandwidth and Energy by freezing TRX, or make sure that there are enough TRX in your account balance for burning<br /><br /></p>
                                  <ol start={3}>
                                    <li>Network Delay</li>
                                  </ol>
                                  <p>Occasional MainNet delay or unstable nodes might affect your order.</p>
                                  <p><strong>When “Submit Failed” occurs, we advise you to wait for a while.</strong></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default FAQ